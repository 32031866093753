import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    category: [],
    courses: [],
    searchProducts:[],
    tranding_courses: [],
    course_details: [],
    tags: [],
    freeCourses: [],
    courseLoading: false,
    trandingLoading: false,
    detailLoading: false,
    searchFieldShow: false,
    showLoginSignup: false,
    commonLoading: false,
}


export const counterSlice = createSlice({
    name: 'courseReducer',
    initialState,
    reducers: {
        setFreeCourses: (state, action) => {
            state.freeCourses = action.payload
        },
        setRCategory: (state, action) => {
            state.category = action.payload
        },
        setRCourses: (state, action) => {

            state.courses = action.payload
        },
        setRSearchCourses:(state,action) =>{
          state.searchProducts = action.payload
        },
        setRTags: (state, action) => {
            state.tags = action.payload
        },
        setRTrandingCourses: (state, action) => {
            state.tranding_courses = action.payload
        },
        setRCourseDetails: (state, action) => {
            state.course_details = action.payload
        },
        setRCourseLoading: (state, action) => {

            state.courseLoading = action.payload
        },
        setRCourseDetailLoading: (state, action) => {

            state.detailLoading = action.payload
        },
        setRCourseTrandinglLoading: (state, action) => {

            state.trandingLoading = action.payload
        },

        setIsSearchFieldShow: (state, action) => {
            state.searchFieldShow = action.payload
        },
        setShowLoginSignup: (state, action) => {
            state.showLoginSignup = action.payload
        },
        setCommonLoading: (state, action) => {
            state.commonLoading = action.payload
        }
    }
});
// Action creators are generated for each case reducer function
export const { setRCategory, setRCourses, setFreeCourses, setRTags, setRTrandingCourses, setRCourseDetails, setRCourseLoading, setRCourseTrandinglLoading, setRCourseDetailLoading, setRSearchCourses, setIsSearchFieldShow, setShowLoginSignup, setCommonLoading } = counterSlice.actions

export default counterSlice.reducer