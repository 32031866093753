import React from 'react';
import { Oval } from  'react-loader-spinner'
import Loading from '../pages/loading';

const FallbackContainer = () => {
	return <Loading />;
	// return(
	// 	<div style={{position :'fixed', top:'50%', left : '50%', transform : 'rotate3d(10, 10, 10, 30)', zIndex:'9999'}}>
	// 		<Oval color="#00BFFF" height={100} width={100} timeout={3000}/>
	//     </div>
	// )
};


export default FallbackContainer;



