import React from "react";
import { MetaTagData } from "./MetaTagDataFile";

const { Helmet } = require("react-helmet");

interface metaTagProps {
    title?: string;
    description?: string;
    keyword?: string;
}

const MetaTag: React.FC<metaTagProps> = ({
    title,
    description,
    keyword
}) => {
    const currentPage = window.location.pathname;

    return (
        <>
            {
                MetaTagData.map((data, index) => {
                    if (data.url !== currentPage) {
                        return (
                            <Helmet>
                                <title>{title}</title>
                                <meta name="description" content={description} />
                                <meta property="og:title" content={title} />
                                <meta property="og:description" content={description} />
                                <meta name="keywords" content={keyword} />
                                {/* fav icon */}
                                <link rel="icon" href="/assets/images/favicon.ico" />
                            </Helmet>
                        );
                    }

                    return (
                        <Helmet key={index}>
                            <title>{data.description}</title>
                            <meta name="description" content={data.content} />
                            <meta property="og:title" content={data.description} />
                            <meta property="og:description" content={data.content} />
                            {
                                data.keywords.map((keyword, index) => {
                                    return (
                                        <meta name="keywords" content={keyword} key={index} />
                                    );
                                })
                            }
                            {/* fav icon */}
                            <link rel="icon" href="/assets/images/favicon.ico" />
                        </Helmet>
                    );
                })
            }
        </>
    );
};

export default MetaTag;