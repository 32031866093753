import { createSlice } from "@reduxjs/toolkit";

const blogSlice = createSlice({
    name: "blog",
    initialState: {
        blogs: [],
        loading: false,
        error: null,
        blogToShow: null,
        blogID: null,
        blogCategories: [],
    },
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        getBlogs: (state, action) => {
            state.blogs = action.payload;
        },
        getBlogById: (state, action) => {
            state.blogToShow = action.payload;
        },
        setBlogId: (state, action) => {
            state.blogID = action.payload;
        },
        getBlogCategories: (state, action) => {
            state.blogCategories = action.payload;
        },
    },
});

export const { setLoading, getBlogs, getBlogById, setBlogId, getBlogCategories } = blogSlice.actions;
export default blogSlice.reducer;