import React, { Suspense } from 'react';
import Routing from './config/app.routing';
import FallbackContainer from './config/FallbackContainer';

export default function main() {
  return (
    <Suspense fallback={< FallbackContainer/>}>
      <Routing />
    </Suspense>
  )
}
