import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SignupUser } from '../services/Services';
import { Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";

function NewSignUp() {

  const { userData, isLogin, userID, loginLoading } = useSelector((state) => state?.user || {});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [response, setResponse] = useState('');
    const [password, setPassword] = useState(null);
    const [userError, setUserError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [displayNameError, setDisplayNameError] = useState('');
    const [passError, setPassError] = useState('');
    const [loading, setLoading] = useState(false);
    const [ResponseType, setResponseType] = useState(false);

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
            setEmailError('Email is invalid');
        } else {
            setEmailError(null);
        }

        setEmail(event.target.value);
    };


    async function Signup() {
        setLoading(true);

        if (displayName == null || displayName == '' || displayName == undefined) {
            setDisplayNameError('Please enter full name');
            setLoading(false);
            return
        } else {
            setDisplayNameError();
        }

        if (username == null || username == '' || username == undefined) {
            setUserError('Please enter username');
            setLoading(false);
            return
        } else {
            setUserError();
        }
        if (email == null || email == '' || email == undefined) {
            setEmailError('Please enter email address');
            setLoading(false);
            return
        } else {
            setEmailError();
        }

        if (!isValidEmail(email)) {
            setEmailError('Email is invalid');
            return
        } else {
            setEmailError();
        }


        if (password == null || password == '' || password == undefined) {
            setPassError('Please enter Password');
            setLoading(false);
            return
        } else {
            setPassError();
        }

        if (password.length < 8) {
            setPassError('Please enter minimum 8 character password');
            setLoading(false);
            return
        } else {
            setPassError();
        }


        SignupUser(username, email, password, displayName, dispatch).then(response => {
            setLoading(true);
            if (response.success == false) {
                setResponse(response.message);
                setResponseType('danger');
                setLoading(false);
            } else {
              setResponse('Account Created Successfully. Please login');
              setResponseType('success');
              setLoading(false);
              setTimeout(() => {
                navigate("/login", { replace: true });
              }, 2000);
                
            }


        });

    }

  const [class_name, setClass_name] = React.useState(null);
  // const [showPassword, setShowPassword] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleModal = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const changeClass_name = (class_name)=>{
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if(class_name==null){
      setClass_name('slash')
    }else{
      setClass_name(null)
    }
  }
  return (
    <>
    {/* <li className="nav-item dropdown"> */}
      <Link to="#" onClick={toggleModal}>
        Sign Up
      </Link>
    {/* </li> */}

    {showModal && (
      <div className="modal fade show" tabIndex="-1" style={{ display: 'block' }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className=""
                style={{
                  // textAlign: 'center',
                  marginTop:'25px',
                  width: '100%',
                  fontSize: '2rem',
                  fontWeight: '400',
                  color: '#000',
                  letterSpacing: '0.01rem',
                }}
                id="exampleModalLabel"
              >
                Sign up 
              <p style={{
                fontSize:'20px',
                fontWeight:'200',
                color:'#000',
                letterSpacing:'0.01rem',
                marginTop:"10px"
              }}>Learn on your own time from top <br /> universities and business</p>
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={toggleModal}
                aria-label="Close"
              ></button>
            </div>
           <center> {response && <div style={{marginLeft: "10px"}} className={`alert alert-${ResponseType}`}>{response}</div>}</center>
            
            <div className="modal-body p-5">
                <div className="mb-4">
                  <label
                  style={{
                    float:" left"
                  }}
                    htmlFor="fullName"
                    className="form-label align-content-start text-black fw-semibold"
                  >
                    Full Name
                  </label>
                  <input
                  onInput={(e) => setDisplayName(e.target.value)}
                    type="text"
                    className="form-control border border border-secondary"
                    placeholder="Enter your full name"
                    id="fullName"
                    aria-describedby="emailHelp"
                  />
                   {displayNameError && <span className="text-danger" style={{float:'left', fontWeight: 10, fontSize: '12px' ,marginTop: '3px', marginBottom: '5px' }}>{displayNameError}</span>}
                </div>
                <div className="mb-4">
                  <label
                  style={{
                    float:" left"
                  }}
                    htmlFor="exampleInputEmail1"
                    className="form-label text-black fw-semibold"
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    onInput={(e) => setUsername(e.target.value)}
                    className="form-control border border border-secondary"
                    placeholder="username"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                  {userError && <span className="text-danger" style={{float:'left', fontWeight: 10, fontSize: '12px' ,marginTop: '3px', marginBottom: '5px' }}>{userError}</span>}
                
                </div>
                <div className="mb-4">
                  <label
                  style={{
                    float:" left"
                  }}
                    htmlFor="exampleInputEmail1"
                    className="form-label text-black fw-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    onInput={handleChange}
                    className="form-control border border border-secondary"
                    placeholder="name@gmail.com"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                    {emailError && <span className="text-danger" style={{float:'left', fontWeight: 10, fontSize: '12px' ,marginTop: '3px', marginBottom: '5px' }}>{emailError}</span>}
                
                </div>
                <div className="mb-4">
                  <label
                  style={{
                    float:" left"
                  }}
                    htmlFor="exampleInputPassword1"
                    className="form-label text-black fw-semibold fs-6"
                  >
                    Password
                  </label>
                  <input
                    onInput={(e) => setPassword(e.target.value)}
                    type={showPassword ? 'text' : 'password'}
                    className="form-control border border-secondary"
                    placeholder="Create your password"
                    id="exampleInputPassword1"
                  />
                  <div
                    id="emailHelp"
                    className="mt-1"
                    style={{
                      color: '#000',
                      fontSize: '0.7rem',
                      fontWeight: '100',
                        float:" left"
                    }}
                  >
                    <p>Between 8 to 72 characters.</p>
                    {passError && <span className="text-danger" style={{float:'left', fontWeight: 10, fontSize: '12px' ,marginTop: '3px', marginBottom: '5px' }}>{passError}</span>}
                
                  </div>
                  <i
                    style={{
                      position: 'absolute',
                      top: '71%',
                      right: '4rem',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                    }}
                    onClick={togglePasswordVisibility}
                    className={`fa fa-eye${showPassword ? '-slash' : ''}`}
                  ></i>
                </div>
                <button onClick={() => Signup()} type="submit" className="btn btn-primary w-100" disabled={loading}>
                  {loading ? "Signing up..." : "Join for free"}
                </button>
            </div>
            <div className="container">
              <p className="text-black text-center">
                Already on Q Academy? &nbsp;
                <Link
                  to="#"
                  className="text-decoration-underline fs-6 lh-1 fw-medium"
                  onClick={toggleModal}
                >
                  Log In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    )}
  </>

  )
}

export default NewSignUp

