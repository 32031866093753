import { setLaunchLink } from "../app/reducers/Lab";
import { fetchCountryInfo } from "./Services";

export const LabCreateUser = async ({ dispatch, email, first_name, last_name }) => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(baseUrl + '/wp-json/nuvepro/v2/create-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                userName: email,
                firstName: first_name,
                lastName: last_name,
            })
        });
        if (!response.ok) {
            throw new Error('Authentication failed');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Authentication failed:', error);
        // Handle the error as needed
    }
};

export const LabAddUserIntoTeam = async ({ planID, email, companyID, teamID }) => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(baseUrl + '/wp-json/nuvepro/v2/addUserIntoTeams', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                userName: email,
                companyId: companyID,
                teamIds: teamID,
            })
        });
        const data = await response.json();
        if (data.MessageCode?.toString() === '1000') {
            console.log('fetch Labs: add into team if messageCode 1000', data)
            LabSubscribeUser({ dispatch, planID, email, companyID, teamID });
        } else {
            console.log('fetch Labs Error: add team', data)
        }
        return data;
    } catch (error) {
        console.error('Authentication failed Team:', error);
        // Handle the error as needed
    }
};

export const LabSubscribeUser = async ({ dispatch, planID, email, companyID, teamID }) => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(baseUrl + '/wp-json/nuvepro/v2/subscriptions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                planId: planID,
                userName: email,
                companyId: companyID,
                teamId: teamID,
            })
        });
        // if (!response.ok) {
        //     throw new Error('Authentication failed Subscribe:');
        // }
        const data = await response.json();
        console.log('fetch Labs subscribe:', data?.subscriptionIds[0], data)
        if (data?.subscriptionIds[0]) {
            console.log('fetch Labs subscribe:', 'Inside====================')
          const launchLink = await LabLaunchLab(data?.subscriptionIds[0]);
            if (launchLink) {
                dispatch(setLaunchLink(launchLink));
            }
        } else
            if (data.MessageCode?.toString() === '1014') {
                LabAddUserIntoTeam({ dispatch, planID, email, companyID, teamID })
            } else {
                console.log('fetch Labs subscribe:', data)
            }
        return data;
    } catch (error) {
        console.error('Authentication failed subscribe', error);
        // Handle the error as needed
    }
}

export const LabLaunchLab = async (subscriptionId) => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(baseUrl + '/wp-json/nuvepro/v2/subscriptions/launch', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                subscriptionId: subscriptionId,
            })
        });
        const data = await response.json();
        console.log('fetch Labs:', data)
        if (data?.userAccess) {
            console.log('fetch Labs:', data?.userAccess, 'Inside====================');
            const userAccessArray = JSON.parse(data.userAccess);

            // Find the object with "description" equal to "Web Desktop."
            const webDesktopObject = userAccessArray.find(obj => ['Web desktop.','Web Desktop.'].includes(obj.description) );

            // Extract the "value" field from the found object
            const webDesktopValue = webDesktopObject ? webDesktopObject.value : null;
            return webDesktopValue;
        }
        return data;
    } catch (error) {
        console.error('Authentication failed Launch', error);
        // Handle the error as needed
    }
};

export const LabStopLab = async () => {
    try {
        const baseUrl = await fetchCountryInfo();
        const response = await fetch(baseUrl + '/wp-json/nuvepro/v2/subscriptions/performAction/users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                subscriptionId: 2070870,
                actionName: 'Stop'
            })
        });
        if (!response.ok) {
            throw new Error('Authentication failed Stop:');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Authentication failed Stop', error);
        // Handle the error as needed
    }
};