import React from 'react'
import '../Blog/blogStyles.css'
import './LandingPage.css'
import { Alert, Button, Col, Container, Form, Image, Modal, Row } from 'react-bootstrap';
import { AddToCart, ContactUs, UpdateCartInfo } from '../../services/Services';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { setRCartCookies, setRCartData } from '../../app/reducers/checkoutReducer';
import { LandingPageCourse } from './constFile';
import { Link } from 'react-router-dom';
import { RegisterNowRoadmap } from './Roadmap';
import { setCommonLoading } from '../../app/reducers/courseReducer';
import Loading from '../loading';
import Layout from '../layout/layout';
import { WebinerUserEmailCheck } from '../../services/Webiner';
import { useMediaQuery } from 'react-responsive';

function ContainerBody({ children, className }) {
    return (
        <Container className={`body + ${className}`} >
            {children}
        </Container>
    )
}

export function HeaderContent({ onClick, data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cartCookies } = useSelector(state => state.cart);
    const { isLogin } = useSelector(state => state.user);
    const { commonLoading } = useSelector(state => state.course);

    async function addToCart({ ID, amount, product }) {
        dispatch(setCommonLoading(true));
        AddToCart(
            { id: ID, amount: amount, product: product, cookies: cartCookies },
            dispatch
        ).then(async (response) => {
            if (response?.response?.code == 200 || response?.response?.code == 201) {
                await dispatch(
                    setRCartData({ type: "ADD_CART_DATA", payload: response?.body })
                );
                await dispatch(setRCartCookies({ payload: response?.cookies }));
                await UpdateCartInfo();
                navigate("/checkout", { replace: true });
            } else if (response?.response?.code == 400) {
                navigate("/checkout", { replace: true });
            }
            dispatch(setCommonLoading(false));
        });
    }

    async function registerNow() {
        // if (isLogin) {
        await addToCart({
            ID: data.product_id,
            amount: data.price,
            product: data.name,
        });
        // } else {
        //     dispatch(setShowLoginSignup(true))
        // }

    }

    if (commonLoading) {
        return <Loading />
    } else {
        return (
            <div className='LheaderContainer'>
                <center>
                    <div className='HeaderContent'>
                        <Image src={'/assets/images/LandingPage/liveLogo.png'} alt='roadmap' rounded className='liveLogo' />
                        <h2 className='text-black'>on</h2>
                        <h1 className='text-primary-color capitalize lh-1'>SOARING TO SUCCESS <p className='text-primary-color'>WITH CLOUD COMPUTING</p></h1>
                        <p className='text-black fs-5'> <i className='fa fa-calendar' /> April 26, 2024  </p>
                        <Button href={[undefined, null, ''].includes(data) ? '/cloud-computing' : '#form'} onClick={() => [undefined, null, ''].includes(data) ? onClick() : {}} className='button fs-3'>Enroll for ₹ 99/-</Button>
                    </div>
                </center>
            </div>
        )
    }
}

function AboutWebinar() {
    return (
        <Row>
            <Col lg='6' md='6' className='hideOnMobile'  >
                <Image src={'/assets/images/LandingPage/VectorSmartObject2.png'} alt='roadmap' rounded
                    className='aboutWebinarImage'
                />
            </Col>
            <Col lg='6' md='6' sm='12' xs='12' className='p-5 pt-0 pb-0' >
                <p className='fs-5'>About the webinar</p>
                <h2 className='text-primary-color'>Soaring to success with cloud computing</h2>
                <p className='fs-5 mt-3'>A cloud computing webinar is for you if you love being covered with data and code. Q Academy has designed this exclusive webinar with Dr. Gaurav Hajela.</p>

                <Row>
                    <Col lg='6' md='6' sm='6' xs='6'>
                        <Row className='align-items-center' >
                            <Col lg='3' >
                                <i className='fa fa-calendar big-icon' />
                            </Col>
                            <Col lg='9' className='justify-content-center' >
                                <h3 className='text-primary-color'>When</h3>
                                <p className='fs-6 text-black'>April 26, 2024</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg='6' md='6' sm='6' xs='6'>
                        <Row className='align-items-center' >
                            <Col lg='3' >
                                <i className='fa fa-clock-o big-icon' />
                            </Col>
                            <Col lg='9' className='justify-content-center' >
                                <h3 className='text-primary-color'>Time</h3>
                                <p className='fs-6 text-black'>11:00 AM to 12:00 PM</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Button className='btn-black fs-3 mt-3' href='#form'>Enroll for ₹ 99/-</Button>
            </Col>

        </Row>
    )
}

function AboutHost() {
    return (
        <div className='LaboutHost' >
            <div className='LdeepakGourImage'>
                <Container>
                    <h2 className='text-black mb-5 text-center mt-5 '>Not just a host &#10134; <p className='text-primary-color fs-2' >your cloud computing superhero!</p> </h2>
                    <Row>
                        <Col lg='8' md='8' sm='12' xs='12' className='p-5 pt-0 pb-0'>
                            <div className='LhostInfo' >
                                <h3 className='text-primary-color'>Dr. Gaurav Hajela</h3>
                                <p className='fs-5 mt-3 text-black'>An experienced professional with over 10 years of experience in big data analytics, high performance computing, machine learning, and data analytics.</p>
                            </div>
                            <h2 className='text-primary-color mt-5'><span className='text-black'>How will our </span>cloud computing superhero <span className='text-black'>help you?</span></h2>
                            {/* make a number list 1 to 5 */}
                            <ol className='fs-5 text-black'>
                                <li>Going the extra mile to solve your query</li>
                                <li>Providing factual information</li>
                                <li>Guiding you in your career path</li>
                                <li>Helping you understand with examples</li>
                            </ol>
                        </Col>
                        <Col lg='4' md='4' className='hideOnMobile'>
                        </Col>
                    </Row>
                </Container>

            </div>
        </div>
    )
}

function WhyJoin() {

    const data = [
        {
            icon: 'fa fa-calendar',
            title: 'Updated curriculum',
            text: 'The webinar will be conducted on the basis of the latest technologies and strategies available.',
            image: '/assets/images/LandingPage/update.png'
        },
        {
            icon: 'fa fa-calendar',
            title: 'Get crystal clear clarity on concepts',
            text: 'The webinar is designed in such a way that it clears all the concepts easily.',
            image: '/assets/images/LandingPage/eye.png'
        },
        {
            icon: 'fa fa-calendar',
            title: 'Enhancing employability',
            text: 'This webinar will make you ready for the world',
            image: '/assets/images/LandingPage/magic.png'
        },]

    return (
        <div className='whyJoin' >
            <h2 className='text-primary-color lh-1'><p className='text-black' >Why should you</p> join this webinar?</h2>
            <Row className='justify-content-center mt-5 gap-4 gap-lg-9' >
                {
                    data.map((item, index) => {
                        return (
                            <Col md='6' sm='12' xs='12' className='infoCard shadow' key={index} >
                                <div className='infoCardTitle' >
                                    <Image src={item.image} alt='goal' rounded className='iconImage' />
                                    <h3 className='text-white'>{item.title}</h3>
                                    <div className='infoCardDivider' />
                                </div>
                                <p className='text-white fs-5 mt-3'>{item.text}</p>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )
}

function HowItHelps() {
    const data = [
        {
            title: 'Advancing career',
            text: 'The webinar will help you shape your career in cloud computing.',
            image: '/assets/images/LandingPage/advancement.png'
        },
        {
            title: 'Clarity on the industry',
            text: 'The webinar will help you gain clarity on the cloud computing industry.',
            image: '/assets/images/LandingPage/search.png'
        },
        {
            title: 'Upskilling',
            text: "The webinar will help you add multiple new skills and strategies to your mind's database.",
            image: '/assets/images/LandingPage/challenge.png'
        },
        {
            title: 'Competitive advantage',
            text: 'The webinar will help you be competitive in the industry with the right sets of knowledge and skills.',
            image: '/assets/images/raodmap/advantage.png'
        }
    ]
    return (
        <div className='howItHelps' >
            <Row className='justify-content-center p-5 LhelpRow gap-4' >
                <Col lg='12' md='12' sm='12' xs='12' className='align-items-center justify-content-center d-flex' >
                    <h2 className='text-primary-color  helpTitle '><p className='text-black'>How will this</p>webinar help you?</h2>
                </Col>
                {
                    data.map((item, index) => {
                        return (
                            <Col sm='12' xs='12' key={index} className='infoCard shadow'>
                                <div className='infoCardTitle'>
                                    <Image src={item.image} alt='roadmap' rounded className='iconImage' />
                                    <h3 className='text-white text-center '>{item.title}</h3>
                                    <div className='infoCardDivider' />
                                </div>
                                <p className='text-white fs-5 mt-3'>{item.text}</p>
                            </Col>
                        )
                    })
                }
            </Row>

            <Row className='LhelpLowerImage'>
                <Col lg='6' md='6' className='p-5 pt-0 pb-0 hideOnMobile' />
                <Col lg='6' md='6'>
                    <h2 className='text-primary-color mb-4' style={{
                        lineHeight: 0.8
                    }}><p className='text-black'>What topics</p> will be covered?</h2>
                    <ol className='fs-5 text-black'>
                        <li>Fundamentals of Cloud Computing</li>
                        <li>Cloud Service Providers</li>
                        <li>Technical terminology comparison of various cloud service providers</li>
                        <li>Career in cloud computing</li>
                    </ol>
                </Col>
            </Row>
        </div>
    )

}

export function RegisterNowLanding({ data, buttonTitle, onClick, onClickUri }) {

    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [loading, setLoading] = React.useState(false);


    async function registerNow() {
        // check all fileds are filled
        if (name?.trim()?.length == 0 || email.length == 0 || phone.length == 0) {
            alert('Please fill all the fields');
            return;
        }

        if (email.length > 0) {
            const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
            if (!isEmailValid) {
                alert('Please enter a valid email');
                return;
            }
        }

        // other logics
        setLoading(true);
        await WebinerUserEmailCheck({ name, email, number: phone, product: data })
        setLoading(false);
    }

    return (
        <div id='form' className='registerNow p-4 p-lg-0 sideRib-registerForm' >
            <Row className='registerFormImage'>
                <Col lg='8' md='12' sm='12' xs='12' className='p-lg-5 pt-lg-0 pb-lg-0' >
                    <h2 className='text-primary-color ms-lg-5'><span className='text-black' >Sign up</span> for the webinar</h2>
                    <Form className='m-lg-5 mt-5'>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                onChange={(e) => setName(e.target.value)}
                                value={name}
                                type="text" id='text-input-custom-style' placeholder="Enter your name" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                id='text-input-custom-style' type="email" placeholder="Enter your email" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                onChange={(e) => setPhone(e.target.value)}
                                value={phone}
                                id='text-input-custom-style' type="number" placeholder="Enter your phone number" />
                        </Form.Group>
                        <Button className='btn-black fs-3' href={onClickUri} onClick={() => !onClick && buttonTitle?.length > 0 ? onClick() : registerNow()} disabled={loading} > {loading ? 'Loading...' : buttonTitle?.length > 0 ? buttonTitle : `Enroll now for ₹ 99/-`}</Button>
                    </Form>

                </Col>
                <Col lg='4' md='auto' className='hideOnMobile'>
                </Col>
            </Row>
            <Button className='button fs-sm-6 stickyButton' href='#form'>{buttonTitle?.length > 0 ? buttonTitle : `Enroll now for ₹ 99/-`}</Button>
        </div>
    )
}

function Certificate() {
    return (
        <div className='Lcertification' >
            <Container>
                <Row>
                    <Col lg='4' sm='12' className='d-flex justify-content-lg-center align-items-center pe-lg-5' >
                        <h2 className='LcertificationTitle'><p style={{ lineHeight: 0.5 }}>Get a</p> completion certificate</h2>
                    </Col>
                    <Col lg='8' sm='12' >
                        <Image src={'/assets/images/LandingPage/VectorSmartObject6.png'} alt='certificate' rounded />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export function TestimonialTrailLanding() {
    const testimonials = [
        {
            name: "Hemant Chandwani",
            feedback: "We enjoyed the training sessions. The trainer is having hands-on experience in teaching and thorough knowledge to explain the concepts.",
            image: '/assets/images/avatar/Hemantchandwani_Stwilfrd.jpg'
        },
        {
            name: "Nisha Kumari",
            feedback: "Course was very useful in development of our skills. It's is skill that is going to develop in future.",
            image: '/assets/images/avatar/Nisha Kumari.jpeg'
        },
        {
            name: " Harsh Prajapati",
            feedback: "Video recording were also available.",
            image: '/assets/images/avatar/HarshPrajapati_StWilfred.jpg'
        },
        {
            name: "Harsh Jangid",
            feedback: "The course content is very good and the instructor is too good and he teach us well.",
            image: '/assets/images/avatar/HarshJangid_StWilfred.jpg'
        },
        {
            name: "Anuj Sharma",
            feedback: "QAcademy's content in useful and valuable."
        },
        {
            name: "Brajesh Kumar",
            feedback: "Q academy course is skill enhancement and future prospects. And thank you q academy."
        },
        {
            name: "Abhishek Lakhera",
            feedback: "Course is going to help me develop skills and it will be helpful in future."
        },
        {
            name: "Amit Jat",
            feedback: "Good explanation of topic and session delivering is quite interesting."
        },
        {
            name: "Rahul Sharma",
            feedback: "QAcademy's course is helping us to grow in future so that's why this is very beneficial for us as a student."
        },
        {
            name: "Raghvendra Singh Nirwan",
            feedback: "This Course is growing in future so that's why this is very useful and valuable for every student who take this course from qacademy."
        },
        {
            name: "Saurabh Bhatt",
            feedback: "QAcademy's Courses are good for my skill development."
        },
        {
            name: "Harsh Prajapat",
            feedback: "Python learning was the exciting experience at QAcademy."
        },
        {
            name: "Hemant Chandwani",
            feedback: "Course will help us in future as it is in demand."
        },
        {
            name: "Astha Nema",
            feedback: "Data Science course at QAcademy helps us to understand the latest technologies in the best way as it is in demand."
        },
        {
            name: "Ayush Gupta",
            feedback: "Q Academy courses were very useful and proved valuable to me."
        },
        {
            name: "Suhani Sharma",
            feedback: "Q Academy is the best place for Skill enhancement."
        },
        {
            name: "Akshat Kukreja",
            feedback: "Excellent 👍is the right word for Q Academy."
        },
        {
            name: "Astha Kathayat",
            feedback: "Every aspect of Q Academy's course were useful and valuable."
        },
        {
            name: "Samarth Choudhary",
            feedback: "First time getting QAcademy's services and I am impressed."
        },
        {
            name: "Yash Verma",
            feedback: "Q Academy has given me the opportunity to learn new technologies in a hassle-free, homely environment."
        },
        {
            name: "Vishal Jethwani",
            feedback: "Q Academy Team is extremely supportive to provide the best learning experience."
        },
        {
            name: "Sanskar Mishra",
            feedback: "I have taken the course for my skill enhancement and found it a golden opportunity at Q Academy."
        },
        {
            name: "Ankush Kumar Singh",
            feedback: "I found Q Academy's course helpful as it provided the latest in-demand technologies."
        },
        {
            name: "Omm Behera",
            feedback: "Excellent experience at Q Academy."
        },
        {
            name: "Satish Ojha",
            feedback: "Explanation of tough concepts in an easy way and keeping the sessions engaged throughout is the key. We had a great experience at Q Academy."
        },
        {
            name: "Shubham Bhagwat",
            feedback: "QAcademy is a bank of knowledge, I am glad I have my account here."
        },
        {
            name: "Harshit Kushwah",
            feedback: "Where knowledge is served with full dedication towards the betterment of learners is valuable at Q Academy."
        },
        {
            name: "Vishal Dangi",
            feedback: "Q Academy gives priority to Student satisfaction."
        },
        {
            name: "Harsh Kumar Kachhi",
            feedback: "Services at Q Academy have helped us stick to our learning journey."
        },
        {
            name: "Intiyaj Ansari",
            feedback: "From trainers to product range: Q Academy has always satisfied our learning requirements."
        },
        {
            name: "Saurabh Maurya",
            feedback: "I took the AWS workshop at Q Academy which has helped me choose better career options."
        },
        {
            name: "Abbi Singh Sanvanshi",
            feedback: "Learning at Live session of Q Academy boosts confidence and enhances interest in the topic."
        },
        {
            name: "Yashraj Patel",
            feedback: "LMS at Q Academy is the best feature which helped to have the after-class aid."
        },
        {
            name: "Prashant Singh",
            feedback: "The entire team at Q Academy is very supportive and helpful throughout."
        },
        {
            name: "Ankita Kushwaha",
            feedback: "Curriculum design is the attractive part of the course I have attended at Q Academy."
        },
        {
            name: "Tufail Ahamad",
            feedback: "Amazing experience at Q Academy."
        },
        {
            name: "Raushan Kumar",
            feedback: "The entire set up of Q Academy is a great way to show appreciation and support to young learners."
        },
        {
            name: "Hemant Jhala",
            feedback: "I am now able to choose my career options wisely after being part of Q Academy."
        },
        {
            name: "Vikram Dhakad",
            feedback: "Learning at Q Academy and the environment they provide has helped me to evolve my grasping skills."
        },
        {
            name: "Sudha Patidar",
            feedback: "Q Academy has given me wings to fly in this era of technology."
        },
        {
            name: "Manthan Singh",
            feedback: "I had a great learning experience at Q Academy."
        },
        {
            name: "Nandini Raikwar",
            feedback: "Thank you Q Academy, Trainer, and curriculum team to provide us with the best services."
        },
        {
            name: "Shruti Soni",
            feedback: "Q Academy is a place to find out your skill enhancement options."
        },
        {
            name: "Abhishek Parmar",
            feedback: "I am grateful to Q Academy for the courses they offered."
        },
        {
            name: "Saksham Sahu",
            feedback: "It was a pleasure to be a part of Q Academy's learning community."
        },
        {
            name: "Yuvraj Mandloi",
            feedback: "I am Grateful that I came across Q Academy for Python Full Stack Development course."
        },
        {
            name: "Chetan Carpenter",
            feedback: "I can now opt for better skills updation as now I know What is Python language learnt at QAcademy."
        },
        {
            name: "Navin Yadav",
            feedback: "Going through the AWS internship program, I am now confident to take up my career into the field of Cloud."
        },
        {
            name: "Pranjal Ajmera",
            feedback: "Lifetime access to LMS and the tremendous knowledge I have"
        },
    ];
    return (
        <div className='testimonialTrail text-center p-2' >
            <h2 className='text-primary-color mb-4'>Testimonial Trail</h2>
            <Row>
                {
                    testimonials?.slice(0, 4).map((item, index) => {
                        return (
                            <Col lg='3' md='3' sm='6' xs='6' className='testimonialCard' key={index}>
                                <div className='testimonialImage' >
                                    <Image src={item.image || '/assets/images/instructor/dummy-profile-.jpg'} alt='testimonial' rounded style={{
                                        // width: '100px',
                                        height: '200px'
                                    }} />
                                </div>
                                <h3 className='text-primary-color mt-2'>{item.name}</h3>
                                <p className='fs-5 text-black ' >{item.feedback}</p>
                            </Col>
                        )
                    })
                }
            </Row>
            <Button className='btn-black fs-3 mt-3' href='#form'>Enroll Now</Button>
        </div>
    )

}

function FAQ() {
    const data = [
        {
            question: 'Will I get any recorded videos after the webinar?',
            answer: 'Yes.'
        },
        {
            question: 'How long will the webinar last?',
            answer: "It's an hour long webinar scheduled for 11:00 AM to 12:00 PM."
        },
        {
            question: 'What will I learn in this webinar?',
            answer: 'Data Strategy and Governance, Data Infrastructure, Data Management and Integration, Data Analytics Tools and Techniques, Skills and Talent Development, and Security and Compliance.'
        },
        {
            question: 'Who will host the webinar?',
            answer: 'Dr. Deepak Gour, PhD computer science & engineering, has over 16 years of experience in cloud computing, cyber security, data science AI, and ML, will host the webinar.'
        },
        {
            question: 'Will I get a completion certificate?',
            answer: "Yes, upon completion of this webinar, you'll. receive a certificate from Q Academy."
        }
    ]
    return (
        <div className='Lfaq p-4' >
            <h2 className='text-primary-color mb-4 text-center'>FAQs</h2>
            {
                data.map((item, index) => {
                    return (
                        <div className='faqCard' key={index} >
                            <h4 className='text-black'>{item.question}</h4>
                            <p className='fs-5'>{item.answer}</p>
                        </div>
                    )
                })
            }
        </div>
    )

}

export function FooterLandingPage() {

    const isBigScreen = useMediaQuery({ query: "(min-width: 1025px)" });

    const socialMedia = [
        {
            name: "Email",
            link: "mailto:info.india@qacademy.tech",
            icon: "fa fa-envelope-o",
        },
        {
            name: "LinkedIn",
            link: "https://www.linkedin.com/company/qacademy/",
            icon: "fa fa-linkedin",
        },
        {
            name: "Facebook",
            link: "https://www.facebook.com/QAcademyCA",
            icon: "fa fa-facebook",
        },
        {
            name: "Instagram",
            link: "https://instagram.com/qacademyin?igshid=MzRlODBiNWFlZA==",
            icon: "fab fa-instagram",
        },
        {
            name: "Twitter",
            link: "https://twitter.com/qacademyin",
            icon: "fa-brands fa-x-twitter",
        },
    ];
    return (
        <>
            <Row className='mt-3'>
                <Col lg={12} md={12} sm={12} xs={12} className="footer mb-2 mt-3">
                    <Container>
                        <Row className="pt-2 pb-2">
                            <Col lg={6} md={12} sm={12} xs={12} className="">
                                <div
                                    className={`text-black ${!isBigScreen && "text-center pb-2"}`}
                                >
                                    Copyright © 2024 Q Academy Education. All Rights Reserved.
                                </div>
                            </Col>
                            <Col lg={6} md={12} sm={12} xs={12}>
                                <Row
                                    style={{
                                        justifyContent: isBigScreen ? "flex-end" : "center",
                                        alignItems: "center",
                                        height: "100%",
                                        width: "100%",
                                    }}
                                >
                                    {socialMedia.map((data, index) => {
                                        return (
                                            <Col
                                                lg={"auto"}
                                                md={"auto"}
                                                className="d-flex justify-content-center"
                                                sm={"auto"}
                                                xs={"auto"}
                                            >
                                                <Link
                                                    // {...linkSetting}
                                                    to={data.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="text-black"
                                                >
                                                    <i
                                                        className={data.icon}
                                                        aria-hidden="true"
                                                        style={{
                                                            color: "#0056d2",
                                                            // marginRight: 10,
                                                            fontSize: 24,
                                                        }}
                                                    />
                                                </Link>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </>
    )
}

function RoadmapBody() {

    return (
        <>
            <div className='sideRib-aboutWebinar'>
                <ContainerBody children={<AboutWebinar />} />
            </div>
            <AboutHost />
            <div className='sideRib-aboutWebinar'>
                <div className='sideRib-right'>
                    <ContainerBody children={<WhyJoin />} />
                </div>
            </div>
            <HowItHelps />
            <RegisterNowLanding data={LandingPageCourse} />
            <Certificate />
            <ContainerBody children={<TestimonialTrailLanding />} />
            <FAQ />
            <FooterLandingPage />
        </>
    )
}

function LandingPage() {
    const mainContainerStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    }
    return (
        <div style={mainContainerStyle}>
            <div style={{
                display: 'none'
            }}>
                <Layout />
            </div>
            <div className='roadmapMainContainer text-start'>
                <HeaderContent data={LandingPageCourse} />
                <RoadmapBody />
            </div>
        </div>
    )
}
export default LandingPage;