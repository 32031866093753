import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';
import { BrowserRouter } from 'react-router-dom';
import  store  from './app/store';
import { Provider } from 'react-redux';
import {CartProvider} from './app/context/cart_context';
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js"
let persistor = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById('root'));
// import './index.css';

const stripePromise = loadStripe('pk_test_51IYlk7BI3LxRBGF668Ug4npM0RN9g37NN6dED3XznxiRGHCxzb0xArVRsDCgPejJA4JqnezhnZ1Kj0pPV052oUYv00nB3TmsE0');
root.render(
   <React.StrictMode>
    {/* <CartProvider> */}
    <Elements stripe={stripePromise}>
    <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor}></PersistGate>
        <Main/>
      </Provider>
    </BrowserRouter>
    </Elements>
  

    {/* </CartProvider> */}
  
   </React.StrictMode>
);

