import { fetchCountryInfo } from "./Services"

export const WebinerUserEmailCheck = async ({ name, email, number, product }) => {
    const baseUrl = await fetchCountryInfo()
    const response = await fetch(baseUrl + `/wp-json/landing-page-registration/v2/registration/email/${email}/email-2/`);
    const res = await response.json();

    if (res?.status === 200 && res?.entry?.meta_data["text-1"]?.value === "Paid") {
        alert('Already Enrolled')
    } else if (res?.data?.status === 404) {
        return await Webinar_Registor_If_user_Email_Not_Registor({ name, email, number, product })
    } else if (res?.status === 200 && res?.entry?.meta_data["text-1"]?.value !== "Paid") {
        return await Webinar_Registor_If_user_Email_Not_Registor({ name, email, number, product })
    } else if (res?.status === 404) {
        return await Webinar_Registor_If_user_Email_Not_Registor({ name, email, number, product })
    }
    return;
}

// if entry is not available, then enter the details

const Webinar_Registor_If_user_Email_Not_Registor = async ({ name, email, number, product }) => {
    const baseUrl = await fetchCountryInfo();

    let response = await fetch(baseUrl + `/wp-json/landing-page-registration/v2/registration `, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "name-1": name,
            "email-2": email,
            "phone-1": number,
            "text-3": product?.name,
        })
    });
    response = await response.json();
    if (response?.status === 200 && product) {
        await WebinerPayment({ name, email, number, product, forminatorFormId: response.data })
        return;
    } else if (response?.status === 200) {
        // alert(response?.message || 'Form Submitted')
        return true;
    }
    return;
}

// if the entry is available then update the form

const Webinar_Registor_If_user_Email_Registor = async ({ intendID }) => {
    const baseUrl = await fetchCountryInfo();
    const search = await fetch(baseUrl + `/wp-json/landing-page-registration/v2/registration/email/${intendID}/text-2/`);
    let entry_id = await search.json();
    entry_id = entry_id?.entry?.entry_id;

    let response = await fetch(baseUrl + `/wp-json/landing-page-registration/v2/registration/${entry_id}  `, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "text-1": "Paid",
        })
    });
    response = await response.json();
    return;
}

// payment

const WebinerPayment = async ({ name, email, number, product, forminatorFormId }) => {
    const baseUrl = await fetchCountryInfo()
    const response = await fetch(baseUrl + `/wp-json/payment/v2/landing-page?ooo=99909`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "billing_address": `{\"city\":\"City\",\"country\":\"Country\",\"address_1\":\"Address 1\",\"postcode\":\"123456\",\"state\":\"State\",\"phone\":\"${number}\",\"first_name\":\"${name}\",\"last_name\":\"\"}`,
            "customer": `{\"first_name\":\"${name}\",\"last_name\":\"\",\"email\":\"${email}\"}`,
            "domain": "qacademy.tech",
            // "domain": "192.168.1.8:3000",
            "forminatorFormId": forminatorFormId,
            "line_items": [{
                "name": product?.name,
                "image": "../images/imageone.png",
                "id": product?.product_id,
                "price": product?.sale_price,
                "quantity": 1
            },]
        })
    });
    const res = await response.json();
    window.location.href = res?.session.url
    return;
}

export const WebinerPaymentSuccess = async ({ sessionID }) => {
    const formData = new FormData();
    formData.append('sessionID', sessionID)
    const baseUrl = await fetchCountryInfo()
    const response = await fetch(baseUrl + `/wp-json/lmscourses/v2/get-checkout-session`, {
        method: 'POST',
        body: formData
    }
    );
    const res = await response.json();
    Webinar_Registor_If_user_Email_Registor({ intendID: res?.body?.payment_intent })
    return;
}