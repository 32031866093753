import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { useMediaQuery } from 'react-responsive';
import leftArrow from '../images/icons/Arrow_l.svg'
import rightArrow from '../images/icons/Arrow_r.svg'

export default function Feedbackspearte({ showTitle }) {

  const { isLogin } = useSelector((state) => state?.user || {});
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLogin])

  // added
  const sliderRef = useRef(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 2000,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          speed: 500,
          autoplaySpeed: 2000, // Show 2 cards at a time on medium screens
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          speed: 500,
          autoplaySpeed: 2000, // Show 2 cards at a time on medium screens
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          speed: 500,
          autoplaySpeed: 2000, // Show 1 card at a time on small screens (mobile devices)
        }
      }
    ]

  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const show = showTitle;

  const isBigScreen = useMediaQuery({ query: '(min-width: 700px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' })


  const StyleProfileBig = {

    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '50%',

  }
  const setborderBig = {
    border: '1px solid rgb(171 167 167 / 50%)',

    borderRadius: '8px',
    height: '140px'
  }

  const StyleProfile = {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    objectFit: 'cover',
  }
  const setborder = {
    border: '1px solid rgb(171 167 167 / 50%)',
    borderRadius: '8px',
  }
  const [hideCard, setShowCard] = useState(false)
  const [Hide, setHide] = useState()
  const ShoeMore = () => {
    setShowCard(!hideCard)

    setHide(true)
  }
  return (
    <section style={{
      marginBottom: '-30px',
    }} className="pb-3 pt-0 pt-lg-5" >
      <div className="container">
        {
          show && (
            <>
              {isTabletOrMobile && <section style={{ padding: '20px' }}></section>}
              <div className="row">
                <h3 className="text-center" style={{ letterSpacing: '1px', color: '#0F75BC' }}>
                  From the Q Academy Community
                </h3>
                <h6 className="text-center" style={{ letterSpacing: '1px', color: '#565656' }}>
                  Trusted by 1000+ learners
                </h6>
              </div>
            </>
          )
        }
        <div className="d-flex align-items-center mt-4">
          {isBigScreen &&
            <div className='col-1'  >
              <button
                onClick={goToPrevSlide}
                className="btn justify-content-center align-items-center"
              >
                <img src={leftArrow} alt="" style={{ height: "58px", width: "58px" }} />
              </button>

            </div>}

          {isTabletOrMobile && <div className='col-1' >
            <button
              // style={sliderPrevButtonStyles}
              onClick={goToPrevSlide}
              className="btn justify-content-center align-items-center"
              style={{ marginLeft: '-40px', left: '20px'}}
            >
            <img src={leftArrow} alt="" style={{ height: "50px", width: "50px" }} />
          </button>
          </div>
          }
        {isTabletOrMobile && <div className="col-10" style={{
          //  background: 'red'
        }} >
          <Slider {...settings} ref={sliderRef}>
            <div className="col-lg-4 col-md-6"
            >
              <div className="card rounded border-0"
                style={{ backgroundColor: 'transparent' }}
              >
                <div className="d-block " style={{ padding: '0px 12px' }}>
                  <div className="card-body " style={setborder}>
                    <div className=''>
                      <p style={{
                        letterSpacing: '1px',
                        textAlign: 'center',
                        fontSize: 'smaller',
                        color: ' #000000'
                      }}>Q Academy has a platform that offers exceptional future skill programs.
                        The courses provided are truly remarkable packed with outstanding content and delivered by highly skilled trainers
                        {Hide && <a onClick={ShoeMore} style={{ color: 'blue' }}>...read more</a>}
                        {hideCard &&
                          <>
                            If you're looking to secure a bright future,
                            I wholeheartedly recommend Q Academy. My best wishes are with them!
                          </>}</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                    <img
                      style={StyleProfile}
                        src="/assets/images/avatar/16.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body  ">
                    <h5 className="card-title text-center"
                      style={{
                        letterSpacing: '1px', fontWeight: 'medium',
                        fontSize: '18px', padding: "5px", color: '#565656'
                      }}
                    >
                      Nisha Kumari
                    </h5>
                    <div className="text-center mt-2 mb-2">
                      <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px', color: '#565656' }}>
                        India
                      </p>
                    </div>

                  </div>

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card rounded border-0" style={{ backgroundColor: 'transparent' }}>
                <div className="d-block" style={{ padding: '0px 12px' }}>

                  <div className="card-body " style={setborder}>
                    <div>
                      <p style={{
                        letterSpacing: '1px',
                        textAlign: 'center',
                        fontSize: 'smaller',
                        color: ' #000000'
                      }}>Q Academy is amazing The explaination is very
                        good and faculty was very friendly. our faculty vignesh
                        sir taught us very well I enrolled in data science program which helps me to develop my skills.</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                    <img

                      style={StyleProfile}
                      src="https://i.ibb.co/fvsWyy3/Whats-App-Image-2023-07-22-at-8-02-41-PM-1.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body ">
                    <h5 className="card-title text-center" style={{
                      letterSpacing: '1px', fontWeight: 'medium',
                      fontSize: '18px', padding: "5px", color: '#565656'
                    }}>
                      Parag Kushwah
                    </h5>
                    <div className="text-center mt-2 mb-2">
                      <p className="card-text"

                        style={{ letterSpacing: '1px', padding: "5px", color: '#565656' }}

                      >
                        India
                      </p>
                      <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card rounded border-0" style={{ backgroundColor: 'transparent' }}>
                <div className="d-block" style={{ padding: '0px 12px' }}>
                  <div className="card-body" style={setborder}>
                    <div>
                      <p style={{
                        letterSpacing: '1px',
                        textAlign: 'center',
                        fontSize: 'smaller',
                        color: ' #000000'
                      }}>
                        It was nice journey with Q Academy and the
                        teaching skills of facility was too good and
                        He explains it several times until students understand.</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                    <img
                      style={StyleProfile}
                      src="https://i.ibb.co/BjfRfcJ/Whats-App-Image-2023-07-22-at-8-02-41-PM.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body ">
                    <h5 className="card-title text-center"

                      style={{
                        letterSpacing: '1px', fontWeight: 'medium',
                        fontSize: '18px', padding: "5px", color: '#565656'
                      }}

                    >
                      Shaikh Sumair
                    </h5>
                    <div className="text-center mt-2 mb-2">
                      <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                        India
                      </p>
                      <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card rounded " style={{ backgroundColor: 'transparent' }}>
                <div className="d-block" style={{ padding: '0px 12px' }}>
                  <div className="card-body " style={setborder}>
                    <div>
                      <p style={{
                        letterSpacing: '1px',
                        textAlign: 'center',
                        fontSize: 'smaller',
                        color: ' #000000'
                      }}>The expert instruction, well-structured curriculum, and engaging learning materials provided a comprehensive and enjoyable learning experience. Prasunamba mam made the course enjoyable.</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                    <img
                      style={StyleProfile}
                      src="https://i.ibb.co/XzMLtWP/Whats-App-Image-2023-07-22-at-8-03-04-PM.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body ">
                    <h5 className="card-title text-center"
                      //  style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}
                      style={{
                        letterSpacing: '1px', lineHeight: "1.0", fontWeight: 'medium',
                        fontSize: '18px', padding: "5px", color: '#565656'
                      }}
                    >
                      Ahmed Abdul Qayyum
                    </h5>
                    <div className="text-center mt-2 mb-2">
                      <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                        India
                      </p>
                      <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card rounded " style={{ backgroundColor: 'transparent' }}>
                <div className="d-block" style={{ padding: '0px 12px' }}>
                  <div className="card-body " style={setborder}>
                    <div>
                      <p style={{
                        letterSpacing: '1px',
                        textAlign: 'center',
                        fontSize: 'smaller',
                        color: ' #000000'
                      }}>Thanks to Q Academy for providing all the support in completing the technological courses that were required by me. The faculty and the process of teaching is truly engaging. This is very helpful and the faculty is very supportive and makes the task alot more easier.</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                    <img
                      style={StyleProfile}
                        src="/assets/images/avatar/17.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body ">
                    <h5 className="card-title text-center" style={{
                      letterSpacing: '1px', fontWeight: 'medium',
                      fontSize: '18px', padding: "5px", color: '#565656'
                    }}>
                      Satwik Reddy
                    </h5>
                    <div className="text-center mt-2 mb-2">
                      <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                        India
                      </p>
                      <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="card rounded " style={{ backgroundColor: 'transparent' }}>
                <div className="d-block" style={{ padding: '0px 12px' }}>
                  <div className="card-body " style={setborder}>
                    <div>
                      <p style={{
                        letterSpacing: '1px',
                        textAlign: 'center',
                        fontSize: 'smaller',
                        color: ' #000000'
                      }}>
                        The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                        Thanks QA team</p>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                    <img
                      style={StyleProfile}
                        src="/assets/images/avatar/18.jpg"
                      alt=""
                    />
                  </div>
                  <div className="card-body ">
                    <h5 className="card-title text-center" style={{
                      letterSpacing: '1px', fontWeight: 'medium',
                      fontSize: '18px', padding: "5px", color: '#565656'
                    }}>
                      Mohammad Azim
                    </h5>
                    <div className="text-center mt-2">
                      <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                        India
                      </p>
                      <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>}

        {isBigScreen &&
          <div className="col-10" style={{
            //  background: 'red'
          }} >
            <Slider {...settings} ref={sliderRef}>
              <div className="col-lg-4 col-md-6"
              >
                <div className="card rounded border-0"
                  style={{ backgroundColor: 'transparent' }}
                >
                  <div className="d-block " style={{ padding: '0px 12px' }}>
                    <div className="card-body " style={setborderBig}>
                      <div className=''>

                        <p style={{

                          letterSpacing: '1px',
                          textAlign: 'center',
                          fontSize: 'small',
                          color: ' #000000'
                        }}>Q Academy has a platform that offers exceptional future skill programs.
                          The courses provided are truly remarkable packed with outstanding content and delivered by highly skilled trainers
                          {Hide && <a onClick={ShoeMore} style={{ color: 'blue' }}>...read more</a>}
                          {hideCard &&
                            <>


                              If you're looking to secure a bright future,
                              I wholeheartedly recommend Q Academy. My best wishes are with them!
                            </>}</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                      <img
                        style={StyleProfileBig}
                          src="/assets/images/avatar/16.jpg"
                        alt=""
                      />
                    </div>
                    <div className="card-body  ">
                      <h5 className="card-title text-center"
                        style={{
                          letterSpacing: '1px', fontWeight: 'medium',
                          fontSize: '18px', padding: "5px", color: '#565656'
                        }}
                      >
                        Nisha Kumari
                      </h5>
                      <div className="text-center mt-2 mb-2">
                        <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px', color: '#565656' }}>
                          India
                        </p>
                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="card rounded border-0" style={{ backgroundColor: 'transparent' }}>
                  <div className="d-block" style={{ padding: '0px 12px' }}>

                    <div className="card-body " style={setborderBig}>
                      <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center',
                          fontSize: 'small',
                          color: ' #000000'
                        }}>Q Academy is amazing The explaination is very
                          good and faculty was very friendly. our faculty vignesh
                          sir taught us very well I enrolled in data science program which helps me to develop my skills.</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                      <img

                        style={StyleProfileBig}
                        src="https://i.ibb.co/fvsWyy3/Whats-App-Image-2023-07-22-at-8-02-41-PM-1.jpg"
                        alt=""
                      />
                    </div>
                    <div className="card-body ">
                      <h5 className="card-title text-center"
                        style={{
                          letterSpacing: '1px', fontWeight: 'medium',
                          fontSize: '18px', padding: "5px", color: '#565656'
                        }}
                      >
                        Parag Kushwah
                      </h5>
                      <div className="text-center mt-2 mb-2">
                        <p className="card-text"

                          style={{ letterSpacing: '1px', padding: "5px", color: '#565656' }}

                        >
                          India
                        </p>
                        <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="card rounded border-0" style={{ backgroundColor: 'transparent' }}>
                  <div className="d-block" style={{ padding: '0px 12px' }}>
                    <div className="card-body" style={setborderBig}>
                      <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center',
                          fontSize: 'small',
                          color: ' #000000'
                        }}>
                          It was nice journey with Q Academy and the
                          teaching skills of facility was too good and
                          He explains it several times until students understand.</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                      <img
                        style={StyleProfileBig}
                        src="https://i.ibb.co/BjfRfcJ/Whats-App-Image-2023-07-22-at-8-02-41-PM.jpg"
                        alt=""
                      />
                    </div>
                    <div className="card-body ">
                      <h5 className="card-title text-center"
                        // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}

                        style={{
                          letterSpacing: '1px', fontWeight: 'medium',
                          fontSize: '18px', padding: "5px", color: '#565656'
                        }}
                      >
                        Shaikh Sumair
                      </h5>
                      <div className="text-center mt-2 mb-2">
                        <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                          India
                        </p>
                        <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="card rounded " style={{ backgroundColor: 'transparent' }}>
                  <div className="d-block" style={{ padding: '0px 12px' }}>
                    <div className="card-body " style={setborderBig}>
                      <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center',
                          fontSize: 'small',
                          color: ' #000000'
                        }}>The expert instruction, well-structured curriculum, and engaging learning materials provided a comprehensive and enjoyable learning experience. Prasunamba mam made the course enjoyable.</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                      <img
                        style={StyleProfileBig}
                        src="https://i.ibb.co/XzMLtWP/Whats-App-Image-2023-07-22-at-8-03-04-PM.jpg"
                        alt=""
                      />
                    </div>
                    <div className="card-body ">
                      <h5 className="card-title text-center"
                        //  style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}
                        style={{
                          letterSpacing: '1px', fontWeight: 'medium',
                          fontSize: '18px', padding: "5px", color: '#565656'
                        }}
                      >
                        Ahmed Abdul Qayyum
                      </h5>
                      <div className="text-center mt-2 mb-2">
                        <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                          India
                        </p>
                        <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="card rounded " style={{ backgroundColor: 'transparent' }}>
                  <div className="d-block" style={{ padding: '0px 12px' }}>
                    <div className="card-body " style={setborderBig}>

                      <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center',
                          fontSize: 'small',
                          color: ' #000000'
                        }}>Thanks to Q Academy for providing all the support in completing the technological courses that were required by me. The faculty and the process of teaching is truly engaging. This is very helpful and the faculty is very supportive and makes the task alot more easier.</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                      <img
                        style={StyleProfileBig}
                          src="/assets/images/avatar/17.jpg"
                        alt=""
                      />
                    </div>
                    <div className="card-body ">
                      <h5 className="card-title text-center"
                        // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px",color: '#565656' }}

                        style={{
                          letterSpacing: '1px', fontWeight: 'medium',
                          fontSize: '18px', padding: "5px", color: '#565656'
                        }}
                      >
                        Satwik Reddy
                      </h5>
                      <div className="text-center mt-2 mb-2">
                        <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                          India
                        </p>
                        <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="card rounded " style={{ backgroundColor: 'transparent' }}>
                  <div className="d-block" style={{ padding: '0px 12px' }}>
                    <div className="card-body " style={setborderBig}>
                      <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center',
                          fontSize: 'small',
                          color: ' #000000'
                        }}>
                          The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                          Thanks QA team</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                      <img
                        style={StyleProfileBig}
                          src="/assets/images/avatar/18.jpg"
                        alt=""
                      />
                    </div>
                    <div className="card-body ">
                      <h5 className="card-title text-center"
                        // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px",color: '#565656' }}
                        style={{
                          letterSpacing: '1px', fontWeight: 'medium',
                          fontSize: '18px', padding: "5px", color: '#565656'
                        }}
                      >
                        Mohammad Azim
                      </h5>
                      <div className="text-center mt-2">
                        <p className="card-text" style={{ letterSpacing: '1px', display: 'inline', marginRight: '5px' }}>
                          India
                        </p>
                        <hr style={{ margin: '0', borderWidth: '0 0 1px', borderColor: '#000', width: '50%', fontWeight: 'bold' }} />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        }
        {isBigScreen &&
          <div className='col-1' >
            <button
              // style={sliderPrevButtonStyles}
              onClick={goToNextSlide}
              className="btn justify-content-center align-items-center"
              style={{ marginLeft: '13px' }}
            >
              <img src={rightArrow} alt="" style={{ height: "58px", width: "58px" }} />
            </button>
          </div>}
        {isTabletOrMobile &&
          <div className='col-1' >
            <button
              // style={sliderPrevButtonStyles}
              onClick={goToNextSlide}
              className="btn justify-content-center align-items-center"
              style={{ marginLeft: '-42px', left: '18px' }}
            >
              <img src={rightArrow} alt="" style={{ height: "50px", width: "50px" }} />
            </button>
          </div>}
      </div>
    </div>
    </section>
  );
}