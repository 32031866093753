import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from "react-router-dom";

export default function CoursesCat() {
  const {category} = useSelector((state) => state?.course);
  return (
    <>
      <section>
        <div className="container">
          {/* Title */}
          <div className="row mb-4">
            <div className="col-lg-8 mx-auto text-center">
              <h2>Choose a Categories</h2>
              <p className="mb-0">
                Perceived end knowledge certainly day sweetness why cordially
              </p>
            </div>
          </div>
          <div className="row g-4">
          {category.map((course, index)=>(
            <>
              <div className="col-sm-6 col-md-4 col-xl-3 ">
                <div className="card card-body bg-success bg-opacity-10 text-center position-relative btn-transition p-4 card-color-light-nb">
                  {/* Image */}
                  <div className="icon-xl bg-body mx-auto rounded-circle mb-3">
                    <img src="/assets/images/element/data-science.svg" alt="" />
                  </div>
                  {/* Title */}
                  <h5 className="mb-2">
                    <Link to="./course-grid.html" className="stretched-link color-w ">
                      {course?.name}
                    </Link>
                  </h5>
                  {/* <h6 className="mb-0 color-w ">15 Courses</h6> */}
                </div>
              </div>
            </>
          ))}

            

          </div>
        </div>
      </section>
    </>
  )
}
