import React, {memo, useEffect, useState} from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Helper } from '../support/helpers';

const PrivateRoute = () => {
    const [login, setLogin] = useState(false);

    // useEffect(()=>{
    //     setLogin(Helper._getAuth()?.token ? true :  false)
    // }, []);
    

    return login ? <Outlet /> : <Navigate to={Helper._routeByName('courses')} />;
};

export default memo(PrivateRoute);