
import React from 'react';
import CoursesCat from '../pages/courses_cat.view';
import NewLogin from '../pages/new.login';
import Careers from '../pages/Careers';
import StudentLabs from '../pages/labs.view';
import SuccessPage from '../pages/SuccessPage/SuccessPage';
import { Button } from 'react-bootstrap';
import Version from '../pages/version';
import DeleteAccount from '../pages/DeleteAccount';
import SiteMap from '../pages/SiteMap';
const CoursesMain = React.lazy(() => import('../pages/courses_view_main'));
const Layout = React.lazy(() => import('../pages/layout/layout'));
const Home = React.lazy(() => import('../pages/home.view'));
const Contact = React.lazy(() => import('../pages/contact.view'));
const About = React.lazy(() => import('../pages/about.view'));
const Courses = React.lazy(() => import('../pages/courses.view'));
const Login = React.lazy(() => import('../pages/new.login'));
const Success_Message = React.lazy(() => import('../pages/success_message'));
const Failed_Message = React.lazy(() => import('../pages/failed_message'));
const Signup = React.lazy(() => import('../pages/signup'));
const Cart = React.lazy(() => import('../pages/cart'));
const CoursesDetail = React.lazy(() => import('../pages/new_courses.details'));
const Checkout = React.lazy(() => import('../pages/new.checkout'));
const Subscription = React.lazy(() => import('../pages/student_subscription_view'));
const Instructors = React.lazy(() => import('../pages/instructors_list'));
const PaymentFailed = React.lazy(() => import('../pages/payment_failed'));
const PaymentSuccess = React.lazy(() => import('../pages/payment_success'));
const University = React.lazy(() => import('../pages/university'));
const Industry = React.lazy(() => import('../pages/industry'));
const ViewAllCourses = React.lazy(() => import('../pages/components/View.courses'));
const WeOffer = React.lazy(() => import('../pages/layout/we.offer'));
const ForBusiness = React.lazy(() => import('../pages/components/for.business'));
const PrivacyPolicy = React.lazy(() => import('../pages/Privacy'));
const CancelationRefund = React.lazy(() => import('../pages/CancellationRefund'));
const Terms = React.lazy(() => import('../pages/Terms'));
const ResetPassword = React.lazy(() => import('../pages/reset.password'));
const Blog_view_all = React.lazy(() => import('../pages/Blog/BlogViewAll'));
const Blog = React.lazy(() => import('../pages/Blog/Blog'));
const Roadmap = React.lazy(() => import('../pages/Roadmap/Roadmap'));
const LandingPage = React.lazy(() => import('../pages/Roadmap/LandingPage'));
const DataScienceCourseLandingPage = React.lazy(() => import('../pages/Roadmap/CoursePage'));
const FastLane = React.lazy(() => import('../component/FastLanePages/FastLaneProductDetail'));
import CareersForm from '../AddedPages/CareersForm.jsx';
import ViewJobApplicants from '../AddedPages/ViewApplicant.jsx';
import DataScience from '../pages/Roadmap/course-pages/DataScience.tsx';
import ArtificialIntelligence from '../pages/Roadmap/course-pages/ArtificialIntelligence.tsx';
import CyberSecurity from '../pages/Roadmap/course-pages/CyberSecurity.tsx';
import DigitalMarketing from '../pages/Roadmap/course-pages/DigitalMarketing.tsx';
import FullStack from '../pages/Roadmap/course-pages/FullStack.tsx';
import ThanksPage from '../pages/Roadmap/course-pages/ThanksPage.tsx';

// caution: also update the routes in generate-sitemap.js

export const routes = [
    {
        name: "reset-password",
        key: "reset-password",
        route: "/reset-password",
        component: <ResetPassword />
    },
    {
        name: "terms",
        key: "terms",
        route: "/terms-and-conditions",
        component: <Layout children={<Terms />}></Layout>
    },
    {
        name: "privacy",
        key: "privacy",
        route: "/privacy-policy",
        component: <Layout children={<PrivacyPolicy />}></Layout>
    },
    {
        name: "cancellation",
        key: "cancellation",
        route: "/cancellations-refund",
        component: <Layout children={<CancelationRefund />}></Layout>
    },
    {
        name: "for-Corporate",
        key: "for-Corporate",
        route: "/for-corporate",
        component: <Layout children={<ForBusiness />}></Layout>

    },
    {
        name: "we-offer",
        key: "we-offer",
        route: "/we-offer",
        component: <WeOffer />
    },
    {
        name: "view-all-courses",
        key: "view-all-courses",
        route: "/courses/:id?/:type?/:name?/:typeName?",
        component: <Layout children={<ViewAllCourses />}></Layout>
    },
    {
        name: "industry",
        key: "industry",
        route: "/industry-partners",
        component: <Layout children={<Industry />}></Layout>
    },
    {
        name: "university",
        key: "university",
        route: "/university-partners",
        component: <Layout children={<University />}></Layout>
    },
    {
        name: "Home",
        key: "Home",
        route: "/",
        component: <Layout children={<Home />}></Layout>
    },
    {
        name: "about-us",
        key: "about",
        route: "/about-us",
        component: <About />
    },
    ,
    {
        name: "contact-us",
        key: "contact",
        route: "/contact-us",
        component: <Layout children={<Contact />}></Layout>
    },
    ,
    {
        name: "courses-cat",
        key: "courses-cat",
        route: "/courses-cat",
        component: <Layout children={<CoursesCat />}></Layout>
    },
    {
        name: "courses-main",
        key: "courses-main",
        route: "/courses-main",
        component: <Layout children={<CoursesMain />}></Layout>
    },
    ,
    {
        name: "courses",
        key: "courses",
        route: "/courses",
        component: <Layout children={<ViewAllCourses />}></Layout>
    },
    ,
    {
        name: "login",
        key: "login",
        route: "/login",
        component: <Layout children={<NewLogin />}></Layout>
    },
    ,
    {
        name: "success_message",
        key: "success_message",
        route: "/success_message",
        component: <Layout children={<Success_Message />}></Layout>
    },
    ,
    {
        name: "failed_message",
        key: "failed_message",
        route: "/failed_message",
        component: <Layout children={<Failed_Message />}></Layout>
    },
    ,
    {
        name: "signup",
        key: "signup",
        route: "/signup",
        component: <Layout children={<Signup />}></Layout>
    },
    ,
    {
        name: "cart",
        key: "cart",
        route: "/cart",
        component: <Layout children={<Cart />}></Layout>
    },
    ,
    {
        name: "coursesdetail",
        key: "coursesdetail",
        route: "/course/:slug/:id?",
        component: <Layout children={<CoursesDetail />}></Layout>
    },
    ,
    {
        name: "checkout",
        key: "checkout",
        route: "/checkout",
        component: <Layout children={<Checkout />}></Layout>
    },
    {
        name: "success",
        key: "success",
        route: "checkout-success",
        component: <Layout children={<PaymentSuccess />}></Layout>
    }
    ,
    {
        name: "failed",
        key: "failed",
        route: "checkout-failed",
        component: <Layout children={<PaymentFailed />}></Layout>
    }
    ,
    {
        name: "subscription",
        key: "subscription",
        route: "/subscription",
        component: <Layout children={<Subscription />}></Layout>
    },
    ,
    {
        name: "instructors",
        key: "instructors",
        route: "/instructors",
        component: <Layout children={<Instructors />}></Layout>
    },
    {
        name: "careers",
        key: "careers",
        route: "/careers",
        component: <Layout children={<Careers />}></Layout>
    },
    {
        name: "labs",
        key: "labs",
        route: "/labs",
        component: <Layout children={<StudentLabs />}></Layout>
    },
    {
        name: "blog",
        key: "blog",
        route: "/blog/:id",
        component: <Layout children={<Blog />}></Layout>
    },
    {
        name: "blog-view-all",
        key: "blog-view-all",
        route: "/blog",
        component: <Layout children={<Blog_view_all />}></Layout>
    },
    {
        name: "data-and-analytics",
        key: "data-and-analytics",
        route: "/data-and-analytics",
        component: <Roadmap />
    },
    {
        name: "cloud-computing",
        key: "cloud-computing",
        route: "/cloud-computing",
        component: <LandingPage />
    },
    {
        name: "data-science-course",
        key: "data-science-course",
        route: "/data-science-course",
        component: <DataScienceCourseLandingPage />
    },
    {
        name: "Lab",
        key: "Lab",
        route: "/Lab",
        component: <Layout children={<StudentLabs />}></Layout>
    },
    {
        name: "course",
        key: "course",
        route: "/coursee/:id/:title",
        component: <Layout children={<FastLane />}></Layout>
    },
    {
        name: "checkout success",
        key: "checkout success",
        route: "/wabinar-checkout-success",
        component: <Layout children={<SuccessPage />}></Layout>
    },
    {
        name: "version of the website",
        key: "",
        route: "/version/:id",
        component: <Version />

    },
    {
        name: "404",
        key: "",
        route: "*",
        component: <Layout><h1 className='m-5 text-center p-5'>404 PAGE NOT FOUND</h1></Layout>
    },
    {
        name: "Delete account",
        key: "delete-account",
        route: "/delete-account",
        component: <DeleteAccount />
    },
    {
        name: "Site Map",
        key: "site-map",
        route: "/site-map",
        component: <Layout children={<SiteMap />}></Layout>
    },
    {
        name: "Add Job",
        key: "Add Job",
        route: "/add-job",
        component: <Layout><CareersForm /></Layout>
    },
    {
        name: "Job Application",
        key: "Job Application",
        route: "/view-job-application",
        component: <Layout><ViewJobApplicants /></Layout>
    },
    // caution: also update the routes in generate-sitemap.js this above path already exist in generate-sitemap.js file so no need to update it again in generate-sitemap.js file but "if you want to add new path then you have to update it in generate-sitemap.js file"
    {
        name: "Data Science",
        key: "Data Science",
        route: "/channel-partner/data-science",
        component: <DataScience />
    },
    {
        name: "ArtificialIntelligence",
        key: "ArtificialIntelligence",
        route: "/channel-partner/artificial-intelligence",
        component: <ArtificialIntelligence />
    },
    {
        name: "CyberSecurity",
        key: "CyberSecurity",
        route: "/channel-partner/cyber-security",
        component: <CyberSecurity />
    },
    {
        name: "Digital Marketing",
        key: "Digital Marketing",
        route: "/channel-partner/digital-marketing",
        component: <DigitalMarketing />
    },
    {
        name: "fullStackDevelopment",
        key: "fullStackDevelopment",
        route: "/channel-partner/full-stack-development",
        component: <FullStack />
    },
    {
        name: "Thankpage",
        key: "Thankpage",
        route: "/thanking-you",
        component: <Layout><ThanksPage /></Layout>
    }
]