import React, { useEffect } from 'react'
import '../Blog/blogStyles.css'
import './roadmap.css'
import { Alert, Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { AddToCart, ContactUs, UpdateCartInfo } from '../../services/Services';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RoadmapCourse } from './constFile';
import { setRCartCookies, setRCartData } from '../../app/reducers/cartReducer';
import { setCommonLoading, setShowLoginSignup } from '../../app/reducers/courseReducer';
import Loading from '../loading';
import Layout from '../layout/layout';
import { FooterLandingPage, RegisterNowLanding, TestimonialTrailLanding } from './LandingPage';
import FeedbackView from '../feedback.view';

function ContainerBody({children,className}) {
        return (
            <Container className={`body + ${className}`} >
                {children}
            </Container>
        )
    }

export function HeaderContent({ onClick: onClick = () => { }, data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cartCookies } = useSelector(state => state.cart);
    const { isLogin } = useSelector(state => state.user);
    const { commonLoading } = useSelector(state => state.course);

    async function addToCart({ ID, amount, product }) {
        dispatch(setCommonLoading(true));
        AddToCart(
            { id: ID, amount: amount, product: product, cookies: cartCookies },
            dispatch
        ).then(async (response) => {
            if (response?.response?.code == 200 || response?.response?.code == 201) {
                await dispatch(
                    setRCartData({ type: "ADD_CART_DATA", payload: response?.body })
                );
                await dispatch(setRCartCookies({ payload: response?.cookies }));
                await UpdateCartInfo();
                navigate("/checkout", { replace: true });
            } else if (response?.response?.code == 400) {
                navigate("/checkout", { replace: true });
            }
            dispatch(setCommonLoading(false));
        });
    }

    async function registerNow() {
        // if (isLogin) {
            await addToCart({
                ID: data.product_id,
                amount: data.price,
                product: data.name,
            });
        // } else {
        //     dispatch(setShowLoginSignup(true))
        // }

    }

    if (commonLoading) {
        return <Loading />
    } else {
    return (
        <>
            <div className='headerContainer'>
                <div className='HeaderContent'>
                    <Image src={'/assets/images/LandingPage/liveLogo.png'} alt='roadmap' rounded className='liveLogo' />
                    <h2 className='text-black'>on</h2>
                    <h1 className='text-primary-color  mb-4 lh-1'><p className='text-primary-color'>The IT Roadmap For </p> Data And Analytics</h1>
                    <p className='text-black fs-5'> <i className='fa fa-calendar' /> April 22, 2024  </p>
                    <Button href={![undefined, null, ''].includes(data) ? '#form' : '/data-and-analytics'} onClick={() => [undefined, null, ''].includes(data) ? onClick() : {}} className='button fs-3'>Enroll for ₹ 99/-</Button>
                </div>
            </div>
        </>
    )
    }
}

function AboutWebinar() {
    return (
        <Row>
            <Col lg='3' md='3' className='hideOnMobile'>
                <Image src={'/assets/images/raodmap2.png'} alt='roadmap' rounded className='h-75' />
            </Col>
            <Col lg='3' md='3' className='hideOnMobile ' >
                <Image src={'/assets/images/raodmap/image2 .png'} alt='roadmap' rounded 
                    className='h-75' />
            </Col>
            <Col lg='6' md='6' sm='12' xs='12' className='p-5 pt-0 pb-0' >
                <p className='fs-5'>About the webinar</p>
                <h2 className='text-primary-color'>The IT roadmap for data and analytics</h2>
                <p className='fs-5 mt-3'>The webinar is designed by Q Academy specifically for those who live and sleep data. Moreover, Dr. Deepak Gour, our experienced data and analytics expert, will host the webinar.</p>

                <Row className='pt-2 pb-5'>
                    <Col lg='6' md='6' sm='6' xs='6'>
                        <Row className='align-items-center' >
                            <Col lg='3' >
                                <i className='fa fa-calendar big-icon' />
                            </Col>
                            <Col lg='9' className='justify-content-center' >
                                <h3 className='text-primary-color'>When</h3>
                                <p className='fs-6 text-black'>April 22, 2024</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg='6' md='6' sm='6' xs='6'>
                        <Row className='align-items-center' >
                            <Col lg='3' >
                                <i className='fa fa-clock-o big-icon' />
                            </Col>
                            <Col lg='9' className='justify-content-center' >
                                <h3 className='text-primary-color'>Time</h3>
                                <p className='fs-6 text-black'>11:00 AM to 12:00 PM</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Button className='btn-black fs-3' href='#form'>Enroll for ₹ 99/-</Button>
            </Col>

        </Row>
    )
}

function AboutHost() {
    return (
        <div className='aboutHost' >
            <div className='deepakGourImage' >
                <Container>
                    <Row>
                        <h2 className='text-white mb-4 pt-4 text-center'>Not just a host<p className='text-white'>your data superhero!</p> </h2>
                        <Col lg='8' md='8' sm='12' xs='12'>
                    <div className='hostInfo' >
                                <h3 className='text-white'>Dr. Deepak Gour  </h3>
                                <p className='fs-5 mt-3 text-white'>PhD computer science & engineering, has over 16 years of experience in cloud computing, cyber security, data science AI, and ML.</p>
                    </div>
                    <h2 className='text-white mt-5'>How will our data superhero help you?</h2>
                    {/* make a number list 1 to 5 */}
                            <ol className='fs-5 text-white'>
                        <li>Providing factual information</li>
                        <li>Solving maximum queries</li>
                        <li>Offering unique explanations and solutions</li>
                        <li>Providing external resources</li>
                        <li>Generating and delivering creative content solutions</li>
                    </ol>
                </Col>
                <Col lg='4' md='4' className='hideOnMobile'>
                </Col>
            </Row>
                </Container>
            </div>
        </div>
    )
}

function WhyJoin() {

    const data =[
        {
            icon: 'fa fa-calendar',
            title: 'Upskill',
            text: 'Future-proof and sharpen your cloud computing skills',
            image: '/assets/images/raodmap/challenge.png'
        },
        {
            icon: 'fa fa-calendar',
            title: 'Boost your career',
            text: 'Gain insights into the job market for data professionals  and learn the technical and strategic skills',
            image: '/assets/images/raodmap/shuttle.png'
        },
        {
            icon: 'fa fa-calendar',
            title: 'Increase your earning potential',
            text: 'Get better career opportunities with improved skills',
            image: '/assets/images/raodmap/revenue.png'
        },
        {
            icon: 'fa fa-calendar',
            title: 'Develop a winning data strategy ',
            text: 'Learn how to build a roadmap for success in data and analytics ',
            image: '/assets/images/raodmap/ribbon.png'
        },


    ]

    return (
        <div className='whyJoin' >
            <h2 className='text-primary-color'>Why should you<p className='text-primary-color' > join this webinar?</p></h2>
            <Row className='justify-content-center mt-3 gap-4' > 
              {
                data.map((item, index) => {
                    return (
                        <Col md='6' sm='12' xs='12' className='infoCard shadow' key={index} >
                            <div className='infoCardTitle' >
                            <Image src={item.image} alt='goal' rounded className='iconImage' />
                            <h3 className='text-white'>{item.title}</h3>
                            <div className='infoCardDivider' />
                            </div>
                            <p className='text-white fs-5 mt-3'>{item.text}</p>
                        </Col>
                    )
                })
              }
            </Row>
        </div>
    )
}

function HowItHelps() {
    const data = [
        {
            title: 'Future-proof career',
            text: 'This webinar will future-proof your career and help you ace the tech world with new trends and',
            image: '/assets/images/raodmap/goal.png'
        },
        {
            title: 'Adaptability and resilience',
            text: 'All the modules covered in this webinar will help you be adaptable to the ongoing challenges in the tech industry.',
            image: '/assets/images/raodmap/adapt.png'
        },
        {
            title: 'Continuous learning',
            text: 'The best opportunity to get along with tech trends.',
            image: '/assets/images/raodmap/continuous-learning.png'
        },
        {
            title: 'Competitive advantage',
            text: 'Learn the updated cloud computing strategies and advance your career.',
            image: '/assets/images/raodmap/advantage.png'
        }
    ]
    return (
        <div className='howItHelps ' >
            <Row className='justify-content-center pt-4 helpRow gap-5' >
                <Col lg='12' md='12' sm='12' xs='12' className='align-items-center justify-content-center d-flex' >
                    <h2 className='text-white  '>How will this<p className='text-white'>webinar help you?</p></h2>
                </Col>
                {
                    data.map((item, index) => {
                        return (
                            <Col sm='12' xs='12' key={index} className='infoCard shadow' >
                                <div className='infoCardTitle'>
                                <Image src={item.image} alt='roadmap' rounded className='iconImage' />
                                <h3 className='text-white text-center '>{item.title}</h3>
                                <div className='infoCardDivider' />
                                </div>
                                <p className='text-white fs-5 mt-3'>{item.text}</p>
                            </Col>
                        )
                    })
                }
            </Row>

            <Row className='helpLowerImage'>
                <Col lg='6' md='6' className='p-5 pt-0 pb-0 hideOnMobile' />
                <Col lg='6' md='6'>
                    <h2 className='text-primary-color'>What topics will be covered?</h2>
                    <ol className='fs-5 text-black'>
                        <li>Data Strategy and Governance</li>
                        <li>Data Infrastructure</li>
                        <li>Data Management and Integration</li>
                        <li>Data Analytics Tools and Techniques</li>
                        <li>Skills and Talent Development</li>
                        <li>Security and Compliance</li>
                    </ol>
                </Col>
            </Row>
        </div>  
    )

}

export function RegisterNowRoadmap({ data }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { cartCookies } = useSelector(state => state.cart);
    const { isLogin } = useSelector(state => state.user);
    const { commonLoading } = useSelector(state => state.course);

    async function addToCart({ ID, amount, product }) {
        dispatch(setCommonLoading(true));
        AddToCart(
            { id: ID, amount: amount, product: product, cookies: cartCookies },
            dispatch
        ).then(async (response) => {
            if (response?.response?.code == 200 || response?.response?.code == 201) {
                await dispatch(
                    setRCartData({ type: "ADD_CART_DATA", payload: response?.body })
                );
                await dispatch(setRCartCookies({ payload: response?.cookies }));
                await UpdateCartInfo();
                navigate("/checkout", { replace: true });
            } else if (response?.response?.code == 400) {
                navigate("/checkout", { replace: true });
            }
            dispatch(setCommonLoading(false));
        });
    }

    async function registerNow() {
        // if (isLogin) {
            await addToCart({
                ID: data.product_id,
                amount: data.price,
                product: data.name,
            });
        // } else {
        //     dispatch(setShowLoginSignup(true))
        // }

    }

    if (commonLoading) {
        return <Loading />
    } else {
    return (
        <div id='form' className='registerNow sideRib-registerForm' >
            <Row className='registerFormImage' >
                <div className='p-lg-5 pt-lg-0 pb-lg-0' style={{
                    height: 400,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                }} >
                    <h1 className='text-primary-color ms-lg-5'>What are you waiting for?</h1>
                    <Form className='m-lg-5 mt-5' >
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control type="text" id='text-input-custom-style'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Enter your name" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control id='text-input-custom-style' type="text" placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control id='text-input-custom-style' type="number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter your phone number" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control id='text-input-custom-style' type="text"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                placeholder="Enter your company name" />
                        </Form.Group> */}
                        <Button className='button fs-2' onClick={() => registerNow()} >Enroll now for ₹ 99/-</Button>
                    </Form>

                </div>
                <Col lg='4' md='auto' className='hideOnMobile'>
                </Col>
            </Row>
            <Button className='button fs-sm-6 stickyButton' onClick={() => registerNow()} >Enroll now for ₹ 99/-</Button>
        </div>
    )
    }
}

function Certificate() {
    return (
        <div className='certification' >
        <Container>
        <Row>
                    <Col lg='4' sm='12' className='d-flex justify-content-lg-center align-items-center pe-lg-5' >
                        <h2 className='text-white certificationTitle'>Get a completion certificate</h2>
        </Col>
        <Col lg='8' sm='12' >
                        <Image src={'/assets/images/roadmapCertificate.png'} alt='certificate' rounded />
        </Col>
        </Row>
        </Container>
        </div>
    )
}

function TestimonialTrail() {
    return (
        <div className='testimonialTrail text-center' >
            <h1 className='text-primary-color mb-4'>Testimonial Trail</h1>
            <Row>
            {
                Array(4).fill().map((_, index) => {
                    return (
                        <Col className='testimonialCard' key={index}>
                            <div className='testimonialImage' >
                                <Image src={'/assets/images/instructor/dummy-profile-.jpg'} alt='testimonial' rounded />
                            </div>
                            <h3 className='text-primary-color mt-2'>John Doe</h3>
                        </Col>
                    )
                } )
            }
            </Row>
            <Button className='btn-black fs-3 mt-3' href='#form'>Enroll now for ₹ 99/-</Button>
        </div>
    )

}

function FAQ(){
    const data = [
        {
            question: 'Will I get any recorded videos after the webinar?',
            answer: 'Yes.'
        },
        {
            question: 'How long will the webinar last?',
            answer: "It's an hour long webinar scheduled for 11:00 AM to 12:00 PM."
        },
        {
            question: 'What will I learn in this webinar?',
            answer: 'Data Strategy and Governance, Data Infrastructure, Data Management and Integration, Data Analytics Tools and Techniques, Skills and Talent Development, and Security and Compliance.'
        },
        {
            question: 'Who will host the webinar?',
            answer: 'Dr. Deepak Gour, PhD computer science & engineering, has over 16 years of experience in cloud computing, cyber security, data science AI, and ML, will host the webinar.'
        },
        {
            question: 'Will I get a completion certificate?',
            answer: "Yes, upon completion of this webinar, you'll. receive a certificate from Q Academy."
        }
    ]
    return (
        <div className='faq p-4' >
            <h2 className='text-white mb-4 text-center'>FAQs</h2>
            {
                data.map((item, index) => {
                    return (
                        <div className='faqCard' key={index} >
                            <h4 className='text-white'>{item.question}</h4>
                            <p className='fs-5 text-white'>{item.answer}</p>
                        </div>
                    )
                })
            }
        </div>
    )

}

function RoadmapBody() {
    return (
        <>
            <div className='sideRib-aboutWebinar' >
                <ContainerBody className='aboutWebinarContainer' children={<AboutWebinar />} />
            </div>
        <AboutHost />
        <ContainerBody children={<WhyJoin />}/>
        <HowItHelps />
            <RegisterNowLanding data={RoadmapCourse} />
        <Certificate />
            <ContainerBody children={<TestimonialTrailLanding />}/>
            <FAQ />
            <FooterLandingPage />
        </>
    )
}

function Roadmap() {
    const mainContainerStyle = {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
    }
    return (
        <div style={mainContainerStyle}>
            <div style={{
                display: 'none'
            }}>
                <Layout />
            </div>
            <div className='roadmapMainContainer text-start'>
                <HeaderContent data={RoadmapCourse} />
            <RoadmapBody />
        </div>
        </div>
    )
}
export default Roadmap;