import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router'
import { WebinerPaymentSuccess } from '../../services/Webiner';
import { Link } from 'react-router-dom';

function SuccessPage() {
    const query = new URLSearchParams(location.search);
     const session_id = query.get('session_id');

    useEffect(()=>{
        if (![undefined,null,''].includes(session_id)){
        WebinerPaymentSuccess({sessionID:session_id})
        }
    },[session_id])

    const style={
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '50vh', 
        flexDirection: 'column'
    }
  return (
    <Container style={style} >
      <h1 className='text-primary mb-4' >Payment Successful!</h1>
      <h2>Congratulations! Your enrollment is complete. We will contact you shortly.</h2>
        <Link to='/' className='btn btn-primary m-5 btn-lg' >Go to Home</Link>
    </Container>
  )
}

export default SuccessPage
