// const cartReducer = (state, action) => {
//   if (action.type === "ADD_TO_CART") {
//     let { id, amount, product } = action.payload;

//     let cartProduct;
//     cartProduct = {
//       id: id,
//       name: product.name,
//       amount,
//       image: product.images[0].guid,
//       price: product.sale_price,
//       max: 1,
//     };

//     let cart = [];
//     if (state.cart) {
//       cart = state.cart;
//       const existItem = cart.find((item) => item.id === cartProduct.id);
//       if (!existItem) {
//         return {
//           ...state,
//           cart: [...state.cart, cartProduct],
//         };
//       }

//     }


//   }

//   if (action.type === "REMOVE_ITEM") {
//     let updatedCart = state.cart.filter(
//       (curItem) => curItem.id !== action.payload
//     );
//     return {
//       ...state,
//       cart: updatedCart,
//     };
//   }

//   return state;
// };

// export default cartReducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cart: {},
  cartItems: [],
  coupons: [],
  cartCookies: [],
  lastOrder: [],
  lastPayment: [],
  userOrders: [],
  userOrderProducts: [],
  cartRemoveLoading: false,
}
export const counterSlice = createSlice({
  name: 'cartReducer',
  initialState,
  reducers: {
    setRCartData: (state, action) => {
      if (action.payload.type === "ADD_TO_CART") {
        let { ID, amount, product } = action.payload.payload;
        let cartProduct;
        cartProduct = {
          id: ID,
          name: product?.name,
          amount,
          image: product?.images[0].guid,
          desc: "This is testing description",
          price: product.sale_price,
          cartQuantity: 1,
        };

        let cart = [];
        if (state.cart) {
          cart = state.cart;
          const existItem = cart.find((item) => item.id === cartProduct.id);
          if (!existItem) {
            return {
              ...state,
              cart: [...state.cart, cartProduct],
            };
          }

        }


      }

      if (action.payload.type === "REMOVE_ITEM") {
        let updatedCart = state.cart.filter(
          (curItem) => curItem.id !== action.payload.payload
        );
        return {
          ...state,
          cart: updatedCart,
        };
      }

      if (action.payload.type === "ADD_CART_DATA") {

        state.cart = action?.payload?.payload
        //state.coupons = action.payload.payload.coupons

        let items = [];
        if (action.payload?.payload?.items.length > 0) {
          action.payload?.payload?.items.map((itemss, index) => {
            items.push({ id: itemss.id, keyID: itemss.key, image: itemss.images[0]?.thumbnail, price: itemss.totals.line_total, name: itemss.name })
          })
        }
        let couponss = [];
        if (action.payload?.payload?.coupons.length > 0) {
          action.payload?.payload?.coupons.map((coupon, index) => {
            couponss.push({ code: coupon.code, discount: coupon.totals.total_discount })
          })
        }

        state.coupons = couponss;
        state.cartItems = items;
      }

      if (action.payload.type === "REMOVE_CART_DATA") {
        state.coupons = []
        state.cartItems = []
        state.cart = {}
      }
    },
    setRCartCookies: (state, action) => {
      state.cartCookies = action.payload.payload
    },
    setRPaymentData: (state, action) => {
      state.lastPayment = action.payload.payload
    },
    setROrderData: (state, action) => {
      state.lastOrder = action.payload.payload
    },
    setRUserOrders: (state, action) => {
      state.userOrders = action.payload.payload
    },
    setRUserOrderProducts: (state, action) => {
      state.userOrderProducts = action.payload.payload
    },
    setRCartRemoveLoading: (state, action) => {
      state.cartRemoveLoading = action.payload
    }
  }
});

// Action creators are generated for each case reducer function
export const { setRCartData, setRCartCookies, setRPaymentData, setROrderData, setRUserOrders, setRUserOrderProducts, setRCartRemoveLoading } = counterSlice.actions

export default counterSlice.reducer