import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { combineReducers } from '@reduxjs/toolkit';
import courseReducer from './reducers/courseReducer';
import userReducer from './reducers/userReducer';
import cartReducer from './reducers/cartReducer';
import storage from 'redux-persist/lib/storage';
import blogReducer from './reducers/Blogs';
import labReducer from './reducers/Lab';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

const persistConfig = {
  key: 'user',
  storage: storage,
  blacklist: ['somethingTemporary'],
}
const cartpersistConfig = {
  key: 'cart',
  storage: storage,
  blacklist: ['user'],
}

const coursepersistConfig = {
  key: 'course',
  storage: storage,
}

const labpersistConfig = {
  key: 'lab',
  storage: storage,
  blacklist: ['user'],
}

const blogpersistConfig = {
  key: 'blog',
  storage: storage,
  blacklist: ['user'],
}

const rootReducer = combineReducers({
  user: persistReducer(persistConfig, userReducer),
  cart: persistReducer(cartpersistConfig, cartReducer),
  course: persistReducer(coursepersistConfig, courseReducer),
  blog: persistReducer(blogpersistConfig, blogReducer),
  lab: persistReducer(labpersistConfig, labReducer),
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
setupListeners(store.dispatch)
export default store;


// export default persistReducer(rootReducer)
// export const store = configureStore({
//   reducer: {
//     course: courseReducer,
//     user:userReducer
//   },
// });


