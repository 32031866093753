import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from './private.route';
import { Helper } from '../support/helpers';
import { routes } from './routes';
import { fetchCountryInfo } from '../services/Services';

function Routing() {
    useEffect(() => {
        fetchCountryInfo();
    }, []);


    const auth = Helper._getAuth();
    const login = auth?.token ? true : false;

    const getRoutes = (allRoutes) => allRoutes.map((route) => {
        if (route?.route) {
            return !route?.middleware ?
                <Route exact path={route?.route} element={login ? <Navigate to={Helper?._routeByName("root")} /> : route?.component} key={route?.key} /> :
                <Route exact path={route?.route} element={<PrivateRoute />} key={route?.key}>
                    <Route exact path={route?.route} element={route?.component} key={route.key} />
                </Route>;
        }
        return null;
    });

    return <Routes>{getRoutes(routes)}</Routes>;
}

export default Routing;
