import { routes } from '../config/routes';
const CryptoJS = require("crypto-js");


export const Helper={
    _Encrypt:(data, key)=>{
        return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    },
    _Decrypt:(data, key)=>{
        const bytes = CryptoJS.AES.decrypt(data, key);

        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    },
    _setAuth:(data)=>{
        sessionStorage.setItem("auth", JSON.stringify({login:true, data: data}));
        return;
    },
    _getAuth:()=>{
        if(sessionStorage.getItem('auth')){
            const auth = JSON.parse(sessionStorage.getItem("auth"));
            if(auth.login){
                const res= Helper._Decrypt(auth.data, 'script-key@login');
                return res.data;
            }
        }
        return false;
    },
    _filter:(arr, criteria)=>arr.filter((obj)=>{
        return Object.keys(criteria).every((c)=>{
            return obj[c] === criteria[c];
        })
    }),
    _routeByName:(key)=>{
        const keyRoute= Helper._filter(routes, {key:key});
        return keyRoute.length>0 && keyRoute[0].route ;
    }
}