import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../images/logo.png";
import { Outlet, Link, useLocation } from "react-router-dom";
import { useCartContext } from "../../app/context/cart_context";
import { getSearchProducts } from "../../services/Services"
import RightHover from "../rightHover";
import {
    setRLoginLoading,
    setRUserData,
    setRIsLogin,
    setRUserID,
    setRUserName,
} from "../../app/reducers/userReducer";
import Hover from "./Hover";
import NewLogin from "../new.login";
import TopHeader from "./TopHeader";
import '../components/styles.css'

export default function AboutHeader() {
    const dispatch = useDispatch();
    const { userData, isLogin, userID, userName } = useSelector(
        (state) => state?.user || {}
    );
    const { cart, cartItems } = useSelector((state) => state?.cart || []);
    const { category, tags, searchProducts } = useSelector((state) => state?.course);
    const [searchTerm, setSearchTerm] = useState('');

    const [showHover, setShowHover] = useState(false);

    // const { cart } = useCartContext();
    const [loggedIn, setLoggedIn] = useState(false);
    const [items, setItems] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])



    function handleSearch(event) {
        const searchTerm = event.target.value;
        setSearchTerm(searchTerm);
    }

    const toggoleHover = () => setShowHover(!showHover);

    function Logout() {
        dispatch(setRIsLogin(0));
        dispatch(setRUserData({}));
        dispatch(setRUserName());
        dispatch(setRUserID(0));
    }

    function redirectLms() {
        let r = (Math.random() + 1).toString(36).substring(7);
        // console.log("random", r);

        window.location.href = 'https://learn.qacademy.tech/wp-json/user/v2/bypasslogin?r' + r + r + r + r + r + r + r + '&user_id=' + userID + '&p=' + r + r + r + r + r + r + r;
    }

    useEffect(() => {

        getSearchProducts(dispatch)
    }, []);

    useEffect(() => {
        setItems(cartItems.length);
    }, [cart, cartItems]);

    useEffect(() => {
        setLoggedIn(isLogin);
    }, [isLogin, userData, userID]);

    const filteredCourses = searchProducts?.filter((course) => {
        return course.name.toLowerCase().includes(searchTerm.toLowerCase());
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const Goals = []

    if (category) {
        category?.map((cat, index) => (
            cat?.courses.map((course, cIndex) => (
                course?.categories.map((ctss, tIndex) => (
                    cat?.tags.map((tagss, tIndex) => (
                        course?.tags.map((tgss, tgIndex) => {
                            if ((tgss.term_id == tagss.id) && ctss.term_id == cat.id) { Goals.push({ category: cat.id, cat_name: cat.name, tag_name: tagss.name, tag: tagss.id, course: course }) }
                        }
                        )
                    )
                    )
                )
                )
            )
            )
        )
        )
    }

    // console.log(Goals, 'Goals')

    function checkGoalsExist(CatID, TagID) {
        const ids = [];
        Goals.map((goal, index) => { if (goal.category == CatID && goal.tag == TagID) { ids.push(TagID) } })

        if (ids.length > 0) {
            return true;
        } else {
            return false;
        }

    }


    const Subjects = []
    if (tags.length > 0) {
        tags.map((cat, index) => (
            cat?.courses.map((course, cIndex) => (
                course?.tags.map((ctss, tIndex) => (
                    cat?.categories.map((tagss, tIndex) => (
                        course?.categories.map((tgss, tgIndex) => {
                            if ((tgss.term_id == tagss.id) && ctss.term_id == cat.id) { Subjects.push({ tag: cat.id, tag_name: cat.name, cat_name: tagss.name, category: tagss.id, course: course }) }
                        }
                        )
                    )
                    )
                )
                )
            )
            )
        )
        )
    }

    // console.log(Subjects, 'Subjects')

    function checkSubjectsExist(CatID, TagID) {
        const ids = [];
        Subjects.map((goal, index) => { if (goal.category == CatID && goal.tag == TagID) { ids.push(TagID) } })

        if (ids.length > 0) {
            return true;
        } else {
            return false;
        }

    }

    let [activeAbout, setActiveAbout] = useState('');
    let [activeWe , setActiveWe] = useState('');

    useEffect(() => {
        let status = window.location.href;
        if (status.includes('about')) {
            setActiveAbout('-primary');
            setActiveWe('-outline-dark');
        } else {
            setActiveWe('-primary');   
            setActiveAbout('-outline-dark');
        }
    }, []);

    return (
        <>
            <header className="navbar-light navbar-sticky header-static" style={{
                height: "11vh", borderBottom: "1px solid rgba(0,0,0,.12)"

            }} >
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                ></meta>
                <nav
                    className="navbar navbar-expand-xl" onMouseEnter={() => { setShowHover(false); setSearchTerm('') }} onClick={() => { setShowHover(false); setSearchTerm('') }} >
                    <div className="container-fluid px-3 px-xl-5" style={{
                        height: '11vh',
                    }} >
                        <Link className="navbar-brand py-2" to="/">
                            <img
                                className="light-mode-item navbar-brand-item"
                                src="/assets/images/logo.webp"
                                alt="logo"
                                style={{ width: "140", height: "40",objectFit:'cover' }} // Adjust the width and height to your desired size
                            />
                        </Link>


                        <div>
                            <Link to="/about-us" className={`btn btn${activeAbout} h-50 mx-1`}>
                                <span>About</span>
                            </Link>

                            <Link to="/we-offer" className={`btn btn${activeWe} h-50 mx-1`}>
                                What we offer
                            </Link>
                        </div>
                    </div>
                </nav>
                {
                    showHover ? <Hover onClose={() => setShowHover(false)} /> : <></>
                }
                {/* <Aler */}
            </header>
        </>
    );
}
