import React, { useReducer, useState } from 'react';
import { Button, Container, FormControl, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addJob } from '../services/jobs.ts';


const initialState = {
    jobTitle: '',
    jobDescription: '',
    jobExpireDate: '',
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'jobTitle':
            return { ...state, jobTitle: action.payload };
        case 'jobDescription':
            return { ...state, jobDescription: action.payload };
        case 'jobExpireDate':
            return { ...state, jobExpireDate: action.payload };
        case 'reset':
            return initialState
        default:
            return state;
    }
}


const CareersForm = () => {

    const [states, setStates] = useReducer(reducer, initialState);
    const dispatchState = (type, payload) => {
        setStates({ type, payload });
    };

    const handleSubmit = async () => {
        // check if all fields are filled
        const { jobTitle, jobDescription, jobExpireDate } = states;
        const condition = jobTitle === '' || jobDescription === '' || jobExpireDate === '';
        if (condition) {
            alert('Please fill all fields');
            return;
        }

        addJob({
            title: jobTitle,
            description: jobDescription,
            expireDate: jobExpireDate,
        }).then((res) => {
            if (res.status === 200) {
                alert(res?.message);
                dispatchState('reset');
            } else {
                alert('Failed to add job');
            }
        }).catch((err) => {
            alert('Failed to add job');
        });
    }

    return (
        <Container>
            <h1 style={{
                textAlign: 'center',
                margin: '50px 0',
            }}>ADD JOB</h1>
            <>

                <Form.Group controlId="title.ControlSelect1">
                    <Form.Label>Job Title</Form.Label>
                    <FormControl placeholder="Job Title" className='mb-3' type="text"
                        onChange={(e) => dispatchState('jobTitle', e.target.value)}
                        value={states.jobTitle}
                    />
                </Form.Group>

                <Form.Group controlId="dec.ControlSelect1">
                    <Form.Label>Job Description</Form.Label>
                    <ReactQuill theme="snow" style={{
                        minHeight: '300px',
                        height: '500px',
                        marginBottom: '50px',
                    }}
                        value={states.jobDescription}
                        onChange={(e) => dispatchState('jobDescription', e)}
                    />
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>Job Expire Date</Form.Label>
                    <Form.Control type="date" style={{
                        marginBottom: '10px',
                    }}
                        onChange={(e) => dispatchState('jobExpireDate', e.target.value)}
                        value={states.jobExpireDate}
                    />
                </Form.Group>
                <Button variant="primary" type="submit" style={{
                    display: 'block',
                    margin: '0 auto',
                    marginBottom: '50px',
                    width: '100%',
                }} onClick={handleSubmit}>SUBMIT JOB</Button>
            </>
        </Container>
    )
}

export default CareersForm