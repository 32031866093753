import React from 'react'
import LandingPageIndex, { LPIinterface } from '../LandingPageIndex.tsx'
import { HeaderContentProps } from '../LandingPageIndex.tsx';

const DataScience = () => {

    const body: LPIinterface = {
        aboutWebinar: {
            title: "Courses Offered",
            description: "Q Academy a number of courses in this domain including",
            buttonTitle: "Request Course Information",
            pointsArray: ["Advance Certification programs in Data Science", "Advance certificate in Data Visualization with Tableau", "Advance certificate in Data Analytics", "Advance certificate in Data Analytics & Business Intelligence", "Advance Certificate in Power BI & Power Excel"],
        },
        howItHelps: {
            // Excel, Python, Machine Learning, Tableau, PowerBI
            skills: [
                {
                    iconImage: "/assets/images/icons/Data Science/Excel.png",
                    text: "Excel",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/Python.png",
                    text: "Python",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/Machine Learning.png",
                    text: "Machine Learning",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/Tableau.jpg",
                    text: "Tableau",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/PowerBI.png",
                    text: "PowerBI",
                },
            ],
        },

        registerNow: {
            buttonTitle: "Book a Free Counseling Session",
        }

    };

    const head1: HeaderContentProps = {
        buttonTitle: "Book a Free Counseling Session",
    };
    const head2: HeaderContentProps = {
        buttonTitle: "Contact Us About This Course",
    };

    return <LandingPageIndex body={body} Head1={head1} Head2={head2} />;
}

export default DataScience
