import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'

function ThanksPage() {
    const navigator = useNavigate();

    useEffect(() => {
        // Inject the GTM script
        const script = document.createElement('script');
        script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TZLWDX2N');
    `;
        document.head.appendChild(script);

        return () => {
            // Cleanup if necessary
            document.head.removeChild(script);
        };
    }, []);

    return (
        <div
            className="row p-3"
            style={{ alignItems: "center", justifyContent: "center" }}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TZLWDX2N" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`,
                }}
            />
            <div className="col-sm-12 col-xl-6 col-md-6 col-lg-6">
                {/* <div className="" style={{ color: responseColor }}>
                    {response}
                </div> */}
                <div className="align-items-center mt-0">
                    <div className="text-center">
                        <h2 className="fw-bold">Thank You!</h2>
                        {/* <p className="mb-0 h5 fw-light"> You are successfully enrolled</p> */}
                    </div>
                    <div class="image-container">
                        <img
                            src="/assets/images/element/payment_confirm.png"
                            alt=""
                            style={{ height: "250px" }}
                        />
                    </div>
                </div>
                <button
                    className="btn btn-primary mb-0 w-100"
                    type="button"
                    onClick={() => navigator("/")}
                >
                    Go to Home
                </button>
            </div>
        </div>
    )
}

export default ThanksPage
