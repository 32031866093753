import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";
import './pages.global.css';
export default function FeedbackView({ showTitle }) {
  const { isLogin } = useSelector((state) => state?.user || {});
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [isLogin]);

  // added
  const sliderRef = useRef(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    arrows: false,
    autoplaySpeed: 2000,
    slidesToShow: 2, // Show the exact number of courses in the Slider
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setSliderIndex(next),
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          speed: 500,
          autoplaySpeed: 2000, // Show 2 cards at a time on medium screens
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          speed: 500,
          autoplaySpeed: 2000, // Show 2 cards at a time on medium screens
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          speed: 500,
          autoplaySpeed: 2000, // Show 1 card at a time on small screens (mobile devices)
        },
      },
    ],
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const show = showTitle;

  const isBigScreen = useMediaQuery({ query: "(min-width: 700px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 680px)" });

  const StyleProfileBig = {
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "50%",
  };
  const setborderBig = {
    border: "1px solid rgb(171 167 167 / 50%)",
    borderRadius: "8px",
    height: "140px",
  };

  const StyleProfile = {
    width: "80px",
    height: "80px",
    objectFit: "fill",
    borderRadius: "50%",
  };
  const setborder = {
    border: "1px solid rgb(171 167 167 / 50%)",

    borderRadius: "8px",
    //height:'148px'
  };
  const [hideCard, setShowCard] = useState(false);
  const [Hide, setHide] = useState();
  const ShoeMore = () => {
    setShowCard(!hideCard);

    setHide(true);
  };

  const testimonials = [
    { name: "Keshav Bhardwaj", feedback: "We enjoyed the training sessions. The trainer is having hands-on experience in teaching and thorough knowledge to explain the concepts." },
    { name: "Karan Chauhan", feedback: "Course was very useful in development of our skills. It's is skill that is going to develop in future." },
    { name: "Diksha Aswani", feedback: "Video recording were also available.", image:'/assets/images/avatar/female.png' },
    { name: "Harsh Jangid", feedback: "The course content is very good and the instructor is too good and he teach us well." },
    { name: "Anuj Sharma", feedback: "QAcademy's content in useful and valuable." },
    { name: "Brajesh Kumar", feedback: "Q academy course is skill enhancement and future prospects. And thank you q academy." },
    { name: "Abhishek Lakhera", feedback: "Course is going to help me develop skills and it will be helpful in future." },
    { name: "Amit Jat", feedback: "Good explanation of topic and session delivering is quite interesting." },
    { name: "Rahul Sharma", feedback: "QAcademy's course is helping us to grow in future so that's why this is very beneficial for us as a student." },
    { name: "Raghvendra Singh Nirwan", feedback: "This Course is growing in future so that's why this is very useful and valuable for every student who take this course from qacademy." },
    { name: "Saurabh Bhatt", feedback: "QAcademy's Courses are good for my skill development." },
    { name: "Harsh Prajapat", feedback: "Python learning was the exciting experience at QAcademy." },
    { name: "Hemant Chandwani", feedback: "Course will help us in future as it is in demand." },
    { name: "Astha Nema", feedback: "Data Science course at QAcademy helps us to understand the latest technologies in the best way as it is in demand.", image:'/assets/images/avatar/female.png' },
    { name: "Ayush Gupta", feedback: "Q Academy courses were very useful and proved valuable to me." },
    { name: "Suhani Sharma", feedback: "Q Academy is the best place for Skill enhancement.", image:'/assets/images/avatar/female.png' },
    { name: "Akshat Kukreja", feedback: "Excellent 👍is the right word for Q Academy." },
    { name: "Astha Kathayat", feedback: "Every aspect of Q Academy's course were useful and valuable.", image:'/assets/images/avatar/female.png' },
    { name: "Samarth Choudhary", feedback: "First time getting QAcademy's services and I am impressed." },
    { name: "Yash Verma", feedback: "Q Academy has given me the opportunity to learn new technologies in a hassle-free, homely environment." },
    { name: "Vishal Jethwani", feedback: "Q Academy Team is extremely supportive to provide the best learning experience." },
    { name: "Sanskar Mishra", feedback: "I have taken the course for my skill enhancement and found it a golden opportunity at Q Academy." },
    { name: "Ankush Kumar Singh", feedback: "I found Q Academy's course helpful as it provided the latest in-demand technologies." },
    { name: "Omm Behera", feedback: "Excellent experience at Q Academy." },
    { name: "Satish Ojha", feedback: "Explanation of tough concepts in an easy way and keeping the sessions engaged throughout is the key. We had a great experience at Q Academy." },
    { name: "Shubham Bhagwat", feedback: "QAcademy is a bank of knowledge, I am glad I have my account here." },
    { name: "Harshit Kushwah", feedback: "Where knowledge is served with full dedication towards the betterment of learners is valuable at Q Academy." },
    { name: "Vishal Dangi", feedback: "Q Academy gives priority to Student satisfaction." },
    { name: "Harsh Kumar Kachhi", feedback: "Services at Q Academy have helped us stick to our learning journey." },
    { name: "Intiyaj Ansari", feedback: "From trainers to product range: Q Academy has always satisfied our learning requirements." },
    { name: "Saurabh Maurya", feedback: "I took the AWS workshop at Q Academy which has helped me choose better career options." },
    { name: "Abbi Singh Sanvanshi", feedback: "Learning at Live session of Q Academy boosts confidence and enhances interest in the topic." },
    { name: "Yashraj Patel", feedback: "LMS at Q Academy is the best feature which helped to have the after-class aid." },
    { name: "Prashant Singh", feedback: "The entire team at Q Academy is very supportive and helpful throughout." },
    { name: "Ankita Kushwaha", feedback: "Curriculum design is the attractive part of the course I have attended at Q Academy." },
    { name: "Tufail Ahamad", feedback: "Amazing experience at Q Academy." },
    { name: "Raushan Kumar", feedback: "The entire set up of Q Academy is a great way to show appreciation and support to young learners." },
    { name: "Hemant Jhala", feedback: "I am now able to choose my career options wisely after being part of Q Academy." },
    { name: "Vikram Dhakad", feedback: "Learning at Q Academy and the environment they provide has helped me to evolve my grasping skills." },
    { name: "Sudha Patidar", feedback: "Q Academy has given me wings to fly in this era of technology." },
    { name: "Manthan Singh", feedback: "I had a great learning experience at Q Academy." },
    { name: "Nandini Raikwar", feedback: "Thank you Q Academy, Trainer, and curriculum team to provide us with the best services." },
    { name: "Shruti Soni", feedback: "Q Academy is a place to find out your skill enhancement options.", image:'/assets/images/avatar/female.png' },
    { name: "Abhishek Parmar", feedback: "I am grateful to Q Academy for the courses they offered." },
    { name: "Saksham Sahu", feedback: "It was a pleasure to be a part of Q Academy's learning community." },
    { name: "Yuvraj Mandloi", feedback: "I am Grateful that I came across Q Academy for Python Full Stack Development course." },
    { name: "Chetan Carpenter", feedback: "I can now opt for better skills updation as now I know What is Python language learnt at QAcademy." },
    { name: "Navin Yadav", feedback: "Going through the AWS internship program, I am now confident to take up my career into the field of Cloud." },
    { name: "Pranjal Ajmera", feedback: "Lifetime access to LMS and the tremendous knowledge I have", image:'/assets/images/avatar/female.png' },
  ];

  return (
    <section
      style={{
        marginBottom: "-30px",
        backgroundColor: "#F6F6F6",
      }}
      className="pb-3 pt-0 pt-lg-5 custom-overflow"
    >
      <div className="container">
        {show && (
          <>
            {isTabletOrMobile && (
              <section style={{ padding: "20px" }}></section>
            )}
            <div className="row">
              <h3
                className="text-center"
                style={{ letterSpacing: "1px", color: "#0F75BC" }}
              >
                From the Q Academy Community
              </h3>
              <h6
                className="text-center"
                style={{ letterSpacing: "1px", color: "#565656" }}
              >
                Trusted by 1000+ learners
              </h6>
            </div>
          </>
        )}

        <div className="d-flex align-items-center mt-4">
          {isBigScreen && (
            <div className="col-1">
              <button
                // style={sliderPrevButtonStyles}
                onClick={goToPrevSlide}
                className="btn"
              >
                <span
                  class="carousel-control-prev-icon Carauselshadow"
                  aria-hidden="true"
                  style={{ height: "58px", width: "58px", color: "#ffffff" }}
                ></span>
              </button>
            </div>
          )}

          {isTabletOrMobile && (
            <div className="col-1">
              <button
                // style={sliderPrevButtonStyles}
                onClick={goToPrevSlide}
                className="btn"
                style={{ marginLeft: "-20px" }}
              >
                <span
                  class="carousel-control-prev-icon Carauselshadowfeedback"
                  aria-hidden="true"
                  style={{ height: "50px", width: "50px", color: "#ffffff" }}
                ></span>
              </button>
            </div>
          )}
          {isTabletOrMobile && (
            <div
              className="col-10"
              style={
                {
                  //  background: 'red'
                }
              }
            >
              <Slider {...settings} ref={sliderRef}>
                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded border-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block " style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div className="">
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            I would Like to thank QAcademy & the trainer for
                            designing and delivering the knowledge of Pythn Full
                            stack Development course in a very excellent way.
                            The entire Set up of QAcademy is a great way to show
                            appreciation and support to young learners
                            {Hide && (
                              <a onClick={ShoeMore} style={{ color: "blue" }}>
                                ...read more
                              </a>
                            )}

                            {hideCard && (
                              <>
                                If you're looking to secure a bright future, I
                                wholeheartedly recommend Q Academy. My best
                                wishes are with them!
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/Nisha Kumari.jpeg"
                          alt=""
                        />
                      </div>
                      <div className="card-body  ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Nisha Kumari
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                              color: "#565656",
                            }}
                          >
                            India
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded border-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                              display: '-webkit-box',
                              WebkitLineClamp: 4, // Number of lines to show
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                            }}
                          >
                            Hi,I am Shweta Bharti. I am from Bihar. I purchased
                            the AWS-certified cloud practitioner course from QAcademy.
                            In this course my experience was excellent, all the instructors
                            were very supportive. And they taught me in both way theory and
                            practical about cloud computing. So, I would like to say thank you
                            to all team members.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          // style={{
                          //   width: '80px',
                          //   height: '80px',
                          //   objectFit: 'cover',
                          //   borderRadius: '50%',
                          //   //backgroundColor: 'transparent',
                          // }}
                          style={StyleProfile}
                          src="/assets/images/avatar/Shweta bharti_ b2c.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Shweta Bharti
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              padding: "5px",
                              color: "#565656",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center'
                        }}>Q Academy is amazing The explaination is very good and faculty was very friendly. our faculty vignesh sir taught us very well I enrolled in data science program which helps me to develop my skills.</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded border-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body" style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            The Q-Ranger staff is very accommodating and are very very
                            quick to respond in case of any queries or concerns. My child's
                            coding instructor is very knowledgeable and teaches concepts in a
                            way that can be easily understood by the child with no prior coding
                            experience. Highly recommend their program
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/Aiza Shakir _ b2c.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}

                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Aiza Shakir
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center'
                        }}>
                          It was nice journey with Q Academy and the teaching skills of facility was too good and He explains it several times until students understand.</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            Python Full Stack Development course is growing in future so
                            that's why this is very useful and valuable for every student
                            who take this course from Qacademy
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/dummyimage.jpeg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          //  style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}
                          style={{
                            letterSpacing: "1px",
                            lineHeight: "1.0",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Rahul sharma
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center'
                        }}>The expert instruction, well-structured curriculum, and engaging learning materials provided a comprehensive and enjoyable learning experience. Prasunamba mam made the course enjoyable.</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            The course content is very good and the instructor
                            is too good and he teach us well.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/HarshJangid_StWilfred.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Harsh jangid
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center'
                        }}>Thanks to Q Academy for providing all the support in completing the technological courses that were required by me. The faculty and the process of teaching is truly engaging. This is very helpful and the faculty is very supportive and makes the task alot more easier.</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            Great session delivery and preparation for skill enhancement
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/HarshPrajapati_StWilfred.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Harsh Prajapati
                        </h5>
                        <div className="text-center mt-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center'
                        }}>
                          The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                          Thanks QA team</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            QAcademy's in-demand courses will help us in building our the future bright
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/Hemantchandwani_Stwilfrd.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Hemant Chandwani
                        </h5>
                        <div className="text-center mt-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                        <p style={{
                          letterSpacing: '1px',
                          textAlign: 'center'
                        }}>
                          The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                          Thanks QA team</p>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborder}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "smaller",
                              color: " #000000",
                            }}
                          >
                            Professional training in the Cyber Security course at  QAcademy is excellent and
                            knowledge-giving, the trainers are excellent with profound teaching
                            skills as well as corporate experience.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfile}
                          src="/assets/images/avatar/AjayKumar_b2c.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Ajay Kumar
                        </h5>
                        <div className="text-center mt-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  testimonials.map((testimonial, index) => {
                    return (

                      <div className="col-lg-4 col-md-6">
                        <div
                          className="card rounded "
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div className="d-block" style={{ padding: "0px 12px" }}>
                            <div className="card-body " style={setborder}>
                              <div>
                                <p
                                  style={{
                                    letterSpacing: "1px",
                                    textAlign: "center",
                                    fontSize: "smaller",
                                    color: " #000000",
                                  }}
                                >
                                  {testimonial.feedback}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                              <img
                                style={StyleProfile}
                                src={testimonial.image || "/assets/images/avatar/dummyimage.jpeg"}
                                alt=""
                              />
                            </div>
                            <div className="card-body ">
                              <h5
                                className="card-title text-center"
                                style={{
                                  letterSpacing: "1px",
                                  fontWeight: "medium",
                                  fontSize: "18px",
                                  padding: "5px",
                                  color: "#565656",
                                }}
                              >
                                {testimonial.name}
                              </h5>
                              <div className="text-center mt-2">
                                <p
                                  className="card-text"
                                  style={{
                                    letterSpacing: "1px",
                                    display: "inline",
                                    marginRight: "5px",
                                  }}
                                >
                                  India
                                </p>
                                <hr
                                  style={{
                                    margin: "0",
                                    borderWidth: "0 0 1px",
                                    borderColor: "#000",
                                    width: "50%",
                                    fontWeight: "bold",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }


              </Slider>
            </div>
          )}

          {isBigScreen && (
            <div
              className="col-10"
              style={
                {
                  //  background: 'red'
                }
              }
            >
              <Slider {...settings} ref={sliderRef}>
                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded border-0"
                    style={{ backgroundColor: "transparent" }}>
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborderBig}>
                        <div className="">
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            I would Like to thank QAcademy & the trainer for
                            designing and delivering the knowledge of Pythn Full
                            stack Development course in a very excellent way.
                            The entire Set up of QAcademy is a great way to show
                            appreciation and support to young learners                                                                                                                      Thanks lot

                            {Hide && (
                              <a onClick={ShoeMore} style={{ color: "blue" }}>
                                ...read more
                              </a>
                            )}
                            {hideCard && (
                              <>
                                If you're looking to secure a bright future, I
                                wholeheartedly recommend Q Academy. My best
                                wishes are with them!
                              </>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfileBig}
                          src="/assets/images/avatar/Nisha Kumari.jpeg"
                          alt=""
                        />
                      </div>
                      <div className="card-body  ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Nisha Kumari
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                              color: "#565656",
                            }}
                          >
                            India
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded border-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborderBig}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            Hi,I am Shweta Bharti. I am from Bihar. I purchased
                            the AWS-certified cloud practitioner course from QAcademy.
                            In this course my experience was excellent, all the instructors
                            were very supportive. And they taught me in both way theory and
                            practical about cloud computing. So, I would like to say thank you
                            to all team members.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          // style={{
                          //   width: '80px',
                          //   height: '80px',
                          //   objectFit: 'cover',
                          //   borderRadius: '50%',
                          //   //backgroundColor: 'transparent',
                          // }}
                          style={StyleProfileBig}
                          src="/assets/images/avatar/Shweta bharti_ b2c.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Shweta Bharti
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              padding: "5px",
                              color: "#565656",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>Q Academy is amazing The explaination is very good and faculty was very friendly. our faculty vignesh sir taught us very well I enrolled in data science program which helps me to develop my skills.</p>
                           </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded border-0"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body" style={setborderBig}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            The Q-Ranger staff is very accommodating and are very very
                            quick to respond in case of any queries or concerns. My child's
                            coding instructor is very knowledgeable and teaches concepts in a
                            way that can be easily understood by the child with no prior coding
                            experience. Highly recommend their program
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfileBig}
                          src="/assets/images/avatar/Aiza Shakir _ b2c.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}

                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Aiza Shakir
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>
                               It was nice journey with Q Academy and the teaching skills of facility was too good and He explains it several times until students understand.</p>
                           </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborderBig}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            Python Full Stack Development course is growing in future so
                            that's why this is very useful and valuable for every student
                            who take this course from Qacademy
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfileBig}
                          src="/assets/images/avatar/dummyimage.jpeg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          //  style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px" }}
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Rahul sharma
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>The expert instruction, well-structured curriculum, and engaging learning materials provided a comprehensive and enjoyable learning experience. Prasunamba mam made the course enjoyable.</p>
                           </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborderBig}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            The course content is very good and the instructor
                            is too good and he teach us well.
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfileBig}
                          src="/assets/images/avatar/HarshJangid_StWilfred.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px",color: '#565656' }}

                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Harsh jangid
                        </h5>
                        <div className="text-center mt-2 mb-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>Thanks to Q Academy for providing all the support in completing the technological courses that were required by me. The faculty and the process of teaching is truly engaging. This is very helpful and the faculty is very supportive and makes the task alot more easier.</p>
                           </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborderBig}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            Great session delivery and preparation for skill enhancement

                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfileBig}
                          src="/assets/images/avatar/HarshPrajapati_StWilfred.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px",color: '#565656' }}
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Harsh Prajapati
                        </h5>
                        <div className="text-center mt-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>
                               The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                               Thanks QA team</p>
                           </div> */}
                      </div>
                    </div>
                  </div>
                </div>




                <div className="col-lg-4 col-md-6">
                  <div
                    className="card rounded "
                    style={{ backgroundColor: "transparent" }}
                  >
                    <div className="d-block" style={{ padding: "0px 12px" }}>
                      <div className="card-body " style={setborderBig}>
                        <div>
                          <p
                            style={{
                              letterSpacing: "1px",
                              textAlign: "center",
                              fontSize: "small",
                              color: " #000000",
                            }}
                          >
                            QAcademy's in-demand courses will help us in building our the future bright
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                        <img
                          style={StyleProfileBig}
                          src="/assets/images/avatar/Hemantchandwani_Stwilfrd.jpg"
                          alt=""
                        />
                      </div>
                      <div className="card-body ">
                        <h5
                          className="card-title text-center"
                          // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px",color: '#565656' }}
                          style={{
                            letterSpacing: "1px",
                            fontWeight: "medium",
                            fontSize: "18px",
                            padding: "5px",
                            color: "#565656",
                          }}
                        >
                          Hemant Chandwani
                        </h5>
                        <div className="text-center mt-2">
                          <p
                            className="card-text"
                            style={{
                              letterSpacing: "1px",
                              display: "inline",
                              marginRight: "5px",
                            }}
                          >
                            India
                          </p>
                          <hr
                            style={{
                              margin: "0",
                              borderWidth: "0 0 1px",
                              borderColor: "#000",
                              width: "50%",
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                        {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>
                               The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                               Thanks QA team</p>
                           </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {
                  testimonials.map((testimonial, index) => {
                    return (
                      <div className="col-lg-4 col-md-6">
                        <div
                          className="card rounded "
                          style={{ backgroundColor: "transparent" }}
                        >
                          <div className="d-block" style={{ padding: "0px 12px" }}>
                            <div className="card-body " style={setborderBig}>
                              <div>
                                <p
                                  style={{
                                    letterSpacing: "1px",
                                    textAlign: "center",
                                    fontSize: "small",
                                    color: " #000000",
                                  }}
                                >
                                  {testimonial.feedback}
                                </p>
                              </div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center pt-3 mt-4">
                              <img
                                style={StyleProfileBig}
                                src={testimonial.image || "/assets/images/avatar/dummyimage.jpeg"}
                                alt=""
                              />
                            </div>
                            <div className="card-body ">
                              <h5
                                className="card-title text-center"
                                // style={{ letterSpacing: '1px', fontWeight: 'bold', fontSize: '25px', padding: "5px",color: '#565656' }}
                                style={{
                                  letterSpacing: "1px",
                                  fontWeight: "medium",
                                  fontSize: "18px",
                                  padding: "5px",
                                  color: "#565656",
                                }}
                              >
                                {testimonial.name}
                              </h5>
                              <div className="text-center mt-2">
                                <p
                                  className="card-text"
                                  style={{
                                    letterSpacing: "1px",
                                    display: "inline",
                                    marginRight: "5px",
                                  }}
                                >
                                  India
                                </p>
                                <hr
                                  style={{
                                    margin: "0",
                                    borderWidth: "0 0 1px",
                                    borderColor: "#000",
                                    width: "50%",
                                    fontWeight: "bold",
                                  }}
                                />
                              </div>
                              {/* <div>
                             <p style={{
                               letterSpacing: '1px',
                               textAlign: 'center'
                             }}>
                               The courses provided by Q Academy are as per the industry requirement and the trainers are all from the industry letting the learning experience overwhelming. I am sure that my future is in the right hands.
                               Thanks QA team</p>
                           </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }

              </Slider>
            </div>
          )}

          {isBigScreen && (
            <div className="col-1">
              <button
                // style={sliderPrevButtonStyles}
                onClick={goToNextSlide}
                className="btn "
                style={{ marginLeft: "25px" }}
              >
                <span
                  class="carousel-control-next-icon Carauselshadow"
                  aria-hidden="true"
                  style={{ height: "58px", width: "58px", color: "#ffffff" }}
                ></span>
              </button>
            </div>
          )}
          {isTabletOrMobile && (
            <div className="col-1">
              <button
                // style={sliderPrevButtonStyles}
                onClick={goToNextSlide}
                className="btn "
                style={{ marginLeft: "-35px" }}
              >
                <span
                  class="carousel-control-next-icon Carauselshadowfeedback"
                  aria-hidden="true"
                  style={{ height: "50px", width: "50px", color: "#ffffff" }}
                ></span>
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
