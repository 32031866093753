import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import './PartnerView.css'
import { useMediaQuery } from 'react-responsive';
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import leftArrow from '../images/icons/Arrow_l.svg'
import rightArrow from '../images/icons/Arrow_r.svg'


export default function PartnerView({ dens }) {

  const country = localStorage.getItem('country') || '';

  const imageSize = {
    width: '135px', height: '120px', margin: 20,
    // mixBlendMode: 'color-burn',
    objectFit: 'contain',
  }

  const { isLogin } = useSelector((state) => state?.user || {});
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isLogin])


  const isBigScreen = useMediaQuery({ query: '(min-width: 1030px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1015px)' })

  const partnerDataArray = [
    {
      id: 1,
      image: '/assets/images/newPartnerlogo/image1.png',
      show: country === 'IN',
    },
    {
      id: 2,
      image: '/assets/images/newPartnerlogo/image2.jpeg',
      show: country === 'IN',
    },
    {
      id: 3,
      image: '/assets/images/newPartnerlogo/image3.jpeg',
      show: country === 'IN',
    },
    {
      id: 4,
      image: '/assets/images/newPartnerlogo/image4.png',
      show: country === 'IN',
    },
    {
      id: 5,
      image: '/assets/images/newPartnerlogo/image5.png',
      show: country === 'IN',
    },
    {
      id: 6,
      image: '/assets/images/newPartnerlogo/image6.png',
      show: country === 'IN',
    },
    {
      id: 7,
      image: '/assets/images/newPartnerlogo/image7.png',
      show: country === 'IN',
    },
    {
      id: 8,
      image: '/assets/images/newPartnerlogo/image8.jpg',
      show: country === 'IN',
    },
    {
      id: 9,
      image: '/assets/images/newPartnerlogo/image9.png',
      show: country === 'IN',
    },
    {
      id: 10,
      image: '/assets/images/newPartnerlogo/image10.png',
      show: true, // this
    },
    {
      id: 11,
      image: '/assets/images/newPartnerlogo/image11.png',
      show: true, // this
    },
    {
      id: 12,
      image: '/assets/images/newPartnerlogo/image12.png',
      show: true, // this
    },
    {
      id: 13,
      image: '/assets/images/newPartnerlogo/image13.png',
      show: country === 'IN',
    },
    {
      id: 14,
      image: '/assets/images/newPartnerlogo/image14.jpeg',
      show: country === 'IN',
    },
    {
      id: 15,
      image: '/assets/images/newPartnerlogo/image17.jpeg',
      show: country === 'IN',
    },
    {
      id: 16,
      image: '/assets/images/newPartnerlogo/image19.png',
      show: country === 'IN',
    },
    {
      id: 17,
      image: '/assets/images/newPartnerlogo/image22.png',
      show: country === 'IN',
    },
    {
      id: 18,
      image: '/assets/images/newPartnerlogo/image24.jpeg',
      show: country === 'IN',
    },
    {
      id: 19,
      image: '/assets/images/newPartnerlogo/image25.png',
      show: country === 'IN',
    },
    {
      id: 20,
      image: '/assets/images/newPartnerlogo/image29.png',
      show: country === 'IN',
    },
    {
      id: 21,
      image: '/assets/images/newPartnerlogo/HCL.jpeg',
      show: true,
    },
  ]

  const showingPartnerAccording = [
    {
      id: 1,
      initial: 0,
      final: 5
    },
    {
      id: 2,
      initial: 5,
      final: 10
    },
    {
      id: 3,
      initial: 10,
      final: 15
    },
    {
      id: 4,
      initial: 15,
      final: 20
    },
    {
      id: 5,
      initial: 20,
      final: 25
    },
  ]
  
  const partnerData = partnerDataArray.filter((partner) => partner.show)
  const showingPartnerAccordingToArray = partnerData.length > 5 ? showingPartnerAccording : showingPartnerAccording.slice(0, 1)


  const desktopAndTablet = () => {
    return showingPartnerAccordingToArray.map((partner) => (
      <Carousel.Item key={partner.id} className='text-center'>
        {partnerData.slice(partner.initial, partner.final).map((item) => (
          <img src={item.image} alt={`Partner ${item.id}`}
            key={item.id}
            style={imageSize} />
        ))}
      </Carousel.Item>
    ))
  };

  const arrowStyle = {
    textShadow: '0px 0px 20px #000000ff',
    width: 80,
    height: 80,
  }

  const corourselRef = useRef(null);
  const nextArrow = () => {
    return (
      <a href='javascript:void(0);' className='next-arrow' onClick={() => corourselRef.current.next()}>
        <img src={rightArrow} alt='arrow-right' style={arrowStyle} />
      </a>
    )
  }
  const prevArrow = () => {
    return (
      <a href='javascript:void(0);' className='prev-arrow' onClick={() => corourselRef.current.prev()}>
        <img src={leftArrow} alt='arrow-left' style={arrowStyle} />
      </a>
    )
  }

  // return ()

  return (
    <div style={{ backgroundColor: "#F6F6F6", height: 350, paddingTop: 100, marginTop: !dens ? 0 : -37 }} >
      <Container style={{
        marginTop: !dens ? 20 : 0,
      }} >
        <div className="partner-title mb-2">
          <h3 className="text-center" style={{ color: '#0F75BC' }}>{!dens ? 'Our Collaborations and Associations' : 'Some of the leading Companies Universities with which we collaborated'}</h3>
        </div>
        <Row className="justify-content-center align-items-center">
          <Col>{isBigScreen && prevArrow()}</Col>
          <Col lg={10} >
            <Carousel
              ref={corourselRef}
              touch={true}
              defaultActiveIndex={0}
              controls={isTabletOrMobile}
              nextIcon={nextArrow()}
              prevIcon={prevArrow()}
              indicators={false}
              interval={1500}
              slide={ isBigScreen } 
            >
              {
                isBigScreen ? desktopAndTablet() :
                  partnerData.map((item) => (
                    <Carousel.Item key={item.id} className='text-center'>
                      <img src={item.image} alt={`Partner ${item.id}`} key={item.id} style={imageSize} />
                    </Carousel.Item>
                  ))
              }
            </Carousel>
          </Col>
          <Col>{isBigScreen && nextArrow()}</Col>
        </Row>
      </Container>
    </div>
  )
}