import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../services/Services';
import { Link } from 'react-router-dom';
import '../pages.global.css'

function makeSmallAndReplace(str) {
    // Convert the string to lowercase and replace spaces with hyphens
    const modifiedStr = str.toLowerCase().replace(/\s+/g, '-');
    return modifiedStr;
}

const Hover = (Props) => {
    const dispatch = useDispatch();
    const { category, tags, courses } = useSelector((state) => state?.course);
    const [hover, setHover] = React.useState(false)
    const [selected, setSelected] = React.useState('')
    const [selectedTag, setSelectedTag] = React.useState('')
    const [goalsOrSubjects, setGoalsOrSubjects] = React.useState('')

    const Goals = []

    if (category) {
        category?.map((cat, index) => (
            cat?.courses.map((course, cIndex) => (
                course?.categories.map((ctss, tIndex) => (
                    cat?.tags.map((tagss, tIndex) => (
                        course?.tags.map((tgss, tgIndex) => {
                            if ((tgss.term_id == tagss.id) && ctss.term_id == cat.id) { Goals.push({ category: cat.id, cat_name: cat.name, tag_name: tagss.name, tag: tagss.id, course: course }) }
                        }
                        )
                    )
                    )
                )
                )
            )
            )
        )
        )
    }

    const Corporate = []

    if (category) {
        category?.map((cat, index) => cat.slug === 'corporate' && Corporate.push(cat))
    }
    const Certificate = []

    if (category) {
        category?.map((cat, index) => cat.slug === 'earn-a-certificate' && Certificate.push(cat))
    }
    const Degree = []

    if (category) {
        category?.map((cat, index) => cat.slug === 'earnadegree' && Degree.push(cat))
    }
    const Diploma = []

    if (category) {
        category?.map((cat, index) => cat.slug === 'earn-a-diploma' && Diploma.push(cat)
        )
    }

    const mainCategory = selectedTag?.length > 0 && selectedTag === 'Corporate' ? Corporate : selectedTag === 'Earn a Certificate' ? Certificate : selectedTag === 'Earn a Degree' ? Degree : selectedTag === 'Earn a Diploma' ? Diploma : []

    function checkGoalsExist(CatID, TagID) {
        const ids = [];
        Goals.map((goal, index) => { if (goal.category === CatID && goal.tag === TagID) { ids.push(TagID) } })

        if (ids.length > 0) {
            return true;
        } else {
            return false;
        }
    }


    // subjects 
    const Subjects = []
    if (tags.length > 0) {
        tags.map((cat, index) => (
            cat?.courses.map((course, cIndex) => (
                course?.tags.map((ctss, tIndex) => (
                    cat?.categories.map((tagss, tIndex) => (
                        course?.categories.map((tgss, tgIndex) => {
                            if ((tgss.term_id == tagss.id) && ctss.term_id == cat.id) { Subjects.push({ tag: cat.id, tag_name: cat.name, cat_name: tagss.name, category: tagss.id, course: course }) }
                        }
                        )
                    )
                    )
                )
                )
            )
            )
        )
        )
    }

    // console.log(Subjects, 'Subjects')

    function checkSubjectsExist(CatID, TagID) {
        const ids = [];
        Subjects.map((goal, index) => { if (goal.category == CatID && goal.tag == TagID) { ids.push(TagID) } })

        if (ids.length > 0) {
            return true;
        } else {
            return false;
        }

    }

    const Art = []

    if (tags) { tags?.map((cat, index) => cat.slug === 'cyber' && Art.push(cat)) }

    const BigData = []

    if (tags) { tags?.map((cat, index) => cat.slug === 'bigdata' && BigData.push(cat)) }

    let cloudComputing = []

    if (tags) {
        tags?.map((cat, index) => cat.slug === "cloud-computing" && cloudComputing.push(cat))
    }

    const ComputerScience = []

    if (tags) { tags?.map((cat, index) => cat.slug === "computerscience" && ComputerScience.push(cat)) }

    const DataScience = []

    if (tags) { tags?.map((cat, index) => cat.slug === "datascience" && DataScience.push(cat)) }

    const InformationTechnology = []

    if (tags) { tags?.map((cat, index) => cat.slug === "informationtechnology" && InformationTechnology.push(cat)) }

    const SoftwareDevelopment = []

    if (tags) { tags?.map((cat, index) => cat.slug === "software-development" && SoftwareDevelopment.push(cat)) }

    const mainSubject = selectedTag?.length > 0 && selectedTag === 'Cyber Security' ? Art : selectedTag === 'Big Data' ? BigData : selectedTag === 'Cloud Computing' ? cloudComputing : selectedTag === 'Computer Science' ? ComputerScience : selectedTag === 'Data Science' ? DataScience : selectedTag === 'Information Technology' ? InformationTechnology : selectedTag === 'Software Development' ? SoftwareDevelopment : []

    return (
        <div className='col-12 d-flex justify-content-center '
            style={{
                backgroundColor: '#58585874',
                position: 'absolute',
                height: '100vh',
                zIndex: 99,
            }}

            onClick={Props.onClose} onMouseLeave={Props.onClose}>
            <div className='col-11 d-flex'
            // style={{ position: 'absolute',height:"90%",
            // left:"16rem",}}
            >
                <div className='px-4 col-3 overflow-hidden border border-1 border-secondary'
                    //  style={{ background: '#ffffff',     borderRadius:"5px 0px 0px 5px", }}
                    style={{ background: '#ffffff', marginLeft: '15rem', borderRadius: "5px 0px 0px 5px", height: 340, width: '325px' }}
                >
                    <ul className='list-unstyled'>

                        <li>
                            <h4 className="dropdown-header m-3" style={{ color: '#0F75BC', fontSize: '16px' }} >By Subject</h4>
                        </li>
                        {tags.length > 0 && tags.map((res, i) =>
                            <li key={i} className="dropdown px-2 d-flex align-items-center my-3"
                                style={{
                                    cursor: 'pointer',

                                    //backgroundColor: res.id === selected ? '#0dcaf0-200' : '#ffffff',
                                    borderLeft: res.id === selected ? '4px solid #0F75BC' : '4px solid #ffffff',
                                    color: res.id === selected ? '#0F75BC' : '#C4C4C4',
                                    fontSize: "12px",
                                    //  padding: res.id === selected ? '0px' : '0px',
                                    // borderRadius: '5px'
                                }}
                                onMouseEnter={() => {
                                    setHover(true);
                                    setSelected(res.id); setSelectedTag(res?.name.replace(/\//g, ' > '));
                                    setGoalsOrSubjects('subjects')
                                }}
                            >

                                <span style={{
                                    color: '#565656',
                                    fontWeight: '400',
                                    color: res.id === selected ? '#0F75BC' : '#565656',
                                    fontSize: "13px",
                                }}
                                >{res?.name.replace(/\//g, ' > ')} </span>
                                <i className="fas fa-chevron-right ms-auto"></i>
                            </li>
                        )}
                        {/* <li>
                            <a href="courses?category_name= degrees" className="align-items-center my-3 btn btn-outline-primary" style={{ borderRadius: '5px' }} >Browse all courses</a> "
                            </li>*/}
                    </ul>

                </div>
                {
                    hover &&
                    <>
                        <div className='col-4 overflow-auto border border-1 border-secondary ' style={{ background: '#ffffff', height: 340, width: '327px' }} >
                            {/* close button */}
                            {/* <div className='d-flex justify-content-end' >
                                <button className='btn btn-outline-secondary' onClick={Props.onClose} >X</button>
                            </div>
                            <h5>{selectedTag}</h5> */}

                            {goalsOrSubjects === 'goals' ? mainCategory.length > 0 && mainCategory?.map((res, i) => (

                                <></>
                            )) :
                                tags.length > 0 && mainSubject?.map((tag, i) =>
                                    <ul className="list-group-border-0 mt-5">
                                        <li className="list-group-item ">
                                            <div>
                                                <li key={i} className="list-group-item">
                                                    {tag.courses?.slice(-7).map((item, index) => (
                                                        <div key={index} className="d-flex mb-2 position-relative">
                                                            <div className="ms-1">
                                                                <Link className="stretched-link h6  mb-0" style={{ fontWeight: '100', color: '#565656', fontSize: "13px" }}
                                                                    to={`/course/${item.post_name}`} >
                                                                    {item.post_title}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    )
                                                    )}
                                                </li>

                                            </div>
                                        </li>
                                    </ul>

                                )
                            }
                            <div style={{
                                marginLeft: '2.2rem',
                            }}  >
                                <Link
                                    to={`/courses/${selected}/t/${makeSmallAndReplace(selectedTag)} `} >
                                    <button className="btn btn-primary"> Browse all courses</button>
                                </Link>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default Hover