export const MetaTagData = [
    {
        id: 1,
        title: "Home page",
        url: "/",
        description: "Best Online Courses with Certifications in India | Q Academy",
        content: "Q Academy provides the best online learning courses and specialized online training programs with certificates in India. Join us for free to embark on your learning journey today!",
        keywords: ["best online courses with certificates", "online courses with certificates", "online courses with certificates in india"],
    },
    {
        id: 2,
        title: "About Us",
        url: "/about-us",
        description: "Unveiling Our Story, Mission, and Values | Q Academy",
        content: "Learn about our journey, mission, and values. Join us on a mission to provide the best online learning experience. Discover more about our story today!",
        keywords: ["about q academy"],
    },
    {
        id: 3,
        title: "Contact Us",
        url: "/contact-us",
        description: "Get in Touch with Q Academy | Contact Information and Support",
        content: "Contact Q Academy for any inquiries, assistance, or collaboration opportunities. Our team is here to provide prompt and friendly support. Reach out to us today and let's connect on your learning journey!",
        keywords: ["contact q academy"],
    },
    {
        id: 4,
        title: "Our Instructors",
        url: "/instructors",
        description: "Meet Our Expert Instructors | Q Academy",
        content: "Explore the diverse expertise of our dedicated instructors at Q Academy. Learn from industry leaders and subject-matter experts committed to your success.",
        keywords: ["q academy instructors"],
    },
    {
        id: 5,
        title: "Terms & Conditions",
        url: "/terms-and-conditions",
        description: "Terms & Conditions | Q Academy",
        content: "Read through Q Academy's Terms & Conditions to understand the guidelines for your educational experience. Learn about our policies and expectations to ensure a seamless and enjoyable learning journey with us.",
        keywords: ["q academy terms & conditions"],
    },
    {
        id: 6,
        title: "Privacy Policy",
        url: "/privacy-policy",
        description: "Privacy Policy: Your Trust, Our Commitment to Security | Q Academy",
        content: "Explore Q Academy's Privacy Policy to understand how we prioritize and safeguard your information. Trust us to provide a secure and transparent online learning environment. Your privacy matters to us.",
        keywords: ["q academy privacy policy"],
    },
    {
        id: 7,
        title: "Cancellation/Refund",
        url: "/cancelation-refund",
        description: "Cancellation & Refund Policy | Q Academy",
        content: "Review Q Academy's Cancellation and Refund Policy for a clear understanding of the terms regarding your learning investment. We prioritize transparency to ensure a satisfactory experience for our learners.",
        keywords: ["q academy cancellation & refund policy"],
    },
    {
        id: 8,
        title: "Careers",
        url: "/careers",
        description: "Jobs & Careers at Q Academy | Empower Learners, Shape the Future",
        content: "Join us at Q Academy in our mission to provide top-tier courses for all, and embark on your journey toward a fulfilling career today. Explore Job Openings!",
        keywords: ["Job Openings at Q Academy"],
    },
    {
        id: 9,
        title: "Corporate",
        url: "/for-corporate",
        description: "Online Corporate Training Programs & Courses | Q Academy",
        content: "Q Academy's corporate online training platform, offering tailored solutions for companies to enhance employee skills. Connect with us to elevate your team through specialized online training courses options.",
        keywords: ["online corporate training courses", "online corporate training programs"],
    },
    {
        id: 10,
        title: "Individuals",
        url: "/courses/0/c/Courses-for-Individuals",
        param: { category_name: "Courses for Individuals" },
        description: "Online Courses for Individual Learning | Q Academy",
        content: "Browse through Q Academy's extensive list of courses for individuals. Take your pick from various domains such as programming, data science, and more. Start your journey of personal growth with our online courses today!",
        keywords: ["online courses for individuals", "take individual courses online"],
    },
    {
        id: 11,
        title: "Self Paced",
        url: "/courses/62/c/self-paced",
        param: { category_name: "Self Paced", category_id: 62 },
        description: "Self Paced Online Courses with Certificate | Q Academy",
        content: "Q Academy's self-paced courses designed for flexible learning. Elevate your skills at your own pace with our self-paced online courses and earn certificates for your achievements.",
        keywords: ["self paced online courses with certificate"],
    },
    {
        id: 12,
        title: "Free",
        url: "/courses/63/c/free",
        param: { category_id: 63, category_name: "Free" },
        description: "Free Online Courses and Certificates | Q Academy",
        content: "Check Q Academy's free online courses and certificates. Gain valuable skills without cost. Enrich your learning journey with Q Academy's diverse range of free courses.",
        keywords: ["free online courses with certificates", "qacademy free courses"],
    },
    {
        id: 13,
        title: "Trending",
        url: "/courses/61/c/trending",
        param: { category_id: 61, category_name: "Trending" },
        description: "Top Trending Online Courses in India | Q Academy",
        content: "Discover the top trending online courses in India at Q Academy. Stay ahead with our list of the best trending online courses. Elevate your skills and knowledge with the latest trends.",
        keywords: ["top trending online courses", "online trending courses in india"],
    },
    {
        id: 14,
        title: "Big Data",
        url: "/courses/23/t/big-data",
        param: { tag_id: 23, tag_name: "Big Data" },
        description: "Best Big Data Online Courses, Training, & Certifications | Q Academy",
        content: "Elevate your skills with the best big data online courses & training, including certifications. Learn Big Data online and stay ahead in the dynamic world of data analytics.",
        keywords: ["big data online courses", "best big data online course"],
    },
    {
        id: 15,
        title: "Cloud Computing",
        url: "/courses/56/t/cloud-computing",
        param: { tag_id: 56, tag_name: "Cloud Computing" },
        description: "Best Cloud Computing Online Courses, Classes, & Certifications | Q Academy",
        content: "Explore best cloud computing courses online with certifications at Q Academy. Enroll in the best classes for cloud computing and advance your skills today.",
        keywords: ["cloud computing online courses with certificate", "best cloud computing courses online"],
    },
    {
        id: 16,
        title: "Computer Science",
        url: "/courses/58/t/computer-science",
        param: { tag_id: 58, tag_name: "Computer Science" },
        description: "Best Online Computer Science Courses & Certifications | Q Academy",
        content: "Discover the best online computer science courses at Q Academy. Elevate your knowledge with top computer science online classes tailored for success in the tech world.",
        keywords: ["online computer science courses", "best computer science online courses"],
    },
    {
        id: 17,
        title: "Cyber Security",
        url: "/courses/60/t/cyber-security",
        param: { tag_id: 60, tag_name: "Cyber Security" },
        description: "Best Online Cyber Security Certifications Courses & Training | Q Academy",
        content: "Master cyber security with Q Academy's online courses and certifications. Join the best cyber online security training for comprehensive skills and certifications.",
        keywords: ["best online cyber security courses", "online cyber security courses with certificates"],
    },
    {
        id: 18,
        title: "Data Science",
        url: "/courses/22/t/data-science",
        param: { tag_id: 22, tag_name: "Data Science" },
        description: "Best Online Data Science Courses, Training, & Certifications | Q Academy",
        content: "Dive into the world of data science with Q Academy's online courses. Gain expertise in data science through the best online training programs available.",
        keywords: ["best online data science course", "data science online course with certificate"],
    },
    {
        id: 19,
        title: "Information Technology",
        url: "/courses/59/t/information-technology",
        param: { tag_id: 57, tag_name: "Information Technology" },
        description: "Best Online Information Technology Courses with Certifications | Q Academy",
        content: "Unlock the future of IT with Q Academy's online courses. Explore the best information technology online classes and certifications for a successful IT career.",
        keywords: ["online it courses with certificate", "information technology online courses"],
    },
    {
        id: 20,
        title: "Software Development",
        url: "/courses/57/t/software-development",
        param: { tag_id: 57, tag_name: "Software Development" },
        description: "Online Software Development Courses, Classes & Certifications | Q Academy",
        content: "Elevate your coding skills with Q Academy's online software development courses. Enroll in the best online software development classes and become a skilled developer.",
        keywords: ["online software development courses", "software development courses online"],
    }
];
