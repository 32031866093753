import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    userData: {},
    userName:'',
    isLogin: 0,
    userID: 0,
    loginLoading:0
}

export const counterSlice = createSlice({
    name: 'userReducer',
    initialState,
    reducers: {
        setRUserData: (state, action) => {
            state.userData = action.payload
        },
        setRIsLogin: (state, action) => {
            state.isLogin = action.payload
        },
        setRUserID: (state, action) => {
            state.userID = action.payload
        },
        
        setRUserName: (state, action) => {
            state.userName = action.payload
        },
        setRLoginLoading: (state, action) => {
            state.loginLoading = action.payload
        }
    }
});

// Action creators are generated for each case reducer function
export const { setRUserData,setRIsLogin,setRUserID,setRLoginLoading,setRUserName } = counterSlice.actions

export default counterSlice.reducer