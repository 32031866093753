import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { LabCreateUser, LabSubscribeUser } from '../services/LabService';

export default function StudentLabs(props) {
const StateRedux = useSelector((state) => state);
const dispatch = useDispatch();
const { planID, companyID, teamID, launchLink } = StateRedux.lab;
const { email, first_name, last_name }  = StateRedux?.user?.userData;

async function fetchLabs() {
    try {
        console.log('fetch Labs:', email, first_name, last_name)
       const response = await LabCreateUser({dispatch, email, first_name, last_name});
       if(response.MessageCode?.toString() === '1004') {
       LabSubscribeUser({dispatch, planID, email, companyID, teamID})
       } else {
              console.log('fetch Labs Error:', response)
         }
    } catch (error) {
        console.log('fetch Labs Error:', error);
    }
}
useEffect(() => {
    if(email && first_name && last_name) {
        fetchLabs()
    } else{
        console.log('fetch Labs Error:', 'Email, first_name, last_name is missing', email, first_name, last_name);
    }      
    }, [email, first_name, last_name]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <main>
             <section className="py-5">
                <div className="container">
                    <h3> Labs </h3>

                    <table className='table table-bordered'>
                        <thead>
                            <th>#</th>
                            <th> Lab Name </th>
                            <th> Status </th>
                            <th> Action</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td> 1 </td>
                                <td> Lab 1 </td>
                                <td> Active </td>
                                     <td>
                                {
                                    launchLink? <a href={launchLink} target='_blank' className='btn btn-primary btn-md' > Launch </a>: <button className='btn btn-primary btn-md' > 
                                    Pending </button> 
                                }
                                    </td> 
                            </tr>
                        </tbody>

                    </table>

                </div>
            </section>
        </main>
    )
}
