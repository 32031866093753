import React from 'react'
import LandingPageIndex, { defaultData, LPIinterface } from '../LandingPageIndex.tsx'
import { HeaderContentProps } from '../LandingPageIndex.tsx';

const ArtificialIntelligence = () => {

    const body: LPIinterface = {
        aboutWebinar: {
            title: "Courses Offered",
            description: "Q Academy a number of courses in this domain including.",
            buttonTitle: "Request Course Information",
            pointsArray: ["Certificate programme in Advance Python for AI & Data Science", "Advance Certificate in Generative AI"],
        },
        howItHelps: {
            // Excel, Python, Machine Learning, Deep Learning, Keras, TensorFlow, AI Algorithms
            skills: [
                {
                    iconImage: "/assets/images/icons/Data Science/Excel.png",
                    text: "Excel",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/Python.png",
                    text: "Python",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/Machine Learning.png",
                    text: "Machine Learning",
                },
                {
                    iconImage: "/assets/images/icons/Data Science/Deep Learning.png",
                    text: "Deep Learning",
                },
                {
                    iconImage: "/assets/images/icons/Artificial Intelligence/keras.png",
                    text: "Keras",
                },
                {
                    iconImage: "/assets/images/icons/Artificial Intelligence/tensorflow.png",
                    text: "TensorFlow",
                },
                {
                    iconImage: "/assets/images/icons/Artificial Intelligence/AI.png",
                    text: "AI Algorithms",
                },
            ],

            array: [
                {
                    title: 'Future-proof career',
                    text: 'These courses will future-proof your career and help you ace the tech world with new trends',
                    image: '/assets/images/raodmap/goal.png'
                },
                {
                    title: 'Adaptability and resilience',
                    text: 'All the modules covered will help you be adaptable to the ongoing challenges in the tech industry',
                    image: '/assets/images/raodmap/adapt.png'
                },
                {
                    title: 'Continuous learning',
                    text: 'The best opportunity to get along with tech trends.',
                    image: '/assets/images/raodmap/continuous-learning.png'
                },
                {
                    title: 'Competitive advantage',
                    text: 'Learn the updated AI strategies and advance your career.',
                    image: '/assets/images/raodmap/advantage.png'
                }
            ]
        },

        registerNow: {
            buttonTitle: "Book a Free Counseling Session",
        }

    };

    const head1: HeaderContentProps = {
        buttonTitle: "Book a Free Counseling Session",
        data: { ...defaultData, mainTitle: 'Industry-Driven Artificial Intelligence Courses for Aspiring Professionals!', }
    };
    const head2: HeaderContentProps = {
        buttonTitle: "Contact Us About This Course",
    };

    return <LandingPageIndex body={body} Head1={head1} Head2={head2} />;
}

export default ArtificialIntelligence
