import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SignupUser, LoginUser, fetchCountryInfo, GetCartInfo } from '../services/Services';
import { setRIsLogin, setRUserData, setRUserID, setRUserName } from '../app/reducers/userReducer';
import { useMediaQuery } from 'react-responsive';
import './pages.global.css';
// import NewLogin from '../pages/new.login'
import { Link, useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { Col, Container } from 'react-bootstrap';

function SingupComponent(props) {

    const { userData, isLogin, userID, loginLoading } = useSelector((state) => state?.user || {});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUsername] = useState(null);
    const [email, setEmail] = useState(null);
    const [response, setResponse] = useState('');
    const [password, setPassword] = useState(null);
    const [userError, setUserError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [displayNameError, setDisplayNameError] = useState('');
    const [passError, setPassError] = useState('');
    const [loading, setLoading] = useState(false);
    const [ResponseType, setResponseType] = useState('');
    const [forgotPasswordShow, setForgotPasswordShow] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [forgotPasswordError, setForgotPasswordError] = useState('');
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
            setEmailError('Email is invalid');
        } else {
            setEmailError(null);
        }
        setEmail(event.target.value);
    };

    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      }
    async function handleForgotPassword() {
        setLoading(true);
        // https://learn.qacademy.tech/wp-json/lmscourses/v2/forgot-password?email=dhyaneshwarshukla@gmail.com
        // validate email
        if (!validateEmail(forgotPasswordEmail)) {
            setForgotPasswordError('Email is invalid');
            setLoading(false);
            return;
        }
        if (forgotPasswordEmail?.trim()) {
            const baseUrl = await fetchCountryInfo();
            const response = await fetch(baseUrl + `/wp-json/lmscourses/v2/forgot-password?email=${forgotPasswordEmail}`, {
                method: 'POST',
            });
            const data = await response.json();
            setLoading(false);
            setResponse(data.message);
            setResponseType('success')
        } else {
            setLoading(false);
            setForgotPasswordError('Please enter email address')
        }
    }
    async function Signup() {
        setLoading(true);
        if (displayName == null || displayName == '' || displayName == undefined) {
            setDisplayNameError('Please enter full name');
            setLoading(false);
            return
        } else {
            setDisplayNameError();
            setLoading(false);
        }

        if (username == null || username == '' || username == undefined) {
            setUserError('Please enter username');
            setLoading(false);
            return
        } else {
            setUserError();
            setLoading(false);
        }
        if (password == null || password == '' || password == undefined) {
            setPassError('Please enter password');
            setLoading(false);
            return
        }
        else {
            setPassError();
            setLoading(false);
        }
        if (email == null || email == '' || email == undefined) {
            setEmailError('Please enter email address');
            setLoading(false);
            return
        } else {
            setEmailError();
            setLoading(false);
        }

        if (!isValidEmail(email)) {
            setEmailError('Email is invalid');
            return
        } else {
            setEmailError();
            setLoading(false);
        }


        if (password == null || password == '' || password == undefined) {
            setPassError('Please enter Password');
            setLoading(false);
            return
        } else {
            setPassError();
            setLoading(false);
        }

        if (password.length < 8) {
            setPassError('Please enter minimum 8 character password');
            setLoading(false);
            return
        } else {
            setPassError();
            setLoading(false);
        }

        SignupUser(username, email, password, displayName, dispatch).then(response => {
            setLoading(true);
            if (response.success == false) {
                setResponse(response.message);
                setResponseType('danger');
                setLoading(false);
            } else {
                setResponse('Account Created Successfully. Please login');
                setResponseType('success');
                setLoading(false);
                setLoginSucc(true)
            }
        });
    }

    const [loginSucc, setLoginSucc] = useState(false)


    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);

    const toggleModal = () => {
        setShowModal((prevShowModal) => !prevShowModal);
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    async function handleLogin() {
        setLoading(true);
        setError(''); // Clear previous error message

        if (!username?.trim()) {
            setError('Please Enter Username');
            setLoading(false);
            return;
        }

        if (!password.trim()) {
            setError('Please enter Password');
            setLoading(false);
            return;
        }
        try {
            const response = await LoginUser(username, password, dispatch);
            if (response.success) {
                setLoading(false);
                setSuccess('Login Successful..');
                await dispatch(setRUserID(response.data.id));
                dispatch(setRUserName(response.data.username));
                dispatch(setRUserData(response.data));
                dispatch(setRIsLogin(1));
                GetCartInfo(response.data.id);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } else {
                setLoading(false);
                setError('Oops!! Invalid Username or Password'); // Display the error message received from the server
            }
        } catch (error) {
            setLoading(false);
            setError('Error occurred while logging in.'); // Display a generic error message
        }
    }

    useEffect(() => {
                setResponse('');
                setSuccess('');
                setError('');
    }, [loginSucc])

    return (
        <>
            <center> {response && !loginSucc && <div style={{ marginLeft: "10px" }} className={`alert alert-${ResponseType}`}>{response}</div>}</center>

            {
                loginSucc == true ?
                    <>
                        <div className="modal-header" style={{ border: 'none' }}>
                            <h5
                                className=""
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    fontSize: '2rem',
                                    fontWeight: '400',
                                    color: '#000',
                                    letterSpacing: '0.01rem',
                                }}
                                id="exampleModalLabel"
                            >
                                {forgotPasswordShow ? 'Forgot Password' : 'Welcome Back'}
                            </h5>
                        </div>
                        {forgotPasswordShow ?
                            <>
                                <div>
                                    <div className="form-group">
                                        {response &&<div className={`alert alert-${ResponseType}`} role="alert">
                                            {response}
                                        </div>}
                                        <label htmlFor="email" className='roboto'>Email address</label>
                                        <input
                                            type="email"
                                            onInput={(e) => setForgotPasswordEmail(e.target.value)}
                                            id="email"
                                            className="form-control left-aligned-placeholder"
                                            placeholder="Enter email"
                                            style={{ textAlign: 'left !important' }}
                                        />
                                    </div>
                                    {forgotPasswordError && <span className="fst-italic text-danger">{forgotPasswordError}</span>}
                                    <button className='btn btn-primary w-100 mt-3' onClick={handleForgotPassword}>{loading ? 'Loading...' : 'Submit'}</button>
                                </div>
                            </> : <>
                                <div className="card-body p-5">
                                    {success && <div className="alert alert-success">{success}</div>}
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputEmail1" className="form-label  roboto text-black">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            onInput={(e) => {
                                                const onlyLetters = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                                                setUsername(onlyLetters);
                                            }}
                                            onPaste={(e) => {
                                                e.preventDefault();
                                                const pastedText = e.clipboardData.getData('text/plain');
                                                const onlyLetters = pastedText.replace(/[^A-Za-z0-9]/g, '');
                                                setUsername(onlyLetters);
                                            }}
                                            // onInput={(e) => setUsername(e.target.value)}
                                            className="form-control border border left-aligned-placeholder border-secondary"
                                            placeholder="Username"
                                            id="exampleInputEmail1"
                                            aria-describedby="emailHelp"
                                            onKeyDown={(e) => {
                                                if (e.key.match(/[^A-Za-z0-9]/)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                    <div style={{ position: 'relative' }}>
                                        <label htmlFor="exampleInputPassword1" className="form-label roboto text-black fw-light fs-6">
                                            Password
                                        </label>
                                        <input
                                            type={showPassword1 ? 'text' : 'password'}
                                            onInput={(e) => setPassword(e.target.value)}
                                            className="form-control left-aligned-placeholder border border-secondary"
                                            placeholder="Enter your password"
                                            id="exampleInputPassword1"
                                        />
                                        <i
                                            style={{
                                                position: 'absolute',
                                                top: '70%',
                                                right: '1rem',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setShowPassword1(!showPassword1)}
                                            className={`fa fa-eye${showPassword1 ? '-slash' : ''}`}
                                        ></i>
                                    </div>

                                    <button type="submit" onClick={handleLogin} className="btn btn-primary w-100 mt-3">
                                        {loading ? 'Loading...' : 'Log In'}
                                    </button>
                                    <Link to="#" className='text-decoration-underline fs-6 fw-medium' onClick={() => setForgotPasswordShow(true)}>Forgot Password</Link>
                                </div>
                                <div className="container">
                                    <p className="text-black text-center">
                                        New to Q Academy? &nbsp;
                                        <Link to="#" className="text-decoration-underline fs-6 lh-1 fw-medium"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setLoginSucc(false)
                                            }}
                                        >
                                            Sign Up
                                        </Link>
                                    </p>
                                </div></>
                        }
                    </> :
                    <>
                        <div className="modal-header" style={{ border: 'none', padding: '0px 16px 0px 16px' }}>
                            <h3
                                className=""
                                style={{
                                    // textAlign: 'center',
                                    width: '100%',
                                    fontSize: '2rem',
                                    fontWeight: '400',
                                    color: '#000',
                                    letterSpacing: '0.01rem',
                                }}
                                id="exampleModalLabel"
                            >
                                Sign up
                                <p style={{ lineHeight: '18px' }} className='fs-6 text-bold text-dark mb-0 mt-2'>Learn on your own time from top universities and business</p>
                            </h3>
                        </div>

                        <div className="card-body p-3 w-sm-75">
                            <div className="mb-4">

                                <label
                                    style={{
                                        float: " left"
                                    }}
                                    htmlFor="fullName"
                                    className="form-label roboto text-black fw-light fs-6"
                                >
                                    Full Name
                                </label>
                                <input
                                    onInput={(e) => {
                                        const onlyLetters = e.target.value.replace(/[^A-Za-z]/ig, '');
                                        setDisplayName(onlyLetters);
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        const pastedText = e.clipboardData.getData('text/plain');
                                        const onlyLetters = pastedText.replace(/[^A-Za-z]/ig, '');
                                        setDisplayName(onlyLetters);
                                    }}
                                    // onInput={(e) => setDisplayName(e.target.value)}
                                    type="text"
                                    className="form-control left-aligned-placeholder border border border-secondary"
                                    placeholder="Enter your full name"
                                    id="fullName"
                                    onKeyDown={(e) => {
                                        if (e.key.match(/[0-9]/)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    aria-describedby="emailHelp"
                                />
                                {displayNameError && <span className="text-danger" style={{
                                    float: 'left',
                                    fontWeight: 10, fontSize: '12px',
                                }}>{displayNameError}</span>}
                            </div>
                            <div className="mb-4">
                                <label
                                    style={{
                                        float: " left"
                                    }}
                                    htmlFor="exampleInputEmail1"
                                    className="form-label roboto  text-black fw-light fs-6"
                                >
                                    Username
                                </label>
                                <input
                                    type="text"
                                    onKeyDown={(e) => {
                                        if (e.key.match(/[^A-Za-z0-9]/)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onInput={(e) => {
                                        const onlyLetters = e.target.value.replace(/[^A-Za-z0-9]/g, '');
                                        setUsername(onlyLetters);
                                    }}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        const pastedText = e.clipboardData.getData('text/plain');
                                        const onlyLetters = pastedText.replace(/[^A-Za-z0-9]/g, '');
                                        setUsername(onlyLetters);
                                    }}
                                    // onInput={(e) => setUsername(e.target.value)}
                                    className="form-control left-aligned-placeholder border border border-secondary"
                                    placeholder="Username"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                                {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
                                {userError && <span className="text-danger" style={{ float: 'left', fontWeight: 10, fontSize: '12px' }}>{userError}</span>}

                            </div>
                            <div className="mb-4">
                                <label
                                    style={{
                                        float: " left"
                                    }}
                                    htmlFor="exampleInputEmail1"
                                    className="form-label roboto text-black fw-light fs-6"
                                >
                                    Email
                                </label>
                                <input
                                    type="email"
                                    value={email}
                                    onInput={handleChange}
                                    className="form-control left-aligned-placeholder border border border-secondary"
                                    placeholder="Name@gmail.com"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                />
                                {emailError && <span className="text-danger" style={{ float: 'left', fontWeight: 10, fontSize: '12px' }}>{emailError}</span>}

                            </div>
                            <div className="mb-4" style={{ position: 'relative' }}>
                                <label
                                    style={{
                                        float: 'left'
                                    }}
                                    htmlFor="exampleInputPassword1"
                                    className="form-label roboto text-black fw-light fs-6"
                                >
                                    Password
                                </label>
                                <input
                                    onInput={(e) => setPassword(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    className="form-control border left-aligned-placeholder border-secondary"
                                    placeholder="Create your password"
                                    id="exampleInputPassword1"
                                />
                                <i
                                    style={{
                                        position: 'absolute', // Fixed positioning within the parent
                                        top: '70%', // Adjust as needed for the eye icon's vertical position
                                        right: '1rem', // Adjust the distance from the right side
                                        transform: 'translateY(-50%)', // Center vertically
                                        cursor: 'pointer',
                                    }}
                                    onClick={togglePasswordVisibility}
                                    className={`fa fa-eye${showPassword ? '-slash' : ''}`}
                                ></i>
                                {passError && <span className="text-danger" style={{ float: 'left', fontWeight: 10, fontSize: '12px' }}>{passError}</span>}
                            </div>
                            <div
                                id="emailHelp"
                                className=""
                                style={{
                                    color: '#000',
                                    marginTop: '-10px',
                                    fontSize: '0.7rem',
                                    fontWeight: '100',
                                    float: " left"
                                }}
                            >
                                <p className='roboto'>Between 8 to 72 characters.</p>


                            </div>
                            <button onClick={() => Signup()} type="submit" className="btn btn-primary w-100" disabled={loading}>
                                {loading ? "Signing up..." : "Join for free"}
                            </button>
                        </div>
                        <div className="container">
                            <p className="text-black text-center">
                                Already on Q Academy? &nbsp;
                                <Link
                                    to="#"
                                    className="text-decoration-underline fs-6 lh-1 fw-medium"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setLoginSucc(true)
                                    }}>
                                    Log In
                                </Link>
                            </p>
                        </div>
                    </>
            }

        </>

    )
}

export default SingupComponent

