import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import { Modal } from 'react-bootstrap';

export default function Loading() {
    return (
        <Modal show={true} style={{flex: 1, backgroundColor: '#fff'}}
        centered={true}
        contentClassName="bg-transparent border-0 justify-content-center align-items-center d-flex"
         >
                <div style={{
                    transform: 'rotate3d(10, 10, 10, 30)', zIndex: '9999',
                    // backgroundColor: '#00000099'
                }}>
                    <Oval color="#00BFFF" height={100} width={100} timeout={3000} />
                </div>
        </Modal>
    )
}
