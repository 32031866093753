import React from 'react'
import { Container } from 'react-bootstrap'
import { routes } from '../config/routes'

function SiteMap() {
    return (
        <Container>
            <strong className='text-primary fs-1 m-auto'>Site Map</strong>
            {/* styles list */}
            <ul
            className='list-unstyled fs-5'>
            {
                routes.map((route, index) => {
                    return (
                        <li key={index}>{route.name}</li>
                    )
                })
            }
                </ul>
                

        </Container>
    )
}

export default SiteMap
