import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "font-awesome/css/font-awesome.min.css"; // Import the Font Awesome CSS
import "./footer.css";
import "../../pages/about.view.css";
import "../components/styles.css";
// import userIcon from "../../images/useric.svg"
import Badge from "react-bootstrap/Badge";
import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { fetchCountryInfo, getCourseCategory, getSearchProducts } from "../../services/Services";
import SingupComponent from "../SingupComponent";
import usericon from "../../images/icons/usericon.png";
import uservector from "../../images/icons/userVector.png";
import { useMediaQuery } from "react-responsive";
import {
  setRUserData,
  setRIsLogin,
  setRUserID,
  setRUserName,
} from "../../app/reducers/userReducer";
import Hover from "./Hover";
import Hovercoursefor from "./Hovercoursefor";
import Dropdown from "react-bootstrap/Dropdown";
import { setIsSearchFieldShow, setShowLoginSignup } from "../../app/reducers/courseReducer";
export default function Header() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [hover, setHover] = React.useState(false);

  const handleClose = () => dispatch(setIsSearchFieldShow(false));
  const handleShow = () => dispatch(setIsSearchFieldShow(true));
  const [selectedCategory, setSelectedCategory] = useState();
  const [selected, setSelected] = React.useState("");
  const [goalsOrSubjects, setGoalsOrSubjects] = React.useState("");
  const [selectedTag, setSelectedTag] = React.useState("");

  const [showLogin, setShowLogin] = useState(false);

  const handleCloseL = () => dispatch(setShowLoginSignup(false));
  const handleShowLogin = () => dispatch(setShowLoginSignup(true));

  const { userData, isLogin, userID, userName } = useSelector(
    (state) => state?.user || {}
  );
  const { cart, cartItems } = useSelector((state) => state?.cart || []);
  const { category, tags, searchProducts, searchFieldShow, showLoginSignup } = useSelector(
    (state) => state?.course
  );
  const [searchTerm, setSearchTerm] = useState("");

  const [showHover, setShowHover] = useState(false);

  const [showHoverC, setShowHoverC] = useState(false);

  // const { cart } = useCartContext();
  const [loggedIn, setLoggedIn] = useState(false);
  const [items, setItems] = useState(0);

  useEffect(() => {
    if (category.length === 0) {
      setTimeout(() => {
        getCourseCategory(dispatch);
      }, 2000);
    }
  }, [showHoverC]);

  useEffect(() => {
    fetchCountryInfo();
    window.scrollTo(0, 0);
  }, []);

  const handleCategoryHover = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  function handleSearch(event) {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  }
  const toggleHover = () => {
    setShowHover(true);
    setShowHoverC(false); // Ensure showHoverC is false
  };
  const toggleHoverC = () => {
    setShowHoverC(true);
    setShowHover(false); // Ensure showHover is false
  };
  function Logout() {
    dispatch(setRIsLogin(0));
    dispatch(setRUserData({}));
    dispatch(setRUserName());
    dispatch(setRUserID(0));
    localStorage.removeItem("persist:cart");
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }
  // search filter list
  const SearchListShow = () => {
    setSearchTerm("");
    handleClose();
  };

  useEffect(() => {
    getSearchProducts(dispatch);
  }, []);

  useEffect(() => {
    setLoggedIn(isLogin);
  }, [isLogin, userData, userID]);

  const filteredCourses = searchProducts?.filter((course) => {
    return course.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Goals = [];

  if (category) {
    category?.map((cat, index) =>
      cat?.courses?.map((course, cIndex) =>
        course?.categories?.map((ctss, tIndex) =>
          cat?.tags?.map((tagss, tIndex) =>
            course?.tags?.map((tgss, tgIndex) => {
              if (tgss.term_id == tagss.id && ctss.term_id == cat.id) {
                Goals.push({
                  category: cat.id,
                  cat_name: cat.name,
                  tag_name: tagss.name,
                  tag: tagss.id,
                  course: course,
                });
              }
            })
          )
        )
      )
    );
  }

  function checkGoalsExist(CatID, TagID) {
    const ids = [];
    Goals?.map((goal, index) => {
      if (goal.category == CatID && goal.tag == TagID) {
        ids.push(TagID);
      }
    });

    if (ids.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  const Subjects = [];
  if (tags.length > 0) {
    tags?.map((cat, index) =>
      cat?.courses?.map((course, cIndex) =>
        course?.tags?.map((ctss, tIndex) =>
          cat?.categories?.map((tagss, tIndex) =>
            course?.categories?.map((tgss, tgIndex) => {
              if (tgss.term_id == tagss.id && ctss.term_id == cat.id) {
                Subjects.push({
                  tag: cat.id,
                  tag_name: cat.name,
                  cat_name: tagss.name,
                  category: tagss.id,
                  course: course,
                });
              }
            })
          )
        )
      )
    );
  }

  // console.log(Subjects, 'Subjects')

  function checkSubjectsExist(CatID, TagID) {
    const ids = [];
    Subjects?.map((goal, index) => {
      if (goal.category == CatID && goal.tag == TagID) {
        ids.push(TagID);
      }
    });

    if (ids.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const content = {
    width: "481px",
    height: "21px",
    top: "13px",
    left: "319px",
    textAlign: "right",
    color: "white",
  };
  const divider = {
    width: "1284px",
    height: "2px",
    top: "6342px",
    left: "318px",
    border: "1px",
    backgroundColor: "#C4C4C4",
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const [showSignUp, setShowSignUp] = useState(false);

  const handleClick = () => {
    //  setShowSignUp(!showSignUp);
    setShowSignUp((prevShowSignUp) => !prevShowSignUp); // Toggle the value of showSignUp
  };

  // explore code here ..

  if (category) {
    category?.map((cat, index) =>
      cat?.courses?.map((course, cIndex) =>
        course?.categories?.map((ctss, tIndex) =>
          cat?.tags?.map((tagss, tIndex) =>
            course?.tags?.map((tgss, tgIndex) => {
              if (tgss.term_id == tagss.id && ctss.term_id == cat.id) {
                Goals.push({
                  category: cat.id,
                  cat_name: cat.name,
                  tag_name: tagss.name,
                  tag: tagss.id,
                  course: course,
                });
              }
            })
          )
        )
      )
    );
  }

  const Corporate = [];

  if (category) {
    category?.map(
      (cat, index) => cat.slug === "corporate" && Corporate.push(cat)
    );
  }
  const Certificate = [];

  if (category) {
    category?.map(
      (cat, index) => cat.slug === "earn-a-certificate" && Certificate.push(cat)
    );
  }
  const Degree = [];

  if (category) {
    category?.map(
      (cat, index) => cat.slug === "earnadegree" && Degree.push(cat)
    );
  }
  const Diploma = [];

  if (category) {
    category?.map(
      (cat, index) => cat.slug === "earn-a-diploma" && Diploma.push(cat)
    );
  }

  const mainCategory =
    selectedTag?.length > 0 && selectedTag === "Corporate"
      ? Corporate
      : selectedTag === "Earn a Certificate"
        ? Certificate
        : selectedTag === "Earn a Degree"
          ? Degree
          : selectedTag === "Earn a Diploma"
            ? Diploma
            : [];

  function checkGoalsExist(CatID, TagID) {
    const ids = [];
    Goals?.map((goal, index) => {
      if (goal.category === CatID && goal.tag === TagID) {
        ids.push(TagID);
      }
    });

    if (ids.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  // subjects

  if (tags.length > 0) {
    tags?.map((cat, index) =>
      cat?.courses?.map((course, cIndex) =>
        course?.tags?.map((ctss, tIndex) =>
          cat?.categories?.map((tagss, tIndex) =>
            course?.categories?.map((tgss, tgIndex) => {
              if (tgss.term_id == tagss.id && ctss.term_id == cat.id) {
                Subjects.push({
                  tag: cat.id,
                  tag_name: cat.name,
                  cat_name: tagss.name,
                  category: tagss.id,
                  course: course,
                });
              }
            })
          )
        )
      )
    );
  }

  // console.log(Subjects, 'Subjects')

  function checkSubjectsExist(CatID, TagID) {
    const ids = [];
    Subjects?.map((goal, index) => {
      if (goal.category == CatID && goal.tag == TagID) {
        ids.push(TagID);
      }
    });

    if (ids.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const Art = [];

  if (tags) {
    tags?.map((cat, index) => cat.slug === "cyber" && Art.push(cat));
  }

  const BigData = [];

  if (tags) {
    tags?.map((cat, index) => cat.slug === "bigdata" && BigData.push(cat));
  }

  let cloudComputing = [];

  if (tags) {
    tags?.map(
      (cat, index) => cat.slug === "cloud-computing" && cloudComputing.push(cat)
    );
  }

  const ComputerScience = [];

  if (tags) {
    tags?.map(
      (cat, index) =>
        cat.slug === "computerscience" && ComputerScience.push(cat)
    );
  }

  const DataScience = [];

  if (tags) {
    tags?.map(
      (cat, index) => cat.slug === "datascience" && DataScience.push(cat)
    );
  }

  const InformationTechnology = [];

  if (tags) {
    tags?.map(
      (cat, index) =>
        cat.slug === "informationtechnology" && InformationTechnology.push(cat)
    );
  }

  const SoftwareDevelopment = [];

  if (tags) {
    tags?.map(
      (cat, index) =>
        cat.slug === "software-development" && SoftwareDevelopment.push(cat)
    );
  }

  const mainSubject =
    selectedTag?.length > 0 && selectedTag === "Cyber Security"
      ? Art
      : selectedTag === "Big Data"
        ? BigData
        : selectedTag === "Cloud Computing"
          ? cloudComputing
          : selectedTag === "Computer Science"
            ? ComputerScience
            : selectedTag === "Data Science"
              ? DataScience
              : selectedTag === "Information Technology"
                ? InformationTechnology
                : selectedTag === "Software Development"
                  ? SoftwareDevelopment
                  : [];

  const userDataString = JSON.parse(localStorage.getItem("persist:user"));

  const CountryCodeGet = localStorage.getItem("country");

  let countryCode = CountryCodeGet;

  function redirectLms() {
    let r = (Math.random() + 1).toString(36).substring(7);
    // console.log("random", r);
    const CountryCodeGet = localStorage.getItem("country");
    let countryCode = CountryCodeGet;
    if (CountryCodeGet == "IN") {
      window.location.href =
        "https://learnqacdstg.wpenginepowered.com/wp-json/user/v2/bypasslogin?r" +
        r +
        r +
        r +
        r +
        r +
        r +
        r +
        "&user_id=" +
        userID +
        "&p=" +
        r +
        r +
        r +
        r +
        r +
        r +
        r;
    } else {
      window.location.href =
        "https://learn.qacademy.tech/wp-json/user/v2/bypasslogin?r" +
        r +
        r +
        r +
        r +
        r +
        r +
        r +
        "&user_id=" +
        userID +
        "&p=" +
        r +
        r +
        r +
        r +
        r +
        r +
        r;
    }
  }

  const topHeader = {
    fontFamily: "Roboto",
    fontSize: "11px",
    fontWeight: "200",
    lineHeight: "3px",
    letterSpacing: " 0em",
    textAlign: "left",
  };

  let subMenu = [
    {
      title: "Courses For",
      data: category,
      type: "courses",
    },

    {
      title: "Explore Courses",
      data: tags,
      type: "subject",
    },
  ];

  // const isBigScreen = useMediaQuery({ query: '(min-width: 700px)' })
  // const isTabletOrMobile = useMediaQuery({ query: '(max-width: 680px)' })

  const isBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isTablet = useMediaQuery({
    query: "(max-width: 1199px) and (min-width: 695px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 694px)" });

  return (
    <>
      <Modal
        size="xl"
        show={searchFieldShow}
        onHide={handleClose}
        dialogClassName="full-width-modal"
        style={{
          marginTop: "-2rem",
        }}
      >
        <center>
          {
            <Modal.Body
              closeButton
              className=""
              style={{
                alignItems: "center",
                border: "none",
                width: isMobile ? '90%' : "50%",
                height: isMobile ? '100px' : "127px",
              }}
            >
              <div className="row mt-3">
                {" "}
                <div className="col-11">
                  <input
                    type="input"
                    value={searchTerm}
                    placeholder="Explore professional courses"
                    onChange={handleSearch}
                    className="searchInput"
                  />
                  {searchTerm != "" ? (
                    <div
                      style={{
                        border: "1px solid",
                        borderColor: "#eff1f2",
                        borderRadius: "10px",
                        position: "absolute",
                        backgroundColor: "rgb(255, 255, 255)",
                        width: "87%",
                        height: "400px",
                      }}
                    // onMouseLeave={() => setSearchTerm("")}
                    >
                      {filteredCourses?.map((course, index) =>
                        index <= 5 ? (
                          <Link
                            to={`/course/${course.slug}`}
                            className="w-100"
                            onClick={SearchListShow}
                            state={{ id: course.id }}
                          >
                            <p
                              className="onHoverTextColorChange w-100"
                              style={{
                                padding: "8px",
                                paddingBottom: "1px",
                                color: "#000",
                                textAlign: "start",
                              }}
                            >
                              {course?.name
                                ? course?.name
                                : "search result..."}
                            </p>
                          </Link>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div
                  className="col-1"
                  style={{
                    position: "absolute",
                    right: "35px",
                    backgroundColor: "transparent",
                  }}
                >
                  <button
                    type="button"
                    className="close"
                    id="closeSearch"
                    aria-label="Close"
                    onClick={handleClose}
                    style={{
                      border: "1px solid grey",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30px",
                      height: "30px",
                      marginLeft: isMobile ? -15 : "-48px",
                    }}
                  >
                    <span
                      aria-hidden="true"
                      style={{
                        fontSize: "20px",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                    >
                      &times;
                    </span>
                  </button>
                </div>{" "}
              </div>
            </Modal.Body>
          }
        </center>
      </Modal>
      {/* <TopHeader /> */}
      {isBigScreen && (
        <header
          className="navbar-light navbar-sticky header-static"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.12)",
          }}
        >
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <div>
            <nav
              className="navbar navbar-expand-lg navbar-dark"
              style={{ backgroundColor: "#05295D", height: "40px" }}
            >
              <div className="container">
                <span className="navbar-brand fs-6">
                  {" "}
                  Have any question? {" "}
                  <Link className="text-white" to="mailto:info@qacademy.tech">
                    info@qacademy.tech
                  </Link>{" "}
                  | <Link className={`text-white ${CountryCodeGet !== 'IN' && 'd-none'}`} to="tel: +919998458459">
                    +91-9998458459 |{" "}
                  </Link>
                  {countryCode}
                </span>
                <div id="google_translate_element"></div>
                <div className="" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                      <button
                        type="dropdown"
                        onClick={handleShow}
                        className="dropdown-toggle btn btn mb-0 z-index-1"
                        style={{
                          color: "white", //made changes here
                        }}
                      >
                        <i
                          className="fas fa-search"
                          style={{
                            color: "white",
                          }}
                        />
                      </button>
                    </li>
                    <li className="nav-item" id="navbarCollapse">
                      <Link
                        className="nav-link d-flex justify-content-center algin-items-center"
                        style={{
                          marginTop: "11px",
                        }}
                        to="/cart"
                      >
                        {/* <img className="text-light" src={shoppingCart} style={{ height: '19px', color: "red", }} /> */}
                        <i
                          class="fa fa-cart-shopping"
                          style={{
                            color: "#d3d5db",
                            textAlign: "center",
                            fontSize: "22px",
                          }}
                        ></i>
                        <Badge
                          className="border border-white ms-1"
                          style={{
                            fontSize: "10px",
                            alignSelf: "center",
                          }}
                          pill
                        >
                          {loggedIn ? cartItems.length : 0}
                        </Badge>
                      </Link>
                    </li>

                    {loggedIn ? (
                      //TODO:need to change
                      <>
                        <div
                          className="dropdown d-flex justify-content-center align-items-center ms-1 ms-lg-0"
                          style={{
                            width: "50px",
                            height: "40px",
                          }}
                        >
                          <Link
                            className="avatar avatar-sm p-0 d-flex justify-content-center align-items-center"
                            style={{
                              marginTop: "1px",
                            }}
                            to="#"
                            id="profileDropdown"
                            role="button"
                            data-bs-auto-close="outside"
                            data-bs-display="static"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img
                              className="avatar-img rounded-circle"
                              style={{
                                width: "28px",
                                height: "28px",
                                objectFit: "cover",
                                marginTop: "5px",
                              }}
                              src={
                                userData?.avatar_url
                                  ? userData?.avatar_url
                                  : "/assets/images/avatar/dummyimage.jpeg"
                              }
                              alt="avatar"
                            />
                          </Link>
                          <ul
                            className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
                            aria-labelledby="profileDropdown"
                          >
                            <li className="px-3 mb-3">
                              <div className="d-flex align-items-center">
                                <div className="avatar me-3">
                                  <img
                                    className="avatar-img rounded-circle"
                                    src={
                                      userData?.avatar_url
                                        ? userData?.avatar_url
                                        : "/assets/images/avatar/dummyimage.jpeg"
                                    }
                                    alt="avatar"
                                  />
                                </div>
                                <div>
                                  <Link className="h6" to="#">
                                    Welcome{" "}
                                    {userData.first_name == ""
                                      ? userName
                                      : userData.first_name +
                                      " " +
                                      userData.last_name}
                                  </Link>
                                </div>
                              </div>
                            </li>
                            <li>
                              {" "}
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to={`/Lab`}
                              >
                                <i className="bi bi-person fa-fw me-2" />
                                Lab
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                href="#"
                                onClick={() => redirectLms()}
                              >
                                <i className="bi bi-person fa-fw me-2" />
                                Dashboard
                              </Link>
                            </li>

                            <li>
                              <button
                                className="dropdown-item bg-danger-soft-hover"
                                onClick={() => Logout()}
                              >
                                <i className="bi bi-power fa-fw me-2" />
                                Logout
                              </button>
                            </li>

                            <li>
                              {" "}
                              <hr className="dropdown-divider" />
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <li className="nav-item">
                        <span
                          className="nav-link"
                          style={{ cursor: "pointer" }}
                          onClick={handleShowLogin}
                        >
                          <img
                            src={uservector}
                            style={{ marginTop: "10px", height: "10px" }}
                          />
                          <img
                            src={usericon}
                            style={{ width: "20px", height: "20px" }}
                          />
                          <i
                            className="fa-solid fa-angle-down"
                            style={{ fontSize: "12px" }}
                          ></i>
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </nav>
          </div>

          <>
            <nav
              className="navbar navbar-expand-xl"
              onMouseEnter={() => {
                setShowHover(false);
                setSearchTerm("");
              }}
              onClick={() => {
                setShowHover(false);
                setSearchTerm("");
              }}
              style={{
                height: "80px",
                boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.2)`,
              }}
            >
              <Container>
                {/* <Link className="h-100 w-100" to="/"> */}
                <img
                  // style={{ height: "250%" }}
                  style={{ width: "17%", cursor: "pointer" }}
                  // style={{ height: "50px" }}
                  src="/assets/images/logo.webp"
                  alt="logo"
                  onClick={() => (window.location.href = "/")}
                />
                <div
                  className="container-fluid d-flex justify-content-around "
                  style={{
                    height: "50px",
                    marginTop: "32px",
                    marginLeft: "100px",
                  }}
                >
                  <div
                    className="navbar-collapse w-100 collapse"
                    id="navbarCollapse"
                  >
                    <ul className="navbar-nav navbar-nav-scroll me-auto">
                      <li
                        className="nav-item "
                        style={{
                          borderBottom: showHover
                            ? "6px solid #0F75BC"
                            : "6px solid #C2C2C2",
                        }}
                      >
                        <Link
                          className="nav-link bg-primary bg-opacity-10 rounded-3 text-primary px-3 py-3 py-xl-0 d-flex align-items-center"
                          onMouseEnter={toggleHover}
                        >
                          <button className="dropdown-toggle border-0  p-0 m-0  px-2 bg-transparent">
                            <span>Explore Courses</span>
                          </button>
                        </Link>
                      </li>
                    </ul>

                    <ul className="navbar-nav ms-9 custom-nav-add navbar-nav-scroll me-auto">
                      <li
                        className="nav-item "
                        style={{
                          borderBottom: showHoverC
                            ? "6px solid #0F75BC"
                            : "6px solid #C2C2C2",
                        }}
                      >
                        <Link
                          className="nav-link bg-primary bg-opacity-10 rounded-3 text-primary px-3 py-3 py-xl-0 d-flex align-items-center"
                          onMouseEnter={toggleHoverC}
                        >
                          <button className="dropdown-toggle border-0  p-0 m-0  px-2 bg-transparent">
                            <span> Courses For</span>
                          </button>
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {loggedIn == true ? (
                    //DONE:change made here
                    <></>
                  ) : (
                    <></>
                  )}
                </div>
                {loggedIn ? (
                  <></>
                ) : (
                  <li
                    className="nav-item list-unstyled navbar-collapse collapse d-flex"
                    id="navbarCollapse"
                  >
                    <Link className="nav-link fs-6  dropdown-item" to="">
                      <button
                        className="btn btn-primary"
                        onClick={handleShowLogin}
                      >
                        Register for Free
                      </button>
                    </Link>
                  </li>
                )}
              </Container>
            </nav>
          </>
          {showHover ? <Hover onClose={() => setShowHover(false)} /> : <></>}
          {showHoverC ? (
            <Hovercoursefor onClose={() => setShowHoverC(false)} />
          ) : (
            <></>
          )}
          {/* <Aler */}
          {/* {showSignUp && <NewSignUp /> } */}

          <Modal show={showLoginSignup} onHide={handleCloseL} animation={false}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SingupComponent />
            </Modal.Body>
          </Modal>
        </header>
      )}
      {/*tab responsive */}

      {isTablet && (
        <>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          ></meta>
          <header
            className="navbar-light navbar-sticky header-static"
            style={{
              borderBottom: "1px solid rgba(0,0,0,.12)",
            }}
          >
            <div>
              <nav
                className="navbar navbar-expand-lg navbar-dark"
                style={{ backgroundColor: "#05295D", height: "40px" }}
              >
                <div className="container d-flex justify-content-center align-items-center">
                  <span
                    className="navbar-brand fs-9 fs-md-6 fs-lg-6"
                    style={topHeader}
                  >
                    {" "}
                    Have any question?{" "}
                    <a className="text-white" href="mailto:info@qacademy.tech">
                      info@qacademy.tech
                    </a>{" "}
                    {" "}
                    | <Link className={`text-white ${CountryCodeGet !== 'IN' && 'd-none'}`} to="tel: +919998458459">
                    +91-9998458459 | 
                  </Link>
                  {countryCode}
                  </span>
                </div>
              </nav>
            </div>
            <nav
              className="navbar navbar-expand-xl"
              onMouseEnter={() => {
                setShowHover(false);
                setSearchTerm("");
              }}
              onClick={() => {
                setShowHover(false);
                setSearchTerm("");
              }}
              style={{ height: "80px" }}
            >
              <div
                class="row mt-2"
                style={{
                  width: "108%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div class="col-4">
                  <button
                    className="navbar-toggler ms-auto"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-animation">
                      <span />
                      <span />
                      <span />
                    </span>
                  </button>

                  <div
                    className="navbar-collapse w-100 collapse"
                    id="navbarCollapse"
                  >
                    <ul className="navbar-nav navbar-nav-scroll me-auto">
                      {/* <li className="nav-item "> */}
                      <div className="col-12 d-flex">
                        <div
                          className="px- col-5 overflow-hidden"
                          style={{
                            background: "#ffffff",
                            // marginLeft: '600px',
                          }}
                        >
                          <ul className="list-unstyled">
                            {subMenu?.map((item, i) => (
                              <>
                                <li>
                                  <h4
                                    className="dropdown-header m-3"
                                    style={{
                                      color: "#0F75BC",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {item.title}
                                  </h4>
                                  <h6
                                    className="dropdown-header m-3"
                                    style={{
                                      color: "#0F75BC",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.title == "Courses For"
                                      ? ""
                                      : "By Subject"}
                                  </h6>
                                </li>
                                {item.data.length > 0 &&
                                  item.data?.map((res, i) => (
                                    <li
                                      key={i}
                                      className="dropdown px-2 d-flex align-items-center my-3"
                                      style={{
                                        cursor: "pointer",

                                        //backgroundColor: res.id === selected ? '#0dcaf0-200' : '#ffffff',
                                        borderLeft:
                                          res.id === selected
                                            ? "4px solid #0F75BC"
                                            : "4px solid #ffffff",
                                        color:
                                          res.id === selected
                                            ? "#0F75BC"
                                            : "#C4C4C4",
                                        fontSize: "12px",
                                        //  padding: res.id === selected ? '0px' : '0px',
                                        // borderRadius: '5px'
                                      }}
                                      onMouseEnter={() => {
                                        setSelected(res.id);
                                        item.type === "courses"
                                          ? (handleCategoryHover(
                                            res?.slug.replace(/\//g, " > ")
                                          ),
                                            setSelectedTag(),
                                            setHover(false))
                                          : (handleCategoryHover(),
                                            setHover(true),
                                            setSelectedTag(
                                              res?.slug.replace(/\//g, " > ")
                                            ),
                                            setGoalsOrSubjects("subjects"));
                                      }}
                                      onMouseLeave={() => {
                                        setSelected("");
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#565656",
                                          fontWeight: "400",
                                          color:
                                            res.id === selected
                                              ? "#0F75BC"
                                              : "#565656",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {res?.name.replace(/\//g, " > ")}{" "}
                                      </span>
                                      <i className="fas fa-chevron-right ms-auto"></i>
                                    </li>
                                  ))}
                              </>
                            ))}
                            <li style={{ marginLeft: "11px" }}>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={handleShowLogin}
                              >
                                Register for Free
                              </button>
                            </li>
                          </ul>
                        </div>
                        {selected !== null && (
                          <div
                            className="col-6 overflow-hidden"
                            style={{
                              background: "#ffffff",
                              height: "100%",
                              borderLeft: "1px solid #565656",
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          >
                            {category
                              ?.find((item) => item?.id === selected)
                              ?.courses?.map((res, i) => (
                                <ul
                                  className="list-group-border-0 mt-3"
                                  key={i}
                                >
                                  <li className="list-group-item ">
                                    <div>
                                      <li className="list-group-item">
                                        <div>
                                          <div className="ms-1">
                                            <Link
                                              className="stretched-link h6 mb-0"
                                              style={{
                                                fontWeight: "100",
                                                color: "#565656",
                                                fontSize: "13px",
                                                cursor: "pointer !important",
                                              }}
                                              // TODO: check slug is passing or not
                                              to={`/course/${res.slug}`}
                                              state={{ id: res.ID }}
                                              onClick={() => {
                                                // subBarClose();
                                                const navbarCollapse =
                                                  new bootstrap.Collapse(
                                                    document.getElementById(
                                                      "navbarCollapse"
                                                    )
                                                  );
                                                navbarCollapse.hide();
                                              }}
                                            >
                                              {res.post_title}
                                            </Link>
                                          </div>
                                        </div>
                                      </li>
                                    </div>
                                  </li>
                                </ul>
                              ))}

                            {goalsOrSubjects === "goals"
                              ? mainCategory.length > 0 &&
                              mainCategory?.map((res, i) => <></>)
                              : tags.length > 0 &&
                              mainSubject?.map((tag, i) => (
                                <ul className="list-group-border-0 mt-5">
                                  <li className="list-group-item ">
                                    <div>
                                      {tag.categories?.map((cat, i) =>
                                        checkSubjectsExist(cat.id, tag.id) ? (
                                          <li
                                            key={i}
                                            className="list-group-item"
                                          >
                                            {/* <h6 className="mb-0">{cat.name}</h6>
                                                              <hr /> */}
                                            {tag.courses
                                              ?.slice(0, 8)
                                              ?.map((course, it) =>
                                                course?.categories.find(
                                                  (item) =>
                                                    item.term_id === cat.id
                                                ) ? (
                                                  <div
                                                    key={it}
                                                    className="d-flex mb-2 position-relative"
                                                  >
                                                    {/* {course?.images.length > 0 ?
                                                                              <img src={course?.images[0].guid} className="icon-md" alt="" />
                                                                              : <></>
                                                                          } */}
                                                    <div className="ms-1">
                                                      <Link
                                                        className="stretched-link h6  mb-0"
                                                        style={{
                                                          fontWeight: "100",
                                                          color: "#565656",
                                                          fontSize: "13px",
                                                        }}
                                                        to={`/course/${course.slug}`}
                                                        state={{
                                                          id: course.ID,
                                                        }}
                                                        onClick={() => {
                                                          // subBarClose();
                                                          const navbarCollapse =
                                                            new bootstrap.Collapse(
                                                              document.getElementById(
                                                                "navbarCollapse"
                                                              )
                                                            );
                                                          navbarCollapse.hide();
                                                        }}
                                                      >
                                                        {course.post_title}
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <></>
                                                )
                                              )}
                                            {/* <div className="d-flex  mb-4 position-relative">
                                                                  <div className="ms-3">
                                                                      <a href={`courses?category_id=${cat.id}&tag_id=${tag.id}&category_name=${cat.name}&topic=${selectedTag}`} className="" state={{ category_id: cat.id, tag_id: tag.id }}>
                                                                          View all {cat?.name}
                                                                      </a>
                                                                  </div>
                                                              </div> */}
                                          </li>
                                        ) : (
                                          <></>
                                        )
                                      )}
                                    </div>
                                  </li>
                                </ul>
                              ))}
                          </div>
                        )}
                      </div>

                      {/* </li> */}
                    </ul>
                  </div>
                </div>
                <div class="col-6">
                  <Link className="navbar-brand" to="/">
                    <img
                      // className="light-mode-item navbar-brand-item"
                      src="/assets/images/logo.webp"
                      alt="logo"
                      style={{ height: "55px" }} // Adjust the width and height to your desired size
                    />
                  </Link>
                </div>
                <div class="col-2">
                  {loggedIn ? (
                    <>
                      <div className="dropdown ms-6 ms-lg-0">
                        <Link
                          className="avatar avatar-sm p-0"
                          to="#"
                          id="profileDropdown"
                          role="button"
                          data-bs-auto-close="outside"
                          data-bs-display="static"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            className="avatar-img rounded-circle"
                            src={
                              userData?.avatar_url
                                ? userData?.avatar_url
                                : "/assets/images/avatar/dummyimage.jpeg"
                            }
                            alt="avatar"
                          />
                        </Link>
                        <ul
                          className="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3"
                          aria-labelledby="profileDropdown"
                        >
                          <li className="px-3 mb-3">
                            <div className="d-flex align-items-center">
                              <div className="avatar me-3">
                                <img
                                  className="avatar-img rounded-circle"
                                  src={
                                    userData?.avatar_url
                                      ? userData?.avatar_url
                                      : "/assets/images/avatar/dummyimage.jpeg"
                                  }
                                  alt="avatar"
                                />
                              </div>
                              <div>
                                <Link className="h6" to="#">
                                  Welcome{" "}
                                  {userData.first_name == ""
                                    ? userName
                                    : userData.first_name +
                                    " " +
                                    userData.last_name}
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li>
                            {" "}
                            <hr className="dropdown-divider" />
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to={`/Lab`}
                            >
                              <i className="bi bi-person fa-fw me-2" />
                              Lab
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              href="#"
                              onClick={() => redirectLms()}
                            >
                              <i className="bi bi-person fa-fw me-2" />
                              Dashboard
                            </Link>
                          </li>

                          <li>
                            <button
                              className="dropdown-item bg-danger-soft-hover"
                              onClick={() => Logout()}
                            >
                              <i className="bi bi-power fa-fw me-2" />
                              Logout
                            </button>
                          </li>

                          <li>
                            {" "}
                            <hr className="dropdown-divider" />
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>
                      <a onClick={handleShowLogin}>
                        <i
                          class="fas fa-sign-in-alt"
                          style={{ color: "#a4a7ad", marginTop: "10px" }}
                        ></i>
                        {/* <i class="fas fa-sign-in-alt fa-xs" style={{color:" #cdd0d6"}}></i> */}
                        <i
                          class="fas fa-user fa-lg"
                          style={{ color: "#a4a7ad" }}
                        ></i>
                        {/* <img src={uservector} style={{ marginTop: '10px', height: '10px', backgroundColor:'blue' }} />
                      <img src={usericon} style={{ width: '20px', height: '20px',backgroundColor:'blue' }} /> */}
                      </a>
                    </>
                  )}
                </div>
              </div>
            </nav>
            {showHover ? <Hover onClose={() => setShowHover(false)} /> : <></>}
            {showHoverC ? (
              <Hovercoursefor onClose={() => setShowHoverC(false)} />
            ) : (
              <></>
            )}
            {/* <Aler */}
            {/* {showSignUp && <NewSignUp /> } */}

            <Modal show={showLogin} onHide={handleCloseL} animation={false}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <SingupComponent />
              </Modal.Body>
              {/* <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </Modal.Footer> */}
            </Modal>
          </header>
        </>
      )}
      {/* mobile & tab responsive */}
      {isMobile && (
        <header
          className="navbar-light navbar-sticky header-static"
          style={{
            borderBottom: "1px solid rgba(0,0,0,.12)",
          }}
        >
          <div>
            <nav
              className="d-flex  align-items-center"
              style={{ backgroundColor: "#05295D", height: "40px" }}
            >
              <Container>
                <span
                  className=""
                  style={{
                    fontSize: "11px",
                    color: "#fff",
                  }}
                >
                  {" "}
                  Have any question?{" "}
                  <a className="text-white" href="mailto:info@qacademy.tech">
                    info@qacademy.tech
                  </a>{" "}
                  {" "}
                  | <Link className={`text-white ${CountryCodeGet !== 'IN' && 'd-none'}`} to="tel: +919998458459">
                    +91-9998458459 | 
                  </Link>
                  {countryCode}
                </span>
              </Container>
            </nav>
          </div>
          <nav
            className="navbar navbar-expand-xl"
            onMouseEnter={() => {
              setShowHover(false);
              setSearchTerm("");
            }}
            onClick={() => {
              setShowHover(false);
              setSearchTerm("");
            }}
            style={{ height: "80px" }}
          >
            <Container>
              <div class="row mt-1">
                <div
                  class="col-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    className="navbar-toggler me-4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarCollapse"
                    aria-controls="navbarCollapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-animation">
                      <span />
                      <span />
                      <span />
                    </span>
                  </button>

                  <div
                    className="navbar-collapse w-100 collapse"
                    id="navbarCollapse"
                  >
                    <ul className="navbar-nav navbar-nav-scroll me-auto">
                      {/* <li className="nav-item "> */}
                      <div className="col-12 d-flex">
                        <div
                          className="px- col-5 overflow-hidden"
                          style={{
                            background: "#ffffff",
                            // marginLeft: '600px',
                            borderRight: "1px solid #565656",
                            paddingRight: "10px",
                          }}
                        >
                          <ul className="list-unstyled">
                            {subMenu?.map((item, i) => (
                              <>
                                <li>
                                  <h4
                                    className="dropdown-header m-3"
                                    style={{
                                      color: "#0F75BC",
                                      fontSize: "16px",
                                    }}
                                  // dangerouslySetInnerHTML={{ __html: item.title }}
                                  >
                                    {item.title}
                                  </h4>
                                  <h6
                                    className="dropdown-header m-3"
                                    style={{
                                      color: "#0F75BC",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {item.title == "Courses For"
                                      ? ""
                                      : "By Subject"}
                                  </h6>
                                </li>

                                {item.data.length > 0 &&
                                  item.data?.map((res, i) => (
                                    <li
                                      key={i}
                                      className="dropdown px-2 d-flex align-items-center my-3"
                                      style={{
                                        cursor: "pointer",
                                        //backgroundColor: res.id === selected ? '#0dcaf0-200' : '#ffffff',
                                        borderLeft:
                                          res.id === selected
                                            ? "4px solid #0F75BC"
                                            : "4px solid #ffffff",
                                        color:
                                          res.id === selected
                                            ? "#0F75BC"
                                            : "#C4C4C4",
                                        fontSize: "12px",
                                        //  padding: res.id === selected ? '0px' : '0px',
                                        // borderRadius: '5px'
                                      }}
                                      onMouseEnter={() => {
                                        setSelected(res.id);
                                        item.type === "courses"
                                          ? (handleCategoryHover(
                                            res?.slug.replace(/\//g, " > ")
                                          ),
                                            setSelectedTag())
                                          : (handleCategoryHover(),
                                            setSelectedTag(
                                              res?.slug.replace(/\//g, " > ")
                                            ));
                                      }}
                                      onMouseLeave={() => {
                                        setSelected("");
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#565656",
                                          fontWeight: "400",
                                          color:
                                            res.id === selected
                                              ? "#0F75BC"
                                              : "#565656",
                                          fontSize: "13px",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: res?.name.replace(/\//g, " > "),
                                        }}
                                      >
                                        {/* {res?.name.replace(/\//g, " > ")}{" "} */}
                                      </span>
                                      <i className="fas fa-chevron-right ms-auto"></i>
                                    </li>
                                  ))}
                              </>
                            ))}
                            <li style={{ marginLeft: "11px" }}>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={handleShowLogin}
                              >
                                Register for Free
                              </button>
                            </li>
                          </ul>
                        </div>
                        {selected !== null && (
                          <div
                            className="col-6 overflow-hidden"
                            style={{
                              background: "#ffffff",
                              height: "100%",
                              // borderLeft: '1px solid #565656',
                              borderRadius: "0px 5px 5px 0px",
                            }}
                          >
                            {category
                              ?.find((item) => item?.id === selected)
                              ?.courses?.slice(-7)
                              ?.map((res, i) => (
                                <ul
                                  className="list-group-border-0 mt-3"
                                  key={i}
                                >
                                  <li className="list-group-item ">
                                    <div>
                                      <li className="list-group-item">
                                        <div>
                                          <div className="ms-1">
                                            <Link
                                              className="stretched-link h6 mb-0"
                                              style={{
                                                fontWeight: "100",
                                                color: "#565656",
                                                fontSize: "13px",
                                                cursor: "pointer !important",
                                              }}
                                              to={`/course/${res.slug}`}
                                              state={{ id: res.ID }}
                                              onClick={() => {
                                                // subBarClose();
                                                const navbarCollapse =
                                                  new bootstrap.Collapse(
                                                    document.getElementById(
                                                      "navbarCollapse"
                                                    )
                                                  );
                                                navbarCollapse.hide();
                                              }}
                                            >
                                              {res.post_title}
                                            </Link>
                                          </div>
                                        </div>
                                      </li>
                                    </div>
                                  </li>
                                </ul>
                              ))}

                            {goalsOrSubjects === "goals"
                              ? mainCategory.length > 0 &&
                              mainCategory?.map((res, i) => <></>)
                              : tags.length > 0 &&
                              mainSubject?.map((tag, i) => (
                                <ul className="list-group-border-0 mt-5">
                                  <li className="list-group-item">
                                    <div>
                                      <li key={i} className="list-group-item">
                                        {/* <h6 className="mb-0">{cat.name}</h6>
                                                            <hr /> */}
                                        {tag.courses
                                          ?.slice(-7)
                                          ?.map((item, index) => (
                                            <div
                                              key={index}
                                              className="d-flex mb-2 position-relative"
                                            >
                                              <div className="ms-1">
                                                <Link
                                                  className="stretched-link h6  mb-0"
                                                  style={{
                                                    fontWeight: "100",
                                                    color: "#565656",
                                                    fontSize: "13px",
                                                  }}
                                                  to={`/course/${item.slug}`}
                                                  state={{ id: item.ID }}
                                                  onClick={() => {
                                                    // subBarClose();
                                                    const navbarCollapse =
                                                      new bootstrap.Collapse(
                                                        document.getElementById(
                                                          "navbarCollapse"
                                                        )
                                                      );
                                                    navbarCollapse.hide();
                                                  }}
                                                >
                                                  {item.post_title}
                                                </Link>
                                              </div>
                                            </div>
                                          ))}
                                      </li>
                                    </div>
                                  </li>
                                </ul>
                              ))}
                            {selected && (
                              <Container>
                                <Container>
                                  <Link
                                    className="btn btn-sm btn-primary m-1 mt-3"
                                    to={
                                      selectedTag !== undefined
                                        ? `/courses/${selected}/t/${selectedTag}`
                                        : `/courses/${selected}/c/${selectedCategory}`
                                    }
                                    onClick={() => {
                                      // subBarClose();
                                      const navbarCollapse =
                                        new bootstrap.Collapse(
                                          document.getElementById(
                                            "navbarCollapse"
                                          )
                                        );
                                      navbarCollapse.hide();
                                    }}
                                  >
                                    Browse all courses
                                  </Link>
                                </Container>
                              </Container>
                            )}
                          </div>
                        )}
                      </div>
                      {/* </li> */}
                    </ul>
                  </div>
                </div>
                <div
                  class="col-6"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Link className="navbar-brand" to="/">
                    <img
                      // className="light-mode-item navbar-brand-item"
                      src="/assets/images/logo.webp"
                      alt="logo"
                      style={{ marginTop: 3.5 }} // Adjust the width and height to your desired size
                    />
                  </Link>
                </div>
                <div
                  class="col-2 ms-4"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {loggedIn ? (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className="avatar avatar-sm p-0"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                        >
                          <img
                            className="avatar-img rounded-circle"
                            src={
                              userData?.avatar_url
                                ? userData?.avatar_url
                                : "/assets/images/avatar/dummyimage.jpeg"
                            }
                            alt="avatar"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item href="#" className="h6 text-black">
                            <div className="avatar me-2">
                              <img
                                className="avatar-img rounded-circle"
                                src={
                                  userData?.avatar_url
                                    ? userData?.avatar_url
                                    : "/assets/images/avatar/dummyimage.jpeg"
                                }
                                alt="avatar"
                              />
                            </div>
                            Welcome{" "}
                            {userData.first_name == ""
                              ? userName
                              : userData.first_name + " " + userData.last_name}
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item href="/Lab" >
                            {" "}
                            <i className="bi bi-person fa-fw me-2" />
                            Lab
                          </Dropdown.Item>
                          <Dropdown.Item href="#" onClick={() => redirectLms()}>
                            {" "}
                            <i className="bi bi-person fa-fw me-2" />
                            Dashboard
                          </Dropdown.Item>
                          <Dropdown.Item href="/cart">
                            {" "}
                            <i className="fa fa-cart-shopping fa-fw me-2" />
                            My Cart ({cartItems.length})
                          </Dropdown.Item>
                          <Dropdown.Item href="#" onClick={() => Logout()}>
                            {" "}
                            <i className="bi bi-power fa-fw me-2" />
                            Logout
                          </Dropdown.Item>
                          <Dropdown.Divider />
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <div
                      style={{
                        marginTop: "10px",
                        width: 50,
                      }}
                    >
                      <a onClick={handleShowLogin}>
                        <i
                          class="fas fa-sign-in-alt"
                          style={{ color: "#a4a7ad" }}
                        ></i>
                        {/* <i class="fas fa-sign-in-alt fa-xs" style={{color:" #cdd0d6"}}></i> */}
                        <i
                          class="fas fa-user fa-lg"
                          style={{ color: "#a4a7ad" }}
                        ></i>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </nav>
          {showHover ? <Hover onClose={() => setShowHover(false)} /> : <></>}
          {showHoverC ? (
            <Hovercoursefor onClose={() => setShowHoverC(false)} />
          ) : (
            <></>
          )}

          <Modal
            show={showLoginSignup}
            onHide={handleCloseL}
            animation={false}
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <SingupComponent />
            </Modal.Body>
          </Modal>
        </header>
      )}
    </>
  );
}