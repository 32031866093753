import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoginUser } from '../services/Services';
import { Link, useNavigate } from 'react-router-dom'; // Use useHistory from react-router package
import { setRIsLogin, setRUserData, setRUserID, setRUserName } from '../app/reducers/userReducer';
import Signup from './new.singup';
import { pad } from 'crypto-js';
import usericon from '../../src/images/icons/usericon.png'
import uservector from '../../src/images/icons/userVector.png'
import { ForgotPassword } from './reset.password';
function NewLogin({ model_state }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <li className="nav-item dropdown">
        <Link style={{
                    color: "#000",
                    opacity: "0.9",
                    letterSpacing: "0.5px",
                    fontSize: "13px",
                    fontWeight: "500",
                    fontFamily: "Source Sans Pro, Arial, sans-serif",
          }} className="nav-link dropdown-item text-primary" to="#" onClick={() => setShowModal(true)}>
          <img src = {uservector} style={{marginTop:'10px', height:'10px'}}/>
          <img src = {usericon} style={{height:'20px', width:'20px'}} />
        </Link>
      </li>

      {showModal && <NewLoginModal onClose={() => setShowModal(false)} />}
    </>
  );
}

export default NewLogin;

export function LoginModal() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  return (
    <>
      <li className="nav-item dropdown">
        <Link  className="nav-link text-white btn btn-primary" to="#" onClick={() => setShowLoginModal(true)}>
          Join for Free
        </Link>
      </li>
      {showLoginModal&&<NewLoginModal onClose={() => setShowLoginModal(false)} />}
    </>
  )
}

export function NewLoginModal({ onClose }) {

  const [class_name, setClass_name] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { userData } = useSelector((state) => state?.user || {});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeClass_name = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
    if (class_name === null) {
      setClass_name('slash');
    } else {
      setClass_name(null);
    }
  };

  async function handleLogin() {
    setLoading(true);
    setError(''); // Clear previous error message

    if (!username.trim()) {
      setError('Please Enter Username');
      setLoading(false);
      return;
    }

    if (!password.trim()) {
      setError('Please enter Password');
      setLoading(false);
      return;
    }

    try {
      const response = await LoginUser(username, password, dispatch);

      if (response.success) {
        setLoading(false);
        setSuccess('Login Successful..');
        dispatch(setRIsLogin(1));
        dispatch(setRUserData(response.data));
        dispatch(setRUserName(response.data.username));
        dispatch(setRUserID(response.data.id));
        // navigate('/#', { replace: true });
        // history.go(-1);
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setLoading(false);
        setError('Oops!! Invalid Username or Password'); // Display the error message received from the server
      }
    } catch (error) {
      setLoading(false);
      setError('Error occurred while logging in.'); // Display a generic error message
      console.log(error); // Handle error appropriately
    }
  }

  return (
    <div
      className="modal fade show"
      tabIndex="-1"
      style={{
        display: 'block',
        backgroundColor: 'rgba(0, 0, 0, 0.8)', // Background overlay color with 50% opacity
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className=""
              style={{
                textAlign: 'center',
                width: '100%',
                fontSize: '2rem',
                fontWeight: '400',
                color: '#000',
                letterSpacing: '0.01rem',
              }}
              id="exampleModalLabel"
            >
              {/* Welcome Back */}
            </h5>
            <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
          </div>
          <div className="modal-body p-5">
            {success && <div className="alert alert-success">{success}</div>}
            {error && <div className="alert alert-danger">{error}</div>}
            <div className="mb-4">
              <label htmlFor="exampleInputEmail1" className="form-label  text-black">
                Username
              </label>
              <input
                type="text"
                onInput={(e) => setUsername(e.target.value)}
                className="form-control border border border-secondary"
                placeholder="Username"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
              {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
            </div>
            {/* <div className="mb-4">
              <label htmlFor="exampleInputPassword1" className="form-label  text-black fw-light fs-6">
                Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                onInput={(e) => setPassword(e.target.value)}
                className="form-control border border-secondary"
                placeholder="Enter your password"
                id="exampleInputPassword1"
              />
              <i
                style={{
                  position: 'absolute',
                  top: '54.1%',
                  right: '4rem',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer',
                }}
                onClick={changeClass_name}
                className={`fa fa-eye${class_name === null ? '' : '-slash'}`}
              ></i>
            </div> */}

<div style={{ position: 'relative' }}>
                                        <label htmlFor="exampleInputPassword1" className="form-label  text-black fw-light fs-6">
                                            Password
                                        </label>
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            onInput={(e) => setPassword(e.target.value)}
                                            className="form-control border border-secondary"
                                            placeholder="Enter your password"
                                            id="exampleInputPassword1"
                                        />
                                        <i
                                            style={{
                                                position: 'absolute',
                                                top: '67%',
                                                right: '2rem',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer',
                                            }}
                                            onClick={changeClass_name}
                                            className={`fa fa-eye${class_name === null ? '' : '-slash'}`}
                                        ></i>
                                    </div>
            <button type="submit" onClick={handleLogin} className="btn btn-primary mt-3 w-100 pt-2">
              {loading ? 'Loading...' : 'Log In'}
            </button>
            {/* <Link to="#"><ForgotPassword/></Link> */}
          </div>
          <div className="container">
            <p className="text-black text-center">
              New to Q Academy? &nbsp;
              <Link to="#" className="text-decoration-underline fs-6 lh-1 fw-medium">
                <Signup />
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}