import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import Header from './header';
import Footer from './footer';
import { getCourseCategory,getCourseTags } from '../../services/Services';
import '../pages.global.css';
import { Helmet } from 'react-helmet';

export default function Layout({children}) {
  const dispatch = useDispatch();
  useEffect(()=>{
    let script = document.createElement("script");
    script.src = "/assets/vendor/tiny-slider/tiny-slider.js";
    script.async = true;
    document.body.appendChild(script);
 
    script = document.createElement("script");
    script.src = "/assets/vendor/glightbox/js/glightbox.js";
    script.async = true;
    document.body.appendChild(script);

    script = document.createElement("script");
    script.src = "/assets/vendor/purecounterjs/dist/purecounter_vanilla.js";
    script.async = true;
    document.body.appendChild(script);

    setTimeout(()=>{
      script = document.createElement("script");
      script.src = "/assets/js/functions.js";
      script.async = true;
      document.body.appendChild(script);
  
    }, 2000)
    
    
    getCourseCategory(dispatch);
    getCourseTags(dispatch);
  }, []);
  const url = window.location.href;
  
  return (
    <>
      <Helmet>
        <link rel="canonical" href={url} />
      </Helmet>
    <div className='custom-overflow'>
      <Header/>
      {children}
      <Footer/>
    </div>
    </>
  )
}
