import React from 'react'

const Version = () => {
    return (
        <div className='container text-center'>
            <h1>Version</h1>
            <p className='fs-3 text-dark'>20.0.0  <span className='fs-6 text-gray'> 19.31.2</span></p>
        </div>
    )
}

export default Version
