import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchCountryInfo } from '../services/Services';
import { Button, Col, Container, Form } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

export default function DeleteAccount() {
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [response, setResponse] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get('user_id');
  const reset_key = searchParams.get('reset_key');

  async function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    alert('Are you sure you want to delete your account?');
   setLoading(false);
  }
  const isMobile = useMediaQuery({ query: '(max-width: 694px)' })
  return (
    <>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <nav className={`w-100 p-2`} style={{ backgroundColor: '#05295D', height: '40px' }}>
        <Container className={`${isMobile && 'd-flex justify-content-center'}`} >
          <Link className="navbar-brand fs-lg-6 text-white" to="/" style={{
            fontSize: '12px',
            textDecoration: 'none'
          }} >Have any question? 1800 532 272 588 info.india@qacademy.tech</Link>
        </Container>
      </nav>
      <nav className={`w-100 p-2`} style={{
        height: '80px',
        boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.2)`,
        marginBottom: '40px',
      }}>
        <Container className={`${isMobile && 'd-flex justify-content-center'}`} >
          <Link to="/" className='navbar-brand'><img style={{
            width: '200px',
            marginTop: '10px',
            objectFit: 'contain',
          }} className="w-75-sm" src="/assets/images/logo.webp" alt="" /></Link>
        </Container>
      </nav>
      <div className='container'>
        {response && <div class={`alert alert-${response.type}`} role="alert">
          {`${response?.msg}!`} {response.type === 'success' ? <Link to='/'>Login</Link> : ''}
        </div>}
      </div>

      {/* <p>Setting up password for:<span>examle@gmail.com</span></p> */}
      <Container>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <Col lg='6' sm='12' md='6'>
            <Form onSubmit={handleResetPassword}>
              <center>
                <p className='mb-4 text-dark' style={{ fontFamily: 'Roboto', fontSize: 35 }}>Delete Account</p>
              </center>
              <Form.Group className="mb-3 " controlId="password">
                <Form.Label className=' text-dark' style={{ fontFamily: 'Roboto' }}>Username / Email</Form.Label>
                <Form.Control type={"text"} placeholder="Enter your username or email"
                  onInput={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="confirmPassword">
                <Form.Label className=' text-dark' style={{ fontFamily: 'Roboto' }}> Password</Form.Label>
                <Form.Control type={showPassword ? "text" : "password"} placeholder="Re-Enter your password"
                  onInput={(e) => setConfirmPassword(e.target.value)}
                  required
                />
                {error && <Form.Text className="text-danger">
                  {error}
                </Form.Text>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                {/* <Form.Check type="checkbox" label="Show Password" onClick={() => setShowPassword(!showPassword)} /> */}
              </Form.Group>
              <Button type="submit" className="w-100" variant='danger'>
                {loading ? 'Loading...' : 'Delete Account'}
              </Button>
            </Form>
          </Col>
        </div>
      </Container>
    </>
  );
}