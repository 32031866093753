import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../../services/Services';
import { Link } from 'react-router-dom';
import '../pages.global.css';

function decodeHtmlEntities(text) {
  return text.replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}
function makeSmallAndReplace(str) {
  // Convert the string to lowercase and replace spaces with hyphens
  const modifiedStr = str.toLowerCase().replace(/\s+/g, '-');
  return modifiedStr;
}

const Hovercoursefor = (Props) => {
  const dispatch = useDispatch();
  const { category, tags, courses } = useSelector((state) => state?.course);
  const [selectedCategory, setSelectedCategory] = useState(46);
  const [selectedCategoryName, setSelectedCategoryName] = useState('Corporate');

  const handleCategoryHover = (categoryId, name) => {
    setSelectedCategory(categoryId);
    setSelectedCategoryName(name);
  };


  // user id get from loacal storage



  return (
    <div
      className='col-12 d-flex justify-content-center '
      style={{
        backgroundColor: '#58585874',
        position: 'absolute',
        height: '100vh',
        zIndex: 99,
      }}
      onClick={Props.onClose} onMouseLeave={Props.onClose}
    >
      <div className='col-11 d-flex' style={{
        height: 340,
        width: '65%',
      }} >
        <div
          className='px-4 col-3 overflow-hidden border border-1 border-secondary'
          style={{
            background: '#ffffff',
            marginLeft: '400px',
            // height: '60%',
          }}
        >
          <ul className='list-unstyled'>
            {category.length > 0 ?
              category.map((res, i) => (
                <li
                  key={i}
                  className="dropdown px-2 d-flex align-items-center my-3"
                  style={{
                    cursor: 'pointer',
                    borderLeft:
                      res.id === selectedCategory ? '4px solid #0F75BC' : '4px solid #ffffff',
                    color: res.id === selectedCategory ? '#0F75BC' : '#C4C4C4',
                    fontSize: '12px',
                  }}
                  onMouseEnter={() => handleCategoryHover(res.id, res.name)}
                // onMouseLeave={() => { handleCategoryHover('') }}
                >
                  <span className=''
                    style={{
                      color: res.id === selectedCategory ? '#0F75BC' : '#565656',
                      fontWeight: '400',
                      fontSize: '13px',
                    }}
                    dangerouslySetInnerHTML={{ __html: res?.name.replace(/\//g, ' > ') }}
                  >
                    {/* {res?.name.replace(/\//g, ' > ')} */}
                  </span>
                  <i className="fas fa-chevron-right ms-auto"></i>
                </li>
              )) : (
                <>
                  <p className='text-dark fs-5 m-5'>
                    Loading....
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </p>
                </>
              )
            }
          </ul>
        </div>
        {selectedCategory !== null && (
          <div
            className='col-4 overflow-hidden border border-1 border-secondary'
            style={{
              background: '#ffffff',
              // borderLeft: '1px solid #565656',
              borderRadius: '0px 5px 5px 0px',
              paddingRight: '40px',
            }}
          >
            <ul className="list-group-border-0 mt-3">
              {category
                ?.find((item) => item?.id === selectedCategory)
                ?.courses?.slice(-5).map((res, i) => (
                  <li className="list-group-item" key={i}>
                    <div>
                      <div className="ms-1">
                        <Link
                          className="stretched-link h6"
                          style={{
                            fontWeight: '100',
                            color: '#565656',
                            fontSize: '13px',
                            cursor: 'pointer !important',
                            lineHeight: 2.5,
                          }}
                          to={`/course/${res.post_name}`}
                          state={{ id: res.ID }}
                        >
                          {res.post_title}
                        </Link>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <div style={{ marginLeft: '2.1rem' }} >
              <Link
                to={`/courses/${selectedCategory}/c/${decodeHtmlEntities(makeSmallAndReplace(selectedCategoryName))}`}
                state={{ id: selectedCategory, categoryName: selectedCategoryName }}
              >
                <button className="btn btn-primary"> Browse all courses</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Hovercoursefor;
