import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProducts } from "../../services/Services";
import "../components/styles.css";
import "font-awesome/css/font-awesome.min.css"; // Import the Font Awesome CSS
import "./footer.css";
import waterMark from "../../images/waterMark.png";
import explorQ from "../../images/ExploreQ.png";

import { useMediaQuery } from "react-responsive";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function Footer() {
  const { tags } = useSelector((state) => state.course);
  const { userName } = useSelector(state => state.user);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const CountryCodeGet = localStorage.getItem("country");

  let countryCode = CountryCodeGet;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let newTag1 = tags.filter((t1) => {
    if (t1.name == "Big Data" || t1.name == "Data Science") {
      return t1;
    }
  });

  let newTag = tags.filter((t) => {
    if (
      t.name == "Software Development" ||
      t.name == "Computer Science" ||
      t.name == "Cloud Computing"
    ) {
      return t;
    }
  });

  const mergedCourses = newTag1.reduce((accumulator, currentTag) => {
    return accumulator.concat(currentTag.courses);
  }, []);

  // Empty dependency array to fetch data only once on component mount

  const isDesktopOrLaptopOrTab = useMediaQuery({
    query: "(min-width: 768px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1025px)" });

  const linkSetting = {
    onClick: () => {
      // setTimeout(() => {
      //   location.reload();
      window.scrollTo(0, 0);
      // }, 1200)
    }
  };

  const FooterData = [
    {
      title: "Q Academy",
      show: true,
      link: [
        {
          name: "About Us",
          link: "/about-us",
        },
        {
          name: "Contact Us",
          link: "/contact-us",
        },
        {
          name: "Our Instructors",
          link: "/instructors",
        },
        {
          name: "Careers",
          link: "/careers",
        },
      ],
      lg: 2,
    },

    {
      title: "Services For",
      show: true,
      link: [
        {
          name: "Corporate",
          link: "/for-corporate",
        },
        {
          name: "Government",
          link: "https://qacademyskills.com/",
          target: "_blank"

        },
        {
          name: "Universities/Colleges",
          link: "/courses/0/c/Courses-for-Universities-Colleges/",
        },
        {
          name: "Individuals",
          link: "/courses/0/c/Courses-for-Individuals",
        },
      ],
      lg: 2,
    },

    {
      title: "Featured Courses",
      show: true,
      link: [
        {
          name: "Power BI",
          link: "/course/powerbi/4090",
        },
        {
          name: "3D Animation",
          link: "/course/3d-animation/2811",
        },
        {
          name: "AWS Data Engineer",
          link: "/course/aws-data-engineer/1827",
        },
        {
          name: "Introduction to Big Data",
          link: "/course/big-data-hadoop-2-2/3897",
        },
        {
          name: "Understanding V-Server",
          link: "/course/understanding-v-server/2497",
        },
        // {
        //   name: "Introduction to Cyber Security",
        //   link: "/course?p=fundamentals-of-cyber-security-2&i=3979",
        // },
        // {
        //   name: "AWS Cloud Practioner",
        //   link: "/course?p=aws-cloud-practioner&i=1645",
        // },
      ],
      lg: 2,
    },

    {
      title: "Policies",
      show: true,
      link: [
        {
          name: "Terms & Conditions",
          link: "/terms-and-conditions",
        },
        {
          name: "Privacy Policy",
          link: "/privacy-policy",
        },
        {
          name: "Cancellation/Refund",
          link: "/cancellations-refund",
        },
      ],
      lg: 2,
    },

    {
      title: "Work With Us",
      show: true,
      link: [
        {
          name: "Become an Instructor",
          link: "/for-corporate",
          state: { modal: 'instructor' },
        },
        {
          name: "Become a Partner",
          link: "/for-corporate",
          state: { modal: 'partner' },
        },
        {
          name: "Become an Affiliate",
          link: "/for-corporate",
          state: { modal: 'affiliate' },
        },
      ],
      lg: 2,
    },
    {
      title: "Webinars",
      show: countryCode === "IN",
      link: [
        {
          name: "Data and Analytics",
          link: "/data-and-analytics",
        },
        {
          name: "Cloud Computing",
          link: "/cloud-computing",
        },
        {
          name: "Data Science",
          link: "/data-science-course",
        },
      ],
      lg: 2,
    },
    {
      title: "Customized options",
      show: ["gauravhajela"].includes(userName),
      link: [
        {
          name: "Add Job",
          link: "/add-job",
        },
        {
          name: "View Applicants",
          link: "/view-job-application",
        },
        {
          name: "View Job",
          link: "/careers",
        },
      ],
    },
    {
      title: "Channel Partner",
      show: true,
      link: [
        {
          name: "Data Science",
          link: "/channel-partner/data-science",
          // state: { modal: 'channel-partner' },
        },
        {
          name:"Artificial Intelligence",
          link: "/channel-partner/artificial-intelligence",
          // state: { modal: 'channel-partner' },
        },
        {
          name:"Cyber Security",
          link: "/channel-partner/cyber-security",
          // state: { modal: 'channel-partner' },
        },
        {
          name:"Digital Marketing",
          link: "/channel-partner/digital-marketing",
          // state: { modal: 'channel-partner' },
        },
        {
          name:"Full Stack Development",
          link: "/channel-partner/full-stack-development",
          // state: { modal: 'channel-partner' },
        }
      ],
      lg: 2,
    }
  ];

  const socialMedia = [
    {
      name: "Email",
      link: "mailto:info.india@qacademy.tech",
      icon: "fa fa-envelope-o",
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/company/qacademy/",
      icon: "fa fa-linkedin",
    },
    {
      name: "Facebook",
      link: "https://www.facebook.com/QAcademyCA",
      icon: "fa fa-facebook",
    },
    {
      name: "Instagram",
      link: "https://instagram.com/qacademyin?igshid=MzRlODBiNWFlZA==",
      icon: "fab fa-instagram",
    },
    {
      name: "Twitter",
      link: "https://twitter.com/qacademyin",
      icon: "fa-brands fa-x-twitter",
    },
  ];

  return (
    <>
      <footer className="pt-5 " style={{ backgroundColor: "#F1F1F1" }}>
        <Col lg={12} md={12} sm={12} xs={12} className="footer mb-5 pb-5">
          <Container>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12}>
                <Row
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Col
                    lg={2}
                    md={3}
                    sm={12}
                    xs={12}
                    style={{
                      textAlign: isDesktopOrLaptopOrTab ? "left" : "center",
                      marginBottom: isDesktopOrLaptopOrTab ? "0px" : "20px",
                      marginLeft: isDesktopOrLaptopOrTab ? -15 : 0,
                    }}
                  >
                    <Card.Img
                      variant="top"
                      src={"/assets/images/QALogo.png"}
                      style={{ width: "120px", height: "auto" }}
                    />
                  </Col>
                  <Col lg={10} md={9} sm={12} xs={12}>
                    <Card.Text
                      className="text-black"
                      style={{
                        textAlign: "justify",
                        marginLeft: windowWidth >= 800 ? "-45px" : "0",
                      }}
                    >
                      Our vision is to become the global leader in technology-
                      based learning, revolutionizing the way education is
                      delivered and accessed around the world. We aim to empower
                      learners of all ages and backgrounds by providing them
                      with innovative and effective educational tools. We
                      believe that education should be a lifelong journey and
                      our platform is designed to facilitate that journey by
                      providing access to wide range of courses that are
                      constantly evolving and dynamic in nature.
                    </Card.Text>
                  </Col>
                </Row>
              </Col>
              <Col
                lg={12}
                md={12}
                sm={12}
                xs={12}
                style={{
                  background: `url(${waterMark})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "bottom right",
                }}
                className="mt-5"
              >
                <Row>
                  {FooterData.map((data, index) => {
                    return (
                      <Col
                        lg={2}
                        md={"auto"}
                        sm={4}
                        xs={6}
                        className={`footer-col me-2 ${!data.show && 'd-none'}`}
                      >
                        <h5 className="text-black">{data.title}</h5>
                        <ul className="list-unstyled">
                          {data.link.map((link, index) => {
                            return (
                              <li>
                                <Link
                                  {...linkSetting}
                                  to={link.link}
                                  className="text-black"
                                  target={link.target}
                                  state={link.state}
                                >
                                  {link.name}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>

        <div
          style={{ width: "100%", height: "1px", backgroundColor: "#5BBBE3" }}
        ></div>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="footer mb-5 mt-3">
            <Container>
              <Row className="pt-2 pb-2">
                <Col lg={6} md={12} sm={12} xs={12} className="">
                  <div
                    className={`text-black ${!isBigScreen && "text-center pb-2"
                      }`}
                  >
                    Copyright © 2024 Q Academy Education. All Rights Reserved.
                  </div>
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                  <Row
                    style={{
                      justifyContent: isBigScreen ? "flex-end" : "center",
                      alignItems: "center",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {socialMedia.map((data, index) => {
                      return (
                        <Col
                          lg={"auto"}
                          md={"auto"}
                          className="d-flex justify-content-center"
                          sm={"auto"}
                          xs={"auto"}
                        >
                          <Link
                            {...linkSetting}
                            to={data.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-black"
                          >
                            <i
                              className={data.icon}
                              aria-hidden="true"
                              style={{
                                color: "#0056d2",
                                // marginRight: 10,
                                fontSize: 24,
                              }}
                            />
                          </Link>
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <div style={{ height: "30px", backgroundColor: "#B1E0F4" }}></div>
      </footer>
    </>
  );
}
